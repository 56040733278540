import * as React from "react";

import * as Auth from "./auth";
import * as Api from "./api/api";

export type State = Auth.State & Api.State;

export function State(): State {
    return Object.freeze({
        ...Api.State(),
        ...Auth.State(),
    });
}

export const StateContext = React.createContext<State>(
    Object.freeze({
        ...Api.DefaultState,
        ...Auth.GuestState,
    })
);

export function useStateContext(): State {
    return React.useContext(StateContext);
}
StateContext.displayName = "Client.StateContext";
