import {useEffect} from "react";
import * as Client from "modules/client";
import * as Chat from "modules/chat";
import axios from "axios";
import * as State from "../state";
import * as Base from "../base";
import {useHistory} from "react-router-dom";

export const ValidateMessage: State.Message<any> = Client.withApi(({api}) => {
    const dispatch = Chat.useDispatchContext();
    const history = useHistory();

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        api.with({cancelToken: cancelToken.token})
            .user.data.validate(1)
            .then(async ([error]: any) => {
                if (error === undefined) {
                    return dispatch(
                        new Chat.ReplaceAction([
                            new Chat.Registration.CompleteInitMessage
                        ])
                    );
                }
                const {
                    attribute: {value, schema, name: attribute},
                    text,
                } = error;
                return dispatch(
                    new Chat.ReplaceAction([
                        new Chat.Registration.FormMessage(
                            attribute,
                            text,
                            value,
                            schema
                        ),
                    ])
                );
            })
            .catch((error: Error) => {
                if (axios.isCancel(error)) {
                    return;
                }
                history.push("/failure");
            })
        return () => cancelToken.cancel();
    }, [api]);
    return <Base.LoadMessage/>;
});
