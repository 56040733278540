import * as React from "react";
import * as Client from "modules/client";
import * as Chat from "modules/chat";
import * as Base from "../base";
import * as State from "../state";

export const ConfirmSubmitMessage: State.Message<State.MessageProps<Chat.Restructuring.RestructuringConfirmSubmitMessage>> =
    ({value}) => {
        const handleRequest = React.useCallback((api: Client.Api.Instance, code: string) => {
            return api.user.restructuring.sign(code);
        }, []);
        const handleResponse = React.useCallback(async (response: Promise<string | undefined>) => {
            const failure = await response;
            if (failure && (failure.toString() !== "true")) {
                return new Chat.ReplaceAction([
                    new Chat.TextMessage(failure || "Ошибка подписания договора"),
                    new Chat.Restructuring.RestructuringConfirmFormMessage(),
                ], value);
            }
            return new Chat.FlushAction([
                new Chat.Restructuring.RestructuringCompleteMessage(),
            ]);
        }, [value]);
        return <Base.DelaySubmitMessage value={value.code} onRequest={handleRequest} onFinish={handleResponse}/>
    };
