import * as React from "react";
import * as State from "../state";
import * as Chat from "../../../modules/chat";
import * as Api from "modules/client/api";
import Select, { ValueType } from "react-select";
import { OptionType } from "dayjs";
import { ControlMessage, LabelMessage } from "../base/form";

const DropdownIndicator = () => <i className="icon icon_select-arrow" />;
const labelMessage = "Please select the bank where your account is located";
export const SelectNubanBankMessage: State.Message<
    State.MessageProps<Chat.BankAccount.SelectNubanBankMessage>
> = (props) => {
    const dispatch = Chat.useDispatchContext();
    const options = React.useMemo(() => {
        return props.value.banks.map((bank) => ({
            label: bank.name,
            value: bank.code.toString(),
        }));
    }, [props.value.banks]);
    const handleChange = React.useCallback(
        ({ label, value }: { label: string; value: string }) => {
            dispatch(
                new Chat.ReplaceAction(
                    [
                        new Chat.TextMessage(labelMessage),
                        new Chat.EditableMessage(
                            label,
                            new Chat.ReplaceAction([
                                new Chat.BankAccount.SelectNubankBankLoadMessage(),
                            ])
                        ),
                        new Chat.BankAccount.SelectNubanMessage(value),
                    ],
                    props.value
                )
            );
        },
        [dispatch, options]
    );

    return (
        <>
            <LabelMessage id={"nuban-input"} message={labelMessage} />
            <ControlMessage>
                <Select
                    options={options}
                    components={{ DropdownIndicator }}
                    placeholder={"Choose your bank"}
                    onChange={handleChange as any}
                    className="form__control-select menu_top"
                    classNamePrefix="select"
                />
            </ControlMessage>
        </>
    );
};
