import { LoadingSpinner } from "modules/components/loading-spinner";

export const Layout = () => {
    return (
        <>
            <LoadingSpinner />
            <div className="btn-container">
                <p>Please waiting....</p>
            </div>
        </>
    );
};

export default Layout;
