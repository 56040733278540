import * as Client from "modules/client";
import { Promocode } from "./promo-page";

export class HeaderMessage {
    public readonly type = "calculator:header";
}

export class LoadingMessage {
    public readonly type = "calculator:loading";
}

export class EditLoadingMessage {
    public readonly type = "calculator:edit-loading";
}

export class FormMessage {
    public readonly type = "calculator:form";

    constructor(
        public readonly conditions: Client.Api.Conditions<
            Required<Client.Api.ConditionsItem>
        >,
        public readonly sent?: true
    ) {}
}

export class CalculatorOnlyMessage {
    public readonly type = "calculator:only";

    constructor(
        public readonly conditions: Client.Api.Conditions<
            Required<Client.Api.ConditionsItem>
        >,
        public readonly promocode?: Promocode,
        public readonly sent?: true
    ) {}
}
export class EditMenuMessage {
    public readonly type = "calculator:edit-menu";
}

export class FormSubmitMessage {
    public readonly type = "calculator:form-submit";
    constructor(public readonly promocodeValue?: string) {}
}

export type Message =
    | FormMessage
    | LoadingMessage
    | HeaderMessage
    | CalculatorOnlyMessage
    | EditLoadingMessage
    | EditMenuMessage
    | FormSubmitMessage;
