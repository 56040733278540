import "./progress.scss";
import {CircleChart} from "modules/components/circular-chart";
import * as Client from "modules/client";
import dayjs from "dayjs";
import {useLocale} from "app/locale";

interface ProgressProps {
    paid: number
    debt: number
    remainDays: number
    overdue: number
}

export const Progress = Client.withState<{}, ProgressProps>(
    ({paid, debt, remainDays, overdue}) => {
        const {t} = useLocale('lk-main');
        const progress = Math.round(paid * 100 / (paid + debt));

        let bgColor: string;
        if (overdue > 1 || remainDays === 0) {
            bgColor = "#D64E5E";
        } else if (remainDays < 4) {
            bgColor = "#e03c85";
        } else {
            bgColor = "#1A83D3";
        }

        return (
            <div className="progress">
                <CircleChart
                    progress={progress}
                    border={8}
                    color={"white"}
                    color2={bgColor}
                />
                <div className="progress__info">
                    <span>{t('repaid')}</span>
                    <span><span className="increased">{paid}</span> грн</span>
                    <span className="m-t">{t('debt')}</span>
                    <span><span className="increased">{debt}</span> грн</span>
                </div>
            </div>
        )
    },
    (state) => {
        if ("user" !== state.type) {
            return false;
        }
        return {
            paid: Number(state.request.paid ?? 0),
            debt: state.request.debt?.total ?? 0,
            remainDays: dayjs(state.request.dateReturn).diff(new Date, "day"),
            overdue: state.request.overdue ?? 0,
        }
    }
);

Progress.displayName = "CurrentCredit.Progress";
