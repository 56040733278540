import * as Base from "../base";
import * as Chat from "modules/chat";
import { ReplaceAction } from "modules/chat";
import { IconArrow } from "modules/components/Icons/IconArrow";

export const RefuseInfoMessage = () => {
    const dispatch = Chat.useDispatchContext();

    const handleCanceled = () => {
        dispatch(
            new ReplaceAction([new Chat.Agreement.AgreementRefuseSendMessage()])
        );
    };

    const handleBack = () => {
        dispatch(
            new ReplaceAction([
                new Chat.TextMessage("Back", "user"),
                new Chat.TextMessage(
                    "Perfectly! To receive money, please sign a credit agreement.",
                    "bot"
                ),
                new Chat.Agreement.AgreementRefuseFormMessage(),
            ])
        );
    };

    return (
        <Base.ButtonMessage source={"user"}>
            <Base.Button theme={"primary"} onClick={handleCanceled}>
                Changed my mind
            </Base.Button>
            <Base.Button
                theme="secondary"
                onClick={handleBack}
                className="long"
            >
                <span>Back</span>
                <IconArrow />
            </Base.Button>
        </Base.ButtonMessage>
    );
};
