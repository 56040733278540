import * as Chat from "modules/chat";
import * as State from "../state";
import {InitMessage} from "./init-message";
import {WelcomeMessage} from "./welcome-message";
import {SelectMethodMessage} from "./select-method-message";
import {SelectNubanBankLoadMessage} from "./select-nuban-bank-load-message";
import {SelectNubanBankMessage} from "./select-nuban-bank-message";
import {SelectNubanMessage} from "./select-nuban-message";
import {SelectNubanLoadMessage} from "./select-nuban-load-message";
import {ConfirmNubanMessage} from "./confirm-nuban-message";
import {ConfirmNubanLoadMessage} from "./confirm-nuban-load-message";
import {InitDashboardMessage} from "./init-dashboard-message";

export function MessageComponentFactory(
    type: Chat.BankAccount.Message["type"]
): State.Message | undefined {
    switch (type) {
        case "bank-account:init":
            return InitMessage;
        case "bank-account:welcome":
            return WelcomeMessage;
        case "bank-account:select-method":
            return SelectMethodMessage;
        case "bank-account:nuban-select-bank-load":
            return SelectNubanBankLoadMessage;
        case "bank-account:nuban-select-bank":
            return SelectNubanBankMessage;
        case "bank-account:nuban-select":
            return SelectNubanMessage;
        case "bank-account:nuban-select-load":
            return SelectNubanLoadMessage;
        case "bank-account:nuban-confirm":
            return ConfirmNubanMessage;
        case "bank-account:nuban-confirm-load":
            return ConfirmNubanLoadMessage;
        case "bank-account:init-dashboard":
            return InitDashboardMessage;
    }
}
