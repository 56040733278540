import { CalculatorStorage } from "app/chat/calculator/storage";
import { LOCALE_ROUTE_PATH, useLocale } from "app/locale";
import * as React from "react";
import { useLocation } from "react-router-dom";
import * as Client from "../client";
import { Action, FlushAction } from "./action";
import { DebugMessage } from "./message";
import { State } from "./state";
import { useEndpointEffect } from "./use-endpoint-effect";
import { useRouteMatch } from "react-router-dom";

export type Reducer = React.Reducer<State, Action>;
export const Reducer: Reducer = (state, action): State => {
    if (Array.isArray(action)) {
        return action.reduce(Reducer, state);
    }
    console.log(`CHAT DISPATCH`, action, state);
    switch (action.type) {
        case "message:push":
            if (action.messages.length === 0) {
                return state;
            }
            return {
                ...state,
                messages: [...state.messages, ...action.messages],
            };
        case "message:remove":
            return {
                ...state,
                messages: state.messages.filter((m) => m !== action.message),
            };
        case "message:delete":
            if (action.props) {
                const { props } = action;

                state.messages = state.messages.filter((message) => {
                    const { types } = props;

                    if (types) {
                        return !types.includes(message.type);
                    }
                });
            } else {
                state.messages.pop();
            }
            return {
                ...state,
            };
        case "message:replace":
            const index =
                "object" === typeof action.target
                    ? state.messages.findIndex((m) => m === action.target)
                    : state.messages.length - (action.target ?? 1);
            if (index === -1) {
                return state;
            }
            return {
                ...state,
                messages: state.messages
                    .slice(0, index)
                    .concat(...action.messages)
                    .concat(...state.messages.slice(index + 1)),
            };
        case "message:flush":
            return {
                ...state,
                messages: action.messages,
            };
        case "api:response":
            if (
                "object" !== typeof action.response.data ||
                !("__message" in action.response.data)
            ) {
                return state;
            }
            const messages = [
                ...state.messages,
                ...action.response.data["__message"].map(
                    (text: string) => new DebugMessage(text)
                ),
            ];
            return {
                ...state,
                messages,
            };
    }
    return state;
};

export type ClientStatus =
    | Client.Api.UserStatus
    | "login"
    | "restructuring"
    | false
    | null
    | undefined;

export function useReducer(
    status: ClientStatus,
    api: Client.Api.Instance,
    withoutInitial?: boolean
): [State, Dispatch] {
    const [state, dispatch] = React.useReducer<Reducer>(Reducer, new State([]));
    const location = useLocation();
    const { t } = useLocale("registration");

    useEndpointEffect(api.endpoint, dispatch);

    const match = useRouteMatch(
        `${LOCALE_ROUTE_PATH}/:type(p|b)/:promoShortUrl`
    );

    React.useEffect(() => {
        if (withoutInitial) {
            return;
        }

        const term = CalculatorStorage.getCalc()?.term ?? -Infinity;

        dispatch(
            new FlushAction(
                State.getInitialMessages(
                    status,
                    t,
                    location.pathname,
                    term > 30 && CalculatorStorage.getSynced(),
                    match?.params
                )
            )
        );
    }, [status, dispatch, location.pathname]);

    return [state, dispatch];
}

export type Dispatch = React.Dispatch<Action>;
export const DispatchContext = React.createContext<Dispatch>(() => undefined);
DispatchContext.displayName = "Chat.DispatchContext";

export function useDispatchContext(): Dispatch {
    return React.useContext(DispatchContext);
}
