import * as React from "react";
import { LocalizedRoute } from "app/locale";
import { Page } from "./Page";

export const Route: () => React.ReactElement = () => {
    return (
        <LocalizedRoute
            key="account-deletion"
            path="/account-deletion"
            exact
            component={Page}
        />
    );
};
