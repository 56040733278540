// tslint:disable:max-line-length
export const Phone = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon_phone"
  >
    <path
      d="M19.5556 14.8149L16.3462 14.3037C15.8437 14.1323 15 14.5 14.9194 14.6045L12.8955 16.1324C10.5483 14.8794 9.1025 13.4341 7.86669 11.1045L9.34958 9.13336C9.5 9 9.87303 8.18658 9.70747 7.65923L9.19428 4.44441C9.19433 3.64795 8.54638 3 7.74997 3H4.44436C3.64795 3 3 3.64795 3 4.44436C3 13.5733 10.4268 21 19.5556 21C20.352 21 21 20.352 21 19.5556V16.2593C21 15.4629 21 15 19.5556 14.8149Z"
      stroke="url(#paint0_linear_381_9498)"
      strokeWidth="2"
    />
    <path
      d="M19.5556 14.8149L16.3462 14.3037C15.8437 14.1323 15 14.5 14.9194 14.6045L12.8955 16.1324C10.5483 14.8794 9.1025 13.4341 7.86669 11.1045L9.34958 9.13336C9.5 9 9.87303 8.18658 9.70747 7.65923L9.19428 4.44441C9.19433 3.64795 8.54638 3 7.74997 3H4.44436C3.64795 3 3 3.64795 3 4.44436C3 13.5733 10.4268 21 19.5556 21C20.352 21 21 20.352 21 19.5556V16.2593C21 15.4629 21 15 19.5556 14.8149Z"
      stroke="url(#paint1_radial_381_9498)"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_381_9498"
        x1="12"
        y1="3"
        x2="17.2422"
        y2="19.3157"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B2FF" />
        <stop offset="1" stopColor="#0F85FF" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_381_9498"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.9223 3) rotate(88.4063) scale(21.8656 8.30061)"
      >
        <stop stopColor="#72D9FF" />
        <stop offset="1" stopColor="#65C4FF" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
