import * as React from "react";
import {ConfigurationValue} from "modules/client/api/configuration";

export const DefaultConfigurationValue: ConfigurationValue = {
    phones: {
        collection: [],
        support: [],
    },
}

export const Context = React.createContext<ConfigurationValue>(DefaultConfigurationValue);

