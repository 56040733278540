// tslint:disable:max-line-length
export const Flash = () => (
  <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
      <path fill="#D9D9D9" d="M0 0H48V48H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M19.6491 39.749L33.5991 22.999H24.5991L26.3991 8.69905L13.7491 26.999H21.4491L19.6491 39.749ZM17.9991 29.999H10.8491C10.2534 29.999 9.81275 29.7324 9.52729 29.199C9.24182 28.6657 9.28242 28.149 9.64909 27.649L26.5491 3.24905C26.8158 2.88238 27.1491 2.63238 27.5491 2.49905C27.9491 2.36571 28.3491 2.38238 28.7491 2.54905C29.1491 2.71571 29.4658 2.98521 29.6991 3.35755C29.9324 3.72988 30.0158 4.12705 29.9491 4.54905L27.9991 19.999H36.7991C37.4445 19.999 37.8975 20.2824 38.1581 20.849C38.4188 21.4157 38.3491 21.949 37.9491 22.449L19.3991 44.699C19.1177 45.0292 18.7723 45.245 18.363 45.3465C17.9537 45.4482 17.5658 45.4115 17.1991 45.2365C16.8324 45.0615 16.5408 44.7928 16.3241 44.4303C16.1074 44.0678 16.0324 43.674 16.0991 43.249L17.9991 29.999Z"
        fill="url(#b)"
      />
      <path
        d="M19.6491 39.749L33.5991 22.999H24.5991L26.3991 8.69905L13.7491 26.999H21.4491L19.6491 39.749ZM17.9991 29.999H10.8491C10.2534 29.999 9.81275 29.7324 9.52729 29.199C9.24182 28.6657 9.28242 28.149 9.64909 27.649L26.5491 3.24905C26.8158 2.88238 27.1491 2.63238 27.5491 2.49905C27.9491 2.36571 28.3491 2.38238 28.7491 2.54905C29.1491 2.71571 29.4658 2.98521 29.6991 3.35755C29.9324 3.72988 30.0158 4.12705 29.9491 4.54905L27.9991 19.999H36.7991C37.4445 19.999 37.8975 20.2824 38.1581 20.849C38.4188 21.4157 38.3491 21.949 37.9491 22.449L19.3991 44.699C19.1177 45.0292 18.7723 45.245 18.363 45.3465C17.9537 45.4482 17.5658 45.4115 17.1991 45.2365C16.8324 45.0615 16.5408 44.7928 16.3241 44.4303C16.1074 44.0678 16.0324 43.674 16.0991 43.249L17.9991 29.999Z"
        fill="url(#c)"
      />
    </g>
    <defs>
      <radialGradient
        id="c"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(.97878 52.20513 -13.36105 .2505 15.653 2.41)"
      >
        <stop stopColor="#72D9FF" />
        <stop offset="1" stopColor="#65C4FF" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="b"
        x1="23.826"
        y1="2.41"
        x2="40.527"
        y2="37.44"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B2FF" />
        <stop offset="1" stopColor="#0F85FF" />
      </linearGradient>
    </defs>
  </svg>
);
