import * as Client from "modules/client";
import {Capacitor} from "@capacitor/core";


export const prepareTerm = (term: Client.Api.ConditionsItem, isBerserk?: boolean): Client.Api.ConditionsItem => {
    if (isBerserk) {
        if (Capacitor.isNativePlatform()) {
            return {
                ...term,
                value: 30,
                min: 1,
                max: 90,
                limit: 30
            };
        } else {
            return {
                ...term,
                value: 70,
                min: 61,
                max: 70,
                limit: 70
            };
        }
    }

    return term;
};
