import * as Chat from "modules/chat";
import * as State from "../state";
import { WelcomeMessage } from "./welcome-message";
import { IntroMessage } from "./intro-message";
import { FormMessage } from "./form-message";
import { LoadingMessage } from "./loading-message";
import { FormSubmitMessage } from "../calculator/form-submit-message";
import { SetPromocodeMessage } from "./set-promocode-message";

export function MessageComponentFactory(
    type: Chat.PromoPage.Message["type"] | string
): State.Message | undefined {
    switch (type) {
        case "promo-page:welcome":
            return WelcomeMessage;
        case "promo-page:intro":
            return IntroMessage;
        case "promo-page:loading":
            return LoadingMessage;
        case "promo-page:form":
            return FormMessage;
        case "calculator:form-submit":
            return FormSubmitMessage;
        case "promo-page:set-promocode":
            return SetPromocodeMessage;
    }
}
