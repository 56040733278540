import * as Client from "modules/client";
import { LoadPdf } from "modules/components/load-pdf";
import React from "react";

export const ButtonAgreement = Client.withApi<{
    id: number;
    className?: string;
}>(({ id, className, api }) => {
    const load = React.useCallback(() => {
        return api.user.agreement.get(id);
    }, []);

    return (
        <LoadPdf
            loadFile={load}
            className={className}
            label={{
                default: "Loan agreement",
                downloaded: "Open agreement",
            }}
        />
    );
});
ButtonAgreement.displayName = "ButtonAgreement";
