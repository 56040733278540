import {SipHashDouble} from "./SipHashDouble";

export async function blobHash(blob: Blob): Promise<string> {
    const buffer = await new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader;
        reader.readAsArrayBuffer(blob);
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = reject;
    });
    const key = SipHashDouble.string16_to_key(blob.type.padStart(16, " "));
    const message = new Uint8Array(buffer);

    return SipHashDouble.hash_hex(key, message);
}
