import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import {Browser} from "@capacitor/browser";

export const DeepLinkCloseBrowserSmileId: string = 'kobogo://closeBrowser/smileId';
export const DeepLinkCloseBrowserDashboardPayment: string = 'kobogo://closeBrowser/dashboard/payment';
export const DeepLinkCloseBrowserMonoDirectDebit: string = 'kobogo://closeBrowser/mono/directDebit';


/** Игнорим тут, чтобы обработать в другом eventListner */
const ignoreUrlList: string[] = [
    DeepLinkCloseBrowserSmileId,
    DeepLinkCloseBrowserDashboardPayment,
    DeepLinkCloseBrowserMonoDirectDebit,
];

export const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();

    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            console.log('AppUrlListener -> event', event);

            if (ignoreUrlList.reduce((c, i) => {
                return c || event.url.startsWith(i)
            }, false)) {
                console.log(`ignoring event for url ${event.url}`);
                return;
            }

            if (event.url.indexOf('kobogo://closeBrowser') > -1) {
                let pathname = event.url.split('kobogo://closeBrowser').pop();
                if (pathname && pathname !== event.url && history) {
                    history.replace(pathname);
                } else {
                    location.reload();
                }

                return;
            }

            const pathname = (new URL(event.url)).pathname;
            if (pathname && history) {
                history.replace(pathname);
                // Если мы уже получили событие appUrlOpen и url открытия,
                // мы могли сюда попасть и из браузера, закрываем на всякий случай тоже.
                Browser.close();
            }
        });
    }, []);

    return null;
};
