import {parseTerm} from "app/chat/calculator/parse-term";
import {CalculatorStorage} from "app/chat/calculator/storage";
import * as Client from "modules/client";
import * as React from "react";
import * as Base from "../base";


export const FormSubmitMessage = React.memo(() => {
    const value = CalculatorStorage.getCalc();
    let promocode = CalculatorStorage.getPromoCode();

    
    if (value == undefined || CalculatorStorage.getSynced()) {
        return null;
    }
    
    return <Submit amount={value.amount} term={parseTerm(value.term)} promocode={promocode?.code}/>;
});

const Submit: React.FC<{ term: number, amount: number, promocode?: string }> = ({term, amount, promocode}) => {
    const dispatch = Client.useDispatchContext();
    
    const handleFinish = React.useCallback(async () => {
        try {
            CalculatorStorage.setSynced(true);
            CalculatorStorage.removePromoCode();
        } catch (error) {
            console.error("FormSubmitMessage", error);
        }
        dispatch(new Client.Auth.RefreshAction());
    }, []);
    
    const handleRequest = React.useCallback(async (api: Client.Api.Instance) => {
        
        return api.user.credit.patch({amount, term, promocode});
    }, [amount, term, promocode]);
    
    return <Base.DelaySubmitMessage onRequest={handleRequest} onFinish={handleFinish}/>;
};

