import * as Base from "app/chat/base";
import * as State from "app/chat/state";
import * as Chat from "modules/chat";
import * as React from "react";


type ButtonMessageProps = State.MessageProps<Chat.SmileId.ButtonMessage>;

export const ButtonMessage: React.FC<ButtonMessageProps> = (value) => {
    const dispatch = Chat.useDispatchContext();

    const handleConfirm = React.useCallback(() => {
        dispatch([
            new Chat.FlushAction([new Chat.SmileId.LoadMessage,]),
        ]);
    }, [dispatch]);

    // TODO: need to change class? @Eugene
    return (
        <Base.ButtonMessage source={"user"} className={"confirm-bank"}>
            <Base.Button theme="primary" onClick={handleConfirm}>
                Continue
            </Base.Button>
        </Base.ButtonMessage>
    );
};
