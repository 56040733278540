export const formatPhoneNumber = (phone: number | string) => {
    const cleaned = ("" + phone).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{1}|\d{3})(\d{3})(\d{4})$/);

    if (match !== null) {
        return (
            "+" + match[1] + " " + match[2] + " " + match[3] + " " + match[4]
        );
    }

    return phone;
};
