import * as Icon from "./icons";
import "./styles.scss";

export type DataType = {
    icon: React.ReactNode;
    title: string;
    text: string;
};

const data: Array<DataType> = [
    {
        icon: <Icon.Flash />,
        title: "Instant loans",
        text: "transfer of money on the your account number",
    },
    {
        icon: <Icon.Clock />,
        title: "1 minute",
        text: "to apply for a loan and get money!",
    },
    {
        icon: <Icon.Device />,
        title: "Easy to use",
        text: "platform is built specially for quick loan",
    },
    {
        icon: <Icon.Shield />,
        title: "Security",
        text: "with SSL protocol",
    },
    {
        icon: <Icon.Chart />,
        title: "Repayment",
        text: "in installments or loan payments",
    },
    {
        icon: <Icon.Figure />,
        title: "Loyalty",
        text: "promotions and discounts",
    },
];

export const Benefits = () => {
    return (
        <div className="benefits">
            {data.map(({ icon, title, text }) => (
                <div key={title} className="benefit">
                    {icon}
                    <h4>{title}</h4>
                    <span>{text}</span>
                </div>
            ))}
        </div>
    );
};
