import { useCallback, useMemo } from "react";
import { useTranslation, UseTranslationOptions } from "react-i18next";
import { useRouteMatch, RouteProps } from "react-router-dom";
import { DEFAULT_LANGUAGE, LOCALE_ROUTE_PATH } from "../i18n";
import {
    RouteLanguage,
    LocaleLanguage,
    LocaleMatch,
    ResourceKeys,
} from "../types";
import { LocalizeObject, localizeObject } from "../utils";
import { languageShortName } from "modules/utils/language-short-name";

type TranslateOptions = {
    [key: string]: any;
};
export type Translate = (keys: ResourceKeys, opt?: TranslateOptions) => string;
export type ChangeLanguage = (locale?: LocaleLanguage) => Promise<void>;
export type JoinBasePath = (path: string | object, base?: string) => string;
export type LocalizeRoutePath = (
    path?: RouteProps["path"],
    joiner?: (item: string) => string
) => string | string[] | undefined;
export type CreateBasePath = (language: LocaleLanguage) => string;

export interface UseLocaleHook {
    (ns?: string | string[], options?: UseTranslationOptions): {
        t: Translate;
        changeLanguage: ChangeLanguage;
        currentLanguage: LocaleLanguage;
        languages: LocaleLanguage[];
        basePath: string;
        joinBasePath: JoinBasePath;
        localizeRoutePath: LocalizeRoutePath;
        routeMatch?: RouteLanguage;
        createBasePath: CreateBasePath;
        localizeObject: LocalizeObject;
    };
}

export const useLocale: UseLocaleHook = (ns, options) => {
    const { t, i18n } = useTranslation(ns, options);

    const routeMatch =
        useRouteMatch<LocaleMatch>(LOCALE_ROUTE_PATH)?.params.locale;

    const languages = useMemo(
        () => Object.keys(LocaleLanguage),
        []
    ) as LocaleLanguage[];

    const currentLanguage = i18n.language as LocaleLanguage;

    const translate = t as Translate;

    const changeLanguage = useCallback<ChangeLanguage>(
        async (locale) => {
            const nextLocale = locale ?? DEFAULT_LANGUAGE;

            if (nextLocale !== i18n.language) {
                await i18n.changeLanguage(nextLocale);
            }
        },
        [i18n]
    );

    const createBasePath = useCallback<CreateBasePath>(
        (language?: LocaleLanguage) => {
            if (language === DEFAULT_LANGUAGE || language == null) {
                return "/";
            }
            return `/${languageShortName(language)}`;
        },
        []
    );

    const basePath = useMemo<string>(() => {
        return createBasePath(currentLanguage);
    }, [currentLanguage]);

    const joinBasePath = useCallback<JoinBasePath>(
        (path: string | object, base?: string): string => {
            const preparedPath = path.toString().replace(/^\/(ru)?/, "");

            const nextPath = `${base ?? basePath}/${preparedPath}`;

            return nextPath.replace(/\/{2,}/g, "/").replace(/(.+)\/$/, "$1");
        },
        [basePath, currentLanguage]
    );

    const localizeRoutePath = useCallback(
        (path?: RouteProps["path"], joiner = joinBasePath) => {
            switch (typeof path) {
                case "undefined":
                    return path;
                case "object":
                    return path.map((key) => joiner(key));
                default:
                    if (path === "*") {
                        return path;
                    }

                    return joiner(path);
            }
        },
        [joinBasePath]
    );

    return {
        t: translate,
        changeLanguage,
        currentLanguage,
        basePath,
        joinBasePath,
        localizeRoutePath,
        routeMatch,
        languages,
        createBasePath,
        localizeObject,
    };
};

// export const translate = i18n.t.bind(i18n)
