import * as React from "react";
import * as Base from "../base";
import * as Chat from "modules/chat";
import * as State from "app/chat/state";
import { IconExclamation } from "modules/components/Icons/IconExclamation";
import { IconVisaColored } from "modules/components/Icons/IconVisaColored";
import { IconMastercardColored } from "modules/components/Icons/IconMastercardColored";

export const InitMessage: State.Message<
    State.MessageProps<Chat.Card.InitMessage>
> = () => {
    return (
        <Base.WrapMessage source="bot">
            <h4>Dear customer,</h4>
            <p>
                To ensure smooth loan repayment, link a credit or debit card to
                your account for automatic deductions. This hassle-free process
                helps avoid late payment penalties.
            </p>
            <p>
                For security, we will make{" "}
                <strong>a refundable transaction of 50 Naira</strong> to verify
                your card. By proceeding, you agree to this procedure.
            </p>
            <div className="warning">
                <IconExclamation />
                <div className="warning__body">
                    <h5>Accepted credit cards:</h5>
                    <p>
                        <span>
                            <IconVisaColored />
                            &nbsp;Visa
                        </span>
                        <span>
                            <IconMastercardColored />
                            &nbsp;Mastercard
                        </span>
                    </p>
                    <p className="attention">
                        We do not currently support Verve cards
                    </p>
                </div>
            </div>
        </Base.WrapMessage>
    );
};
