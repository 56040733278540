import {useLocale} from "app/locale";
import * as Base from "../base";
import {MessageComponent} from "./message-component";
import {useIsMobile} from "modules/hooks/is-mobile";

export const IntroMessage: MessageComponent = () => {
    const {t} = useLocale("main")
    
    if (useIsMobile()) {
        return null;
    }
    return <Base.WrapMessage source="bot">
        <p>{t("intro-message.title")}</p>
        <p>{t("intro-message.text")}:</p>
    </Base.WrapMessage>
};
