export const userStatuses = [
    "registration",
    "pending",
    "card",
    "agreement",
    "restructuring",
    "dashboard",
    "bankId",
    "password",
    "phoneId",
    "contacts",
    "faceId",
    "directDebit",
] as const;

export type UserStatus = (typeof userStatuses)[number];

export interface User {
    id: number;
    phone: number;

    status: UserStatus;
    stats: {
        outpayment: {
            count: number;
        };
    };
    token: string;

    photo?: string;
    email?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
}
