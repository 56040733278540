import * as React from "react";
import { Decor } from "./Decor";
import { useIsMobile } from "modules/hooks/is-mobile";

export const Layout: React.FC = ({ children }) => {
    const isMobile = useIsMobile();
    return (
        <main className="main">
            {children}
            {isMobile && <Decor />}
        </main>
    );
};
Layout.displayName = "main.Layout";
