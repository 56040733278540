import * as React from "react";
import * as About from "./about";
import * as Docs from "./documentation";
import * as Faq from "./faq";
import * as Contacts from "./contacts";
import * as AuthToken from "./auth-token";
import * as DebugInfo from "./debug-info";
import * as LeaveFeedback from "./leave-feedback";

export const routes = (): Array<React.ReactElement> => {
    return [
        About.Route(),
        Docs.Route(),
        Faq.Route(),
        Contacts.Route(),
        AuthToken.Route(),
        DebugInfo.Route(),
        LeaveFeedback.Route(),
    ];
};
