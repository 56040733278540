// tslint:disable:max-line-length
export const IconAccordionArrow = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_accordion-arrow"
    >
        <mask
            id="mask0_1059_28472"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1059_28472)">
            <path
                d="M17.7002 9.7002L11.7002 15.7002L5.7002 9.7002L7.1002 8.3002L11.7002 12.9002L16.3002 8.3002L17.7002 9.7002Z"
                fill="#1A4C6C"
            />
        </g>
    </svg>
);
