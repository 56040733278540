import { CalculatorMessage } from "app/chat/calculator/calculator-message";
import * as Chat from "modules/chat";
import { memo } from "react";
import * as State from "app/chat/state";

export const ValidateTermMessage: State.Message = memo(({ value }) => {
    return (
        <CalculatorMessage
            value={value}
            submitMessage={[
                new Chat.Registration.UpdateTermMessage(),
                new Chat.Registration.ValidateMessage(),
            ]}
        />
    );
});

ValidateTermMessage.displayName = "Calculator.ValidateTermMessage";
