import { useEffect } from "react";
import * as Client from "modules/client";
import {LoadMessage} from "./load-message";

export const RefreshClientStateMessage = () => {
    const dispatch = Client.useDispatchContext();

    useEffect(() => {
        const timeout = setTimeout(() => {
            dispatch(new Client.Auth.RefreshAction());
        }, 5000);

        return () => clearTimeout(timeout);
    }, []);

    return <LoadMessage />;
}
