import * as React from "react";
import { Logo } from "modules/components/header";
import * as Main from "modules/components/main";
import * as ErrorBot from "modules/components/bot/error";
import * as Footer from "modules/components/footer";
import { PhoneList } from "app/chat/base/phone-list";
import "./styles.scss";
import "modules/components/error-page/styles.scss";

export type ErrorBoundaryState = {} | { error: Error };

const Layout = () => {
    React.useEffect(() => {
        document.body.dataset["theme"] = "chat-wait";
    }, []);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            window.location.reload();
        }, 4000);
        return () => clearTimeout(timer);
    }, []);

    return <>
        <header className="header">
            <div className="container">
                <div className="logo">
                    <Logo type={"color"} />
                </div>
            </div>
        </header>
        <Main.Layout>
            <div className="status-wrap">
                <div className="status">
                    <div className="animation-container">
                        <div className="tooltip">
                            <span className="emoji emoji_reload"/>
                        </div>
                        <ErrorBot.Layout/>
                    </div>
                    <h2>Что то не так!</h2>
                    <p>Пожалуйста, обновите страницу или свяжитесь с нами:</p>
                    <PhoneList />
                    <div className="btn-container">
                        <button onClick={() => window.location.reload()} className="btn btn_blue">
                            Обновить страницу
                        </button>
                    </div>
                </div>
            </div>
        </Main.Layout>
        <Footer.Layout/>
    </>
}


export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
    public readonly state: ErrorBoundaryState = {};

    static getDerivedStateFromError(error: Error) {
        return {error};
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    }

    public render() {
        if ("error" in this.state) {
            return <Layout />;
        }

        return this.props.children;
    }
}
