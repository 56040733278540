import * as React from "react";
import { LocalizedLink } from "app/locale";
import { useTheme } from "../../hooks/use-theme";
import * as ErrorFace from "modules/components/bot/error";
import { IconArrow } from "modules/components/Icons/IconArrow";

import "./styles.scss";

export interface StageProps {
    errorNumber: string;
    underTitle: string;
}

export const Layout: React.FC<StageProps> = (props) => {
    useTheme("chat-wait");

    return (
        <>
            <h1>Oops!</h1>
            <p className="under-title">{props.underTitle}</p>
            <div className="animation-container">
                <ErrorFace.Layout />
            </div>
            <div className="btn-container">
                {props.children}
                {props.errorNumber === "404" ? (
                    <LocalizedLink to="/chat" className="btn btn_white">
                        <span>To home</span>
                        <IconArrow />
                    </LocalizedLink>
                ) : (
                    <LocalizedLink to="/chat" className="btn btn_white">
                        <span>Refresh</span>
                        <IconArrow />
                    </LocalizedLink>
                )}
            </div>
        </>
    );
};

Layout.displayName = "ErrorPage.Layout";
