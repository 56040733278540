import * as React from "react";
import {Action} from "modules/components/promocode/action";
import {Promocode} from "modules/chat/promo-page";

export type Dispatch = React.Dispatch<Action>;

export function useState(value: string): [Promocode, Dispatch] {
    const [state, dispatch] = React.useReducer<React.Reducer<Promocode, Action>>(
        (state, action) => {
            switch (action.type) {
                case "promocode:change":
                    return {value: action.promocode};
                case "promocode:valid":
                    return {
                        isValid: true,
                        value: state.value.toUpperCase(),
                        discount: action.discount,
                    };
                case "promocode:invalid":
                    return {
                        isValid: false,
                        value: state.value,
                    };
            }
        },
        {value: value}
    );

    return [state, dispatch];
}
