import * as React from "react";
import * as Chat from "modules/chat";
import * as Base from "../base";
import {MockDecision} from "../../../modules/client/api/smile-id";

export const MockButtonMessage: React.FC<{}> = () => {
    const dispatch = Chat.useDispatchContext();

    const handleClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        const decision: MockDecision = ((e.target as any as HTMLButtonElement).id === "mock-approve")
            ? "Approved"
            : "Rejected";
        dispatch([
            new Chat.FlushAction([
                new Chat.SmileId.MockLoadMessage(decision),
            ]),
        ]);
    }, [dispatch]);

    return (
        <Base.ButtonMessage source={"user"} className={"mock-button"}>
            <div className="form-group">
                <Base.Button
                    id="mock-reject"
                    type="button"
                    theme={"secondary"}
                    onClick={handleClick}
                >
                    Mock Reject
                </Base.Button>
                <Base.Button
                    id="mock-approve"
                    type="button"
                    theme={"primary"}
                    onClick={handleClick}
                >
                    Mock Approve
                </Base.Button>
            </div>
        </Base.ButtonMessage>
    );
};
