import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import { requestMapper } from "./request-mapper";

export interface Request {
    id: number;
    applicationId: number;
    number: number;
    amount: number;
    term: number;
    created: string;
    status: Status;
    initDebtSum: number;
    initReturnDate: string;
    rate: Rates;
    defaultRate: Rates;
    gracePeriod: number;
    commissionSum: number;
    dateReturn: string;
    restructuring?: Restructuring;
    overdue?: number;
    datePaid?: string;
    dateRepaid?: number;
    paid?: number;
    debt?: Debt;
    sell?: Sell;
    promocode?: {
        value: string;
        discount: number;
    };
    type: number;
    agreementSignUrl?: string;
}

export type Debt = {
    body: number;
    interest: number;
    penalty: number;
    total: number;
    prolongation?: number;
    restructuring?: number;
};

export type Rates = {
    interest: number;
    penalty: number;
};

export type Sell = {
    company: {
        name: string;
        requisites: string;
    };
    date: string;
};

export type Status =
    | "New"
    | "Processing"
    | "Reject"
    | "Accept"
    | "Active"
    | "Closed"
    | "Canceled"
    | "Restructured"
    | "Sold";

export type RestructuringScheduleItem = {
    amount: number;
    date: string;
    debt: number;
};

export type Restructuring = {
    schedule: RestructuringScheduleItem[];
} & ({ expireAt: string } | { signAt: string });

type PatchForm = Partial<{
    amount: number;
    term: number;
    promocode: string;
}>;

export const Api = (endpoint: AxiosInstance) => ({
    history: async (): Promise<Request[]> => {
        const { data } = await endpoint.get<Request[]>("/v2/requests");

        return data.map(requestMapper);
    },
    get: async (): Promise<Request> => {
        const { data } = await endpoint.get<Request>("/v2/request");

        return requestMapper(data);
    },
    patch: async (form: PatchForm): Promise<Request> => {
        const { data } = await endpoint.patch<Request>(
            "/v2/request",
            {
                Request: form,
            },
            {}
        );

        return requestMapper(data);
    },
    post: async (form: PatchForm): Promise<Request> => {
        const { data } = await endpoint.post(
            "/v2/request",
            {
                Request: form,
            },
            {}
        );

        return requestMapper(data);
    },
    repaymentCertificate: (id: number): Promise<string> => {
        return endpoint
            .get<{ url: string }>("/v2/repayment-certificate", {
                params: {
                    applicationId: id,
                },
            })
            .then(({ data: { url } }) => url);
    },
});

export const calcRemainDays = (date: string) => {
    const remainDays = dayjs(date)
        .startOf("day")
        .diff(dayjs().startOf("day"), "day");

    return Math.max(remainDays, 0);
};
