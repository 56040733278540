import * as React from "react";
import * as State from "app/chat/state";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as Base from "../base";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {Capacitor} from "@capacitor/core";
import {WidgetResponse} from "../../../modules/client/api/smile-id";

export const LoadMessage: State.Message<
    State.MessageProps<Chat.SmileId.LoadMessage>
> = () => {
    const history = useHistory();
    const {api} = Client.useStateContext();
    const dispatch = Chat.useDispatchContext();

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        api.with({cancelToken: cancelToken.token})
            .user.smileId.getWidget()
            .then((widgetResponse: WidgetResponse) => {
                /** Для веба не используем внешние ссылки, идем стандартной интеграцией через iframe*/
                if (!Capacitor.isNativePlatform()) {
                    dispatch([
                        new Chat.ReplaceAction([
                            new Chat.SmileId.WidgetMessage(widgetResponse.config, widgetResponse.job),
                        ])
                    ]);
                    return;
                }

                dispatch([
                    new Chat.ReplaceAction([
                        new Chat.SmileId.OpenBrowserMessage(widgetResponse.widgetUrl, widgetResponse.job.id)
                    ])
                ]);
            })
            .catch((error: Error) => {
                if (!axios.isCancel(error)) {
                    history.push("/failure");
                    return Promise.reject(error);
                }
            });
        return () => cancelToken.cancel();
    }, []);

    return <Base.LoadMessage/>;
};
