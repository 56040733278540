import { useEffect } from "react";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as Base from "../base";
import * as State from "../state";
import axios from "axios";
import {useHistory} from "react-router-dom";

export const RequestMessage = Client.withApi<State.MessageProps<Chat.SignUp.RequestMessage>>(
    ({value, api}) => {
        const dispatch = Chat.useDispatchContext();
        const history = useHistory();

        useEffect(() => {
            const cancelToken = axios.CancelToken.source();
            api.with({
                cancelToken: cancelToken.token,
            }).guest.sign.request(value.phone, value.channel)
                .then((result) => {
                    if (!result.isSuccess) {
                        return history.push("/forbidden");
                    }
                    dispatch([
                        new Chat.RemoveAction(value),
                        new Chat.PushAction([
                            new Chat.SignUp.AgreementMessage(),
                            new Chat.TimeoutMessage(
                                result.expireTimeSeconds,
                                new Chat.SignUp.ResendMessage(value.phone)
                            ),
                            new Chat.SignUp.ConfirmFormMessage(value.phone),
                        ]),
                    ]);
                })
                .catch((error) => {
                    if (!error.response || error.response.status !== 400) {
                        return Promise.reject(error);
                    }
                    dispatch(new Chat.ReplaceAction([
                        new Chat.TextMessage(
                            error.response.data.errors
                                .map(({details}: { details: string }) => details)
                                .join(", ")
                        ),
                        new Chat.SignUp.RequestFormMessage(value.phone),
                    ], value));
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        history.push("/failure");
                        return Promise.reject(error);
                    }
                });
            return () => cancelToken.cancel();
        }, [value.phone]);

        return <Base.LoadMessage/>;
    }
);
