import * as React from "react";
import { CapacitorDeviceInfo } from "../device";

export interface State {
    liveUpdateVersion?: string;
    pushToken?: string;
    deviceInfo?:  Partial<CapacitorDeviceInfo>;
}

export const initialState: State = {};

export const StateContext = React.createContext<State>(initialState);
StateContext.displayName = "CapacitorApp.StateContext";

export function useCapacitorStoreStateContext(): State {
    return React.useContext(StateContext);
}
