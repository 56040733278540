import {AxiosInstance} from "axios";

export type ReviewApiType = "good" | "neutral" | "bad";
export const Api = (endpoint: AxiosInstance) => {
    return {
        sendMark: async (mark: ReviewApiType) => {
            const response = await endpoint.post<{ uuid: string }>("/v3/feedback", {
                Feedback: {
                    mark: mark
                }
            });

            return {
                id: response.data.uuid
            };
        },
        sendText: async (id: string, proposal: string): Promise<string | true> => {
            return endpoint.patch("/v3/feedback",
                {
                    Feedback: {
                        proposal
                    }
                },
                {
                    params: {
                        id
                    }
                }
            );
        },
    } as const;
}
