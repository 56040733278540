import * as React from "react";
import classNames from "classnames";
import { IconPencil } from "modules/components/Icons/IconPencil";

export const ButtonEdit: React.FC<React.HTMLProps<HTMLButtonElement>> = (
    props
) => {
    return (
        <button
            {...props}
            type="button"
            className={classNames(
                "btn btn_action btn_edit enabled",
                props.className
            )}
        >
            <IconPencil />
        </button>
    );
};
