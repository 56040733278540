
import * as Base from "app/chat/base";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import { prepareTerm } from "modules/utils/prepare-term";
import * as React from "react";
import * as State from "../state";
import { useHistory } from "react-router-dom";

export const LoadingMessage: State.Message = React.memo(({ value }) => {
    const conditions = Client.Api.useConditions();
    const dispatch = Chat.useDispatchContext();
    const pathname = useHistory().location.pathname;
    const isClient = pathname.startsWith("/b");

    React.useEffect(() => {
        if (conditions) {
            const { term, amount, interestRate, isBerserk } = conditions;

            const preparedTerm = prepareTerm(term, isBerserk);

            const preparedConditions: Client.Api.Conditions<
                Required<Client.Api.ConditionsItem>
            > = {
                term: {
                    ...preparedTerm,
                    step: 1,
                    value: Math.max(
                        Math.round((preparedTerm.limit || preparedTerm.max) * 0.5 - 1),
                        preparedTerm.min
                    ),
                    limit: preparedTerm.limit || preparedTerm.max,
                },
                amount: {
                    ...amount,
                    step: 50,
                    value:
                        Math.round((amount.limit || amount.max * 0.8) / 100) *
                        100,
                    limit: isClient ? amount.max : amount.limit || amount.max,
                },
                interestRate,
            };

            dispatch(
                new Chat.ReplaceAction(
                    [
                        new Chat.PromoPage.FormMessage(
                            preparedConditions,
                            value.promo
                        ),
                    ],
                    value
                )
            );
        }
    }, [conditions, dispatch]);

    return <Base.LoadMessage />;
});

LoadingMessage.displayName = "PromoPage.LoadingMessage";
