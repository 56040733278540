import { getPlatform } from "modules/utils/get-platform";

export function dateFilter(input: string, prevState?: string): string {
    if (getPlatform() === "iPhone" || getPlatform() === "iPad") {
        return input;
    }

    const prevValue = prevState ? prevState : "";
    if (
        prevValue &&
        prevValue.length > input.length &&
        prevValue.slice(-1) === "-"
    ) {
        return input.slice(0, -1);
    }
    const value = input.replace(/[^0-9]/g, "");
    const curDate =
        value.slice(0, 2) +
        (value[1] ? "-" : "") +
        value.slice(2, 4) +
        (value[3] ? "-" : "") +
        value.slice(4);

    function isValid(generatedDate: string) {
        const date = new Date(generatedDate);
        const month = generatedDate.split("-")[1];
        const dateYear = date.getFullYear();
        const monthFromObj = date.getMonth() + 1;
        const expireYear = new Date().getFullYear() + 21;
        return (
            +month === monthFromObj &&
            1950 < dateYear &&
            dateYear < expireYear &&
            !isNaN(date.getTime())
        );
    }

    const curLength = curDate.length;

    function dmy(curDate: string) {
        const validDates = [
            "01-01-2020",
            "10-10-2020",
            "01-01-1992",
            "10-10-1992",
        ];
        for (let validDate of validDates) {
            let generatedDate = curDate + validDate.substr(curLength);
            const splitDate = generatedDate.split("-");
            generatedDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
            if (isValid(generatedDate)) {
                return true;
            }
        }
        return false;
    }

    if (dmy(curDate)) {
        return curDate;
    }
    return dateFilter(value.slice(0, -1), "");
}
