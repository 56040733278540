import * as UserInfo from "app/dashboard/user-info";
import {LocalizedLink, LocalizedNavLink, StateWithFrom, useLocale} from "app/locale";
import * as Accordion from "modules/accordion";
import {Logo} from "modules/components/header";
import {IconHamburger} from "modules/components/Icons/IconHamburger";
import * as Social from "modules/components/Social";
import {useIsMobile} from "modules/hooks/is-mobile";
import * as React from "react";
import {useLocation} from "react-router-dom";
import {ButtonLogout} from "./ButtonLogout";
import {homeList, List, useDashboardList} from "./List";

import "./styles.scss";

export const Layout: React.FC = React.memo(() => {
    const isMobile = useIsMobile();
    const {t} = useLocale("nav-list");
    const location = useLocation<StateWithFrom>();
    
    const dashboardList = useDashboardList();
    
    return (
        <div className="nav-dashboard">
            <div className="nav-heading">
                <div className="h-align">
                    {isMobile &&
                    <LocalizedNavLink to="/" className="logo">
                        <Logo type={"white"}/>
                    </LocalizedNavLink>}
                    {isMobile && <LocalizedLink
                        exclude={[/\/nav$/, /\/support-chat/]}
                        to={location.state?.from ?? {}}
                        type="button"
                        className="menu-hamburger active"
                    >
                        <div className="overlay">
                            <IconHamburger/>
                        </div>
                    </LocalizedLink>}
                </div>
                <UserInfo.Layout/>
            </div>
            <div className="nav-body">
                <div className="accordion">
                    <Accordion.Controller defaultActive="1">
                        <Accordion.Item id="1" title={t("my-profile")}>
                            <List items={dashboardList}/>
                            {isMobile && <ButtonLogout/>}
                        </Accordion.Item>
                        <Accordion.Item id="2" title={t("site-menu")}>
                            <List items={homeList}/>
                        </Accordion.Item>
                    </Accordion.Controller>
                </div>
            </div>
            <div className="nav-footer">
                {isMobile
                    ? <>
                        <Social.Layout/>
                    </>
                    : <ButtonLogout/>
                }
            </div>
        </div>
    );
});

Layout.displayName = "DashboardNav.Layout";

export default Layout;
