import * as Api from "modules/client/api";

export class InitDashboardMessage {
    public readonly type = "bank-account:init-dashboard";
}

export class InitMessage {
    public readonly type = "bank-account:init";
}

export class WelcomeMessage {
    public readonly type = "bank-account:welcome";
}

export class SelectMethodMessage {
    public readonly type = "bank-account:select-method";
    constructor(public readonly methods: Array<Api.BankAccount.Method>) {
    }
}

export class SelectNubankBankLoadMessage {
    public readonly type = "bank-account:nuban-select-bank-load";
}

export class SelectNubanBankMessage {
    public readonly type = "bank-account:nuban-select-bank";
    constructor(public readonly banks: Array<Api.BankAccount.Bank>) {
    }
}

export class SelectNubanMessage {
    public readonly type = "bank-account:nuban-select";
    constructor(public readonly bankCode: string, public readonly accountNumber: string = '') {
    }
}

export class SelectNubanLoadMessage {
    public readonly type = "bank-account:nuban-select-load";
    constructor(public readonly bankCode: string, public readonly accountNumber: string) {
    }
}

export class ConfirmNubanMessage {
    public readonly type = "bank-account:nuban-confirm";
    constructor(public readonly bankCode: string, public readonly accountNumber: string) {
    }
}
export class ConfirmNubanLoadMessage {
    public readonly type = "bank-account:nuban-confirm-load";
    constructor(public readonly bankCode: string, public readonly accountNumber: string) {
    }
}

export type Message =
    InitMessage
    | WelcomeMessage
    | SelectMethodMessage
    | SelectNubankBankLoadMessage
    | SelectNubanBankMessage
    | SelectNubanLoadMessage
    | SelectNubanMessage
    | ConfirmNubanMessage
    | ConfirmNubanLoadMessage
    | InitDashboardMessage
    ;
