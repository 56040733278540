import {Capacitor,} from '@capacitor/core';
import {FirebaseAnalytics} from '@capacitor-firebase/analytics';
import {FlexibleStorage} from "flexible-storage";
import {initCapacitorBrowser} from "./browser";
import {initPushNotification} from "./push-notifications";
import {History} from "history";
import {grabAllDeviceInfo} from "./device";
// import {initKochavaSdk} from "./kochava";
import * as Sentry from "@sentry/capacitor";
import { AdjustSdk } from "./adjust-sdk";
import { initAppsFlyer } from "./apps-flyer";
import { CapacitorLiveUpdate, initLiveUpdate } from "./live-update";
import { initUpdateApp } from "./update";
import * as React from "react";
import { CapacitorStoreAction } from "./store/reducer";

export const CapacitorFlexibleStorage: FlexibleStorage = new FlexibleStorage(window.localStorage, "capacitor:");

declare global {
    interface Window {
        installReferrer?: {
            getReferrer: (
                success: (installReferrerObj: installReferrerObj) => void,
                error: (error: string) => void
            ) => void;
        };
    }
}

export interface installReferrerObj {
    clickTs: string;
    installTs: string;
    isInstantExperienceLaunched: string;
    responseCode: installReferrerResponseCode;
    utm_medium: string;
    utm_source: string;
    originalReferrer: string;
}
export enum installReferrerResponseCode {
    OK = "0",
    FEATURE_NOT_SUPPORTED = "1",
    SERVICE_UNAVAILABLE = "2"
}

function initClasses() {
    document.body.classList.add('capacitor');
    document.body.classList.add(`capacitor__${Capacitor.getPlatform()}`);
    document.documentElement.classList.add('capacitor');
    document.documentElement.classList.add(`capacitor__${Capacitor.getPlatform()}`);
}

async function initFirebaseAnalytics() {
    await FirebaseAnalytics.setEnabled({
        enabled: true,
    });
}

async function initFacebookAnalytics() {
    // setAutoLogAppEventsEnabled не представлено в андроиде. Поэтому не используем
    // await FacebookLogin.setAutoLogAppEventsEnabled({enabled: true});
}

function initHelper(initFunction: () => Promise<unknown> | void) {
    try {
        const result = initFunction();
        if (result instanceof Promise) {
            return result.catch(e => {
                console.warn(e);
                Sentry.captureException(e);
            });
        }
    } catch (e) {
        console.warn(e);
        Sentry.captureException(e);
    }
}

export async function initCapacitorPlugins(history: History, dispatch: React.Dispatch<CapacitorStoreAction>) {
    if (!Capacitor.isNativePlatform()) {
        return;
    }

    await Promise.all([
        initHelper(() =>
            initLiveUpdate().then(async () =>
                dispatch({
                    type: "SET_LIVE_UPDATE_VERSION",
                    payload:
                        (await CapacitorLiveUpdate.getUsedVersion()) ||
                        undefined,
                })
            )
        ),
        // initHelper(initKochavaSdk),
        initHelper(AdjustSdk.init),
        initHelper(initFirebaseAnalytics),
        initHelper(initFacebookAnalytics),
        initHelper(initClasses),
        initHelper(initCapacitorBrowser),
        initHelper(async () =>
            initPushNotification(history)
                .then((token) => {
                    dispatch({ type: "SET_PUSH_TOKEN", payload: token });
                })
                .catch(() => {
                    dispatch({ type: "SET_PUSH_TOKEN", payload: undefined });
                })
        ),
        initHelper(() =>
            grabAllDeviceInfo().then((deviceInfo) => {
                dispatch({ type: "SET_DEVICE_INFO", payload: deviceInfo });
            })
        ),
        initHelper(initAppsFlyer),
        initHelper(initUpdateApp),
    ]);
}
