import {RouteLanguage, LocaleLanguage} from "../../app/locale/types";

type ReturnType<T> = T extends undefined ? undefined : (T extends RouteLanguage ? LocaleLanguage : RouteLanguage)
export const languageShortName = <T extends RouteLanguage | LocaleLanguage | undefined>
(shortName?: T): ReturnType<T> => {
    let newShortname;
    switch (shortName) {
        case RouteLanguage.ua:
            newShortname = LocaleLanguage.uk;
            break
        case LocaleLanguage.uk:
            newShortname = RouteLanguage.ua;
            break
        default:
            newShortname = shortName;
    }
    return newShortname as ReturnType<T>
}