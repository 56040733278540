import * as Chat from "modules/chat";
import * as React from "react";
import { CalculatorStorage } from "app/chat/calculator/storage";
import { parseTerm } from "app/chat/calculator/parse-term";
import dayjs from "dayjs";

export type CalculatorMessageType =
    | Chat.Calculator.FormMessage
    | Chat.PromoPage.FormMessage
    | Chat.Calculator.CalculatorOnlyMessage;

export function useCalc(message: CalculatorMessageType) {
    React.useEffect(() => {
        const { amount, term } = message.conditions;

        CalculatorStorage.setCalc(amount.value, term.value);
    }, [message.conditions.amount.value, message.conditions.term.value]);

    const { conditions } = message;
    const interest = Math.round(
        conditions.amount?.value *
            parseTerm(conditions.term.value) *
            conditions.interestRate
    );
    const returnAmount = Math.round(conditions.amount.value + interest);
    const returnDate = dayjs().add(conditions.term.value, "day");
    const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];

    return {
        returnAmount,
        returnDate,
        days,
        interest,
    };
}
