import * as Chat from "modules/chat";
import {ReplaceAction} from "modules/chat";
import {Markdown} from "modules/components/markdown";


export const UpdateTermMessage = () => {
    const dispatch = Chat.useDispatchContext();
    
    const handleClick = () => {
        dispatch(new ReplaceAction([
            new Chat.Calculator.FormSubmitMessage(),
        ]));
    };
    
    return (
        <div className="message user-message calculator-menu">
            <div className="btn-container">
                <button className="btn btn_blue calculator-submit" type="button" onClick={handleClick}>
                    <Markdown content={"Сохранить"}/>
                </button>
            </div>
        </div>
    );
};
