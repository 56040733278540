import { CalculatorStorage } from "app/chat/calculator/storage";
import * as React from "react";
import { useOpenState } from "@horat1us/react-hooks";
import classNames from "classnames";
import { ChangeAction, InvalidAction, ValidAction } from "./action";
import * as Client from "modules/client";
import * as Chat from "modules/chat/";
import { useIsMobile } from "../../hooks/is-mobile";
import { useLocale } from "app/locale";
import { Dispatch } from "./use-state";
import { Promocode } from "../../chat/promo-page";
import "./styles.scss";
import { IconWrap } from "../icon-wrap";
import { IconGift } from "../Icons/IconGift";
import { IconCross } from "../Icons/IconCross";
import { IconInstagramPromo } from "../Icons/IconInstagramPromo";
import { IconFacebookPromo } from "../Icons/IconFacebookPromo";
import { IconTelegramPromo } from "../Icons/IconTelegtamPromo";
import { IconPromoValid } from "../Icons/IconPromoValid";
import { IconPromoInvalid } from "../Icons/IconPromoInvalid";
import { IconDiscount } from "../Icons/IconDiscount";

const PromocodeInfo = () => {
    const { t } = useLocale("main");

    return (
        <div className="promocode__info">
            {useIsMobile() && <p>{t("calc-message.promo-text-mob")}</p>}
            <div className="social-list">
                <a
                    href="https://www.instagram.com/lovilave.ukraine/?hl=ru"
                    target="_blank"
                >
                    <IconWrap color="white" radius="left-bottom">
                        <IconInstagramPromo />
                    </IconWrap>
                </a>
                <a
                    href="https://www.facebook.com/LoviLave-106735147784134/?modal=admin_todo_tour"
                    target="_blank"
                >
                    <IconWrap color="orange" radius="left-top">
                        <IconFacebookPromo />
                    </IconWrap>
                </a>
                <a href="https://t.me/lovilaveua" target="_blank">
                    <IconWrap color="blue">
                        <IconTelegramPromo />
                    </IconWrap>
                </a>
            </div>
        </div>
    );
};

const Icon: React.FC<{ isValid?: boolean }> = ({ isValid }) => {
    switch (isValid) {
        case true:
            return <IconPromoValid />;
        case false:
            return <IconPromoInvalid />;
    }

    return <IconDiscount />;
};

interface PromocodeProps {
    message:
        | Chat.Calculator.FormMessage
        | Chat.Calculator.CalculatorOnlyMessage;
    state: Promocode;
    dispatch: Dispatch;
}

export const Layout = Client.withApi<PromocodeProps>(
    ({ api, message, state, dispatch }) => {
        React.useEffect(() => {
            const code = CalculatorStorage.getPromoCode()?.code;
            code &&
                api.user.promocode.get(code).then((discount) => {
                    discount && dispatch(new ChangeAction(code));
                });
        }, []);

        React.useEffect(() => {
            if ("isValid" in state || state.value.length <= 2) {
                return;
            }
            const cancel = api.cancellable();
            api.user.promocode.get(state.value).then((discount) => {
                dispatch(
                    discount ? new ValidAction(discount) : new InvalidAction()
                );
            });

            return cancel;
        }, [state.value, state.isValid]);

        const handleChange = React.useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(new ChangeAction(e.target.value.toUpperCase())),
            []
        );

        const dispatchMessage = Chat.useDispatchContext();

        React.useEffect(() => {
            if (state.isValid) {
                let newMessage = message;

                if (message instanceof Chat.Calculator.FormMessage) {
                    newMessage = new Chat.Calculator.FormMessage({
                        ...message.conditions,
                        interestRate:
                            message.conditions.interestRate -
                            message.conditions.interestRate * state.discount,
                    });
                } else {
                    newMessage = new Chat.Calculator.CalculatorOnlyMessage(
                        {
                            ...message.conditions,
                            interestRate:
                                message.conditions.interestRate -
                                message.conditions.interestRate *
                                    state.discount,
                        },
                        {
                            value: state.value,
                            discount: state.discount,
                        }
                    );
                }

                dispatchMessage(new Chat.ReplaceAction([newMessage], message));

                return () => {
                    dispatchMessage(
                        new Chat.ReplaceAction([message], newMessage)
                    );
                };
            }
        }, [state.isValid]);

        return (
            <div className="promocode">
                <p className="promocode__title">
                    Enter promo code and get a discount!
                </p>
                <div
                    className={classNames(
                        "input-group",
                        // Refactor
                        state.isValid === true
                            ? "success"
                            : state.isValid === false
                            ? "error"
                            : null
                    )}
                >
                    <input
                        type="text"
                        className="form__control"
                        placeholder="KOBOGO"
                        value={state.value}
                        onChange={handleChange}
                    />
                    <Icon isValid={state.isValid} />
                </div>
                {state.isValid === false && (
                    <p className="promocode__hint">
                        You entered an invalid promo code, please check and turn
                        it on again
                    </p>
                )}
            </div>
        );
    }
);

Layout.displayName = "Promocode.Layout";
