import { useCallback } from "react";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as Base from "../base";
import * as State from "../state";
import dayjs from "dayjs";

export const CompleteMessage: State.Message<
    State.MessageProps<Chat.Registration.CompleteMessage>
> = (props) => {
    const dispatch = Chat.useDispatchContext();

    const handleChange = useCallback(
        (name: string) => {
            const item = props.value.schema.properties[name];
            if (item === undefined) {
                return;
            }

            const value =
                item.format === "date"
                    ? dayjs(props.value.values[name].toString()).format(
                          "DD.MM.YYYY"
                      )
                    : props.value.values[name].toString();

            dispatch(
                new Chat.ReplaceAction([
                    new Chat.Registration.FormMessage(
                        name,
                        item.title || name,
                        value,
                        item
                    ),
                ])
            );
        },
        [props.value.schema.properties, dispatch, props.value.values]
    );

    const items = Object.entries<Client.Api.DataAttributeSchema>(
        props.value.schema.properties
    )
        .map(
            ([name, schema]): [
                string,
                Client.Api.DataAttributeSchema,
                string | number | undefined
            ] => [name, schema, props.value.values[name]]
        )
        .filter(
            (
                arr
            ): arr is [
                string,
                Client.Api.DataAttributeSchema,
                string | number
            ] => arr[2] !== undefined
        )
        .map(([name, schema, value]) => {
            if (Array.isArray(schema.oneOf)) {
                value =
                    (schema.oneOf as Array<{ const: any; title: string }>).find(
                        ({ const: c }: { const: string }) => c === value
                    )?.title ?? value;
            }
            return {
                name,
                value: value.toString(),
                label: schema.title || name,
            };
        });

    return (
        <Base.Summary.Message
            title="Loan request"
            items={items}
            onChange={handleChange}
        />
    );
};
