import * as Base from "app/chat/base";
import * as State from "app/chat/state";
import * as Chat from "modules/chat";
import * as React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { RefreshAction } from "../../../modules/client/auth";
import * as Client from "../../../modules/client";

type ShareContactsLoadMessageProps =
    State.MessageProps<Chat.ShareContacts.ShareContactsLoadMessage>;

// TODO: refactor using DelaySubmitMessage
export const ShareContactsLoadMessage: React.FC<
    ShareContactsLoadMessageProps
> = ({ value }) => {
    const { api } = Client.useStateContext();
    const dispatch = Chat.useDispatchContext();
    const dispatchClient = Client.useDispatchContext();
    const history = useHistory();

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        if (!value.contacts) {
            return;
        }

        api.with({ cancelToken: cancelToken.token })
            .user.contacts.sendContacts(value.contacts)
            .then((): void => {
                setTimeout((): void => {
                    dispatch(
                        new Chat.FlushAction([
                            new Chat.Registration.CompleteInitMessage(),
                        ])
                    );
                }, 5000);
            })
            .catch((error: Error) => {
                if (!axios.isCancel(error)) {
                    history.push("/failure");
                    return Promise.reject(error);
                }
            });

        return () => cancelToken.cancel();
    }, []);

    return (
        <>
            <Base.WrapMessage source="bot">
                <p>Processing...</p>
            </Base.WrapMessage>
        </>
    );
};
