import * as Chat from "modules/chat";
import * as Base from "../base";
import { useEffect } from "react";
import { IconArrow } from "modules/components/Icons/IconArrow";

export const CompleteFormMessage = () => {
    const dispatch = Chat.useDispatchContext();

    useEffect(() => {
        return () => localStorage.removeItem("firstCalculator.done");
    }, []);

    const completeText = 'Done';
    const handleConfirm = () => {
        dispatch(
            new Chat.ReplaceAction([
                new Chat.TextMessage(completeText, "user"),
                new Chat.Registration.CompleteSubmitMessage(),
            ])
        );
    };
    return (
        <Base.ButtonMessage source={"user"}>
            <Base.Button
                id={"complete-registration-submit"}
                theme="secondary"
                onClick={handleConfirm}
            >
                <span>{completeText}</span>
                <IconArrow />
            </Base.Button>
        </Base.ButtonMessage>
    );
};
