import * as Chat from "modules/chat";
import * as State from "../state";
import {InitMessage} from "./init-message";
import {ConfirmMessage} from "./confirm-message";
import {LoadMessage} from "./load-message";
import {WidgetMessage} from "./widget-message";
import {WelcomeMessage} from "./welcome-message";
import {MandatePendingMessage} from "./mandate-pending-message";
import {MandateFailedMessage} from "./mandate-failed-message";
import {MandateSuccessMessage} from "./mandate-success-message";
import {MandatePendingTooLongMessage} from "./mandate-pending-too-long-message";

export function MessageComponentFactory(
    type: Chat.DirectDebit.Message["type"]
): State.Message | undefined {
    switch (type) {
        case "direct-debit:init":
            return InitMessage;
        case "direct-debit:confirm":
            return ConfirmMessage;
        case "direct-debit:load":
            return LoadMessage;
        case "direct-debit:widget":
            return WidgetMessage;
        case "direct-debit:welcome":
            return WelcomeMessage;
        case "direct-debit:mandate-pending":
            return MandatePendingMessage;
        case "direct-debit:mandate-failed":
            return MandateFailedMessage;
        case "direct-debit:mandate-success":
            return MandateSuccessMessage;
        case "direct-debit:mandate-pending:long":
            return MandatePendingTooLongMessage;
    }
}
