import * as Contacts from "modules/contacts";

export const PhoneList = Contacts.withPhoneNumbers((props) => {
    return (
        <>
            {props.phones.map((phone, i) => {
                return (
                    <Contacts.PhoneLink
                        phoneNumber={phone}
                        key={phone.toString() + i}
                    />
                );
            })}
        </>
    );
});
PhoneList.displayName = "PhoneList";
