import { LocalizedLink } from "app/locale";
import { useOpenState } from "@horat1us/react-hooks";
import { Author } from "./Author";
import Logo from "../header/white-logo.svg";
import "./styles.scss";

export const Layout = () => {
    const [isActive, setActive] = useOpenState();
    const year = new Date().getFullYear().toString();
    return (
        <footer className="footer">
            <LocalizedLink to="/chat">
                <img src={Logo} width="64" height="16" alt="Logo" />
            </LocalizedLink>
            <div className="footer-above">
                <p onDoubleClick={setActive}>
                    {isActive
                        ? `Time: ${process.env.BUILD_TIME}. Build: ${process.env.TRAVIS_BUILD_NUMBER}.`
                        : `© ${year} All rights is reserved`}
                </p>
                <Author />
            </div>
        </footer>
    );
};

Layout.displayName = "Footer.Layout";
