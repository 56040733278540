import * as React from "react";
import * as Base from "../base";
import * as Chat from "modules/chat";
import * as State from "app/chat/state";

export const ReauthButtonMessage: State.Message<
    State.MessageProps<Chat.BankId.ReauthButtonMessage>
> = () => {
    const dispatch = Chat.useDispatchContext();

    const handleConfirm = React.useCallback(() => {
        dispatch([
            new Chat.FlushAction([
                new Chat.BankId.ReauthLoadMessage,
            ]),
        ])
    }, [dispatch]);

    return (
        <Base.ButtonMessage source={"user"} className={"confirm-bank"}>
            <Base.Button theme="primary" onClick={handleConfirm}>
                Confirm
            </Base.Button>
        </Base.ButtonMessage>
    );
}
