import * as Client from "modules/client";
import * as React from "react";
import {nameFilter} from "modules/utils/name-filter";
import {emailFilter} from "modules/utils/email-filter";
import {TextControl, TextControlProps} from "./text-control";
import {NumberControl, NumberControlProps} from "./number-control";
import {SelectControl, SelectControlProps} from "./select-control";
import {ButtonControl} from "./button-control";
import {JSONSchemaEnum} from "modules/client/api";
import {passportFilter} from "modules/utils/passport-filter";
import {innFilter} from "modules/utils/inn-filter";
import {dateFilter} from "modules/utils/date-filter";
import dayjs from "dayjs";

export type ControlProps<S extends Client.Api.DataAttributeSchema = Client.Api.DataAttributeSchema> = {
    id: string;
    attribute: string;
    schema: S;
    value: Client.Api.DataAttributeValue<S>;
    onChange: (newValue: Client.Api.DataAttributeValue<S>) => void;
}

export const Control: React.FC<Omit<React.HTMLProps<HTMLInputElement>, keyof ControlProps> & ControlProps> =
    (props) => {
        switch (props.schema.type) {
            case "string":
                const childProps = {...props} as TextControlProps;
                childProps.inputProps = {
                    inputMode: "text",
                };
                if (props.schema.pattern === "^[A-Za-z]+(?:-[A-Za-z]+)?$") {
                    childProps.filter = nameFilter;
                } else if ((props.attribute === "passport") || props.schema.pattern === "^[А-ЯІЇҐ]{2}\\d{6}$") {
                    childProps.filter = passportFilter;
                } else if (props.schema.format === "email") {
                    childProps.filter = emailFilter;
                    childProps.inputProps.inputMode = "email";
                } else if (props.schema.format === "date") {
                    childProps.inputProps.inputMode = "tel";
                    childProps.filter = dateFilter;
                    // fix date format after recieving value from backend
                    if (childProps.value && /^\d{4}-\d{2}-\d{2}$/.test(childProps.value)) {
                        childProps.value = dayjs(childProps.value).format('DD-MM-YYYY');
                    }
                } else if ((props.schema.format === "inn") || (props.schema.title?.toLowerCase() === 'bvn') || (props.schema.title?.toLowerCase() === 'nin')) {
                    childProps.filter = innFilter(props.schema as any);
                    childProps.inputProps.inputMode = "tel";
                } else if (props.schema.pattern === "^\\d{9}$") {
                    childProps.filter = (val: string) => val
                        .replace(/\D/, '')
                        .substr(0, 9);
                    childProps.inputProps.inputMode = "tel";
                }
                return <TextControl {...childProps} />;
            case "integer":
                return <NumberControl {...props as NumberControlProps} />
        }
        if (("oneOf" in props.schema) && Array.isArray(props.schema.oneOf)) {
            if (props.schema.oneOf.length <= 3) {
                return <ButtonControl {...props as ControlProps<JSONSchemaEnum>} />
            }
            return <SelectControl {...props as SelectControlProps} />
        }
        return <div className="unknown-control">UnknownControl</div>;
    };
