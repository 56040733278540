import React from "react";
import * as State from "../state";
import { WrapMessage } from "../base";
import { ButtonRestructuring } from "modules/components/button-restructuring";
import * as Client from "modules/client";
import * as Chat from "modules/chat";

export const OpenMessage= Client.withState<State.MessageProps<Chat.Restructuring.RestructuringOpenMessage>, {applicationId: number} >(
    ({applicationId}) => {
        const at = React.useMemo(() => new Date().toISOString(), []);
        return (
            <WrapMessage source={"bot"} at={at} className="lower">
                <ButtonRestructuring id={applicationId} />
            </WrapMessage>
        )
    },
    (state) => ("user" === state.type)
        && {
            applicationId: state.request.applicationId,
        }
);
OpenMessage.displayName = "Restructuring.OpenMessage";
