// tslint:disable:max-line-length
export const Clock = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 6.04102C28.9486 6.04102 33.4304 8.03762 36.6845 11.2697L36.728 11.3131L36.7714 11.3566C40.0035 14.6107 42.0001 19.0925 42.0001 24.0411C42.0001 33.9822 33.9412 42.0411 24 42.0411C14.0589 42.0411 6 33.9822 6 24.0411C6 14.0999 14.0589 6.04102 24 6.04102ZM39.1416 24.0506L39.1416 24.0492L39.1416 24.0412C39.1416 22.0528 38.7496 20.0838 37.9887 18.2468C37.2278 16.4097 36.1125 14.7406 34.7065 13.3346C33.3005 11.9286 31.6314 10.8133 29.7943 10.0524C27.9573 9.29147 25.9884 8.89983 24 8.89983C15.6376 8.89983 8.85847 15.6786 8.85847 24.0411C8.85847 32.4035 15.6376 39.1826 24 39.1826C32.3593 39.1826 39.1365 32.4087 39.1416 24.0506Z"
      fill="url(#paint0_linear_244_330)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 6.04102C28.9486 6.04102 33.4304 8.03762 36.6845 11.2697L36.728 11.3131L36.7714 11.3566C40.0035 14.6107 42.0001 19.0925 42.0001 24.0411C42.0001 33.9822 33.9412 42.0411 24 42.0411C14.0589 42.0411 6 33.9822 6 24.0411C6 14.0999 14.0589 6.04102 24 6.04102ZM39.1416 24.0506L39.1416 24.0492L39.1416 24.0412C39.1416 22.0528 38.7496 20.0838 37.9887 18.2468C37.2278 16.4097 36.1125 14.7406 34.7065 13.3346C33.3005 11.9286 31.6314 10.8133 29.7943 10.0524C27.9573 9.29147 25.9884 8.89983 24 8.89983C15.6376 8.89983 8.85847 15.6786 8.85847 24.0411C8.85847 32.4035 15.6376 39.1826 24 39.1826C32.3593 39.1826 39.1365 32.4087 39.1416 24.0506Z"
      fill="url(#paint1_radial_244_330)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 14.141C24.7732 14.141 25.4 14.7678 25.4 15.541V23.641H33.5C34.2732 23.641 34.9 24.2678 34.9 25.041C34.9 25.8142 34.2732 26.441 33.5 26.441H24.5C23.4507 26.441 22.6 25.5904 22.6 24.541V15.541C22.6 14.7678 23.2268 14.141 24 14.141Z"
      fill="url(#paint2_linear_244_330)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 14.141C24.7732 14.141 25.4 14.7678 25.4 15.541V23.641H33.5C34.2732 23.641 34.9 24.2678 34.9 25.041C34.9 25.8142 34.2732 26.441 33.5 26.441H24.5C23.4507 26.441 22.6 25.5904 22.6 24.541V15.541C22.6 14.7678 23.2268 14.141 24 14.141Z"
      fill="url(#paint3_radial_244_330)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_244_330"
        x1="24"
        y1="6.04102"
        x2="34.4844"
        y2="38.6725"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B2FF" />
        <stop offset="1" stopColor="#0F85FF" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_244_330"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.8446 6.04102) rotate(88.4063) scale(43.7313 16.6013)"
      >
        <stop stopColor="#72D9FF" />
        <stop offset="1" stopColor="#65C4FF" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_244_330"
        x1="24"
        y1="6.04102"
        x2="34.4844"
        y2="38.6725"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B2FF" />
        <stop offset="1" stopColor="#0F85FF" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_244_330"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.8446 6.04102) rotate(88.4063) scale(43.7313 16.6013)"
      >
        <stop stopColor="#72D9FF" />
        <stop offset="1" stopColor="#65C4FF" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
