import { useEffect } from "react";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as Sentry from "@sentry/capacitor";
import axios from "axios";
import { LoadMessage } from "./load-message";
import { useIsMounted } from "@horat1us/react-hooks";
import { useHistory } from "react-router-dom";

export interface SubmitMessageProps<V = any, D = any> {
    value?: V;
    onRequest: (api: Client.Api.Instance, value: V) => Promise<D>;
    onFinish: (response: Promise<D> | D) => Promise<Chat.Action | void> | void;
}

export const SubmitMessage = Client.withApi<SubmitMessageProps>(
    ({ api, value, onRequest, onFinish }) => {
        const dispatch = Chat.useDispatchContext();
        const history = useHistory();
        const isMounted: boolean = useIsMounted();

        useEffect(() => {
            const cancelToken = axios.CancelToken.source();

            onRequest(api.with({ cancelToken: cancelToken.token }), value)
                .then(async (response) => {
                    if (!isMounted) {
                        return;
                    }

                    const action = await onFinish(response);

                    if (action === undefined) {
                        return;
                    }

                    dispatch(action);
                }).catch(async (error): Promise<void> => {
                    /**
                     * This block of code is responsible for handling errors during the request.
                     * If the component is still mounted, it captures the error using Sentry and calls the onFinish function with null as the argument.
                     */
                    if (!isMounted) {
                        return;
                    }

                    Sentry.captureException(error);

                    history.push('/failure');
                });
        }, [api, value, onRequest, onFinish]);

        return <LoadMessage/>;
    }
);
