import * as Base from "app/chat/base";
import * as State from "app/chat/state";
import * as Chat from "modules/chat";
import * as React from "react";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";

type ShareContactsDisabledProps = State.MessageProps<Chat.ShareContacts.ShareContactsNotEnough>;

export const ShareContactsDisabled: React.FC<ShareContactsDisabledProps> = (value) => {
    const dispatch = Chat.useDispatchContext();
    const handleConfirm = React.useCallback(() => {
        NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App
        }).then(()=>{
            dispatch([
                new Chat.FlushAction([new Chat.ShareContacts.ShareContactsInitMessage]),
            ]);
        });
    }, []);

    return (
        <>
            <Base.WrapMessage source="bot">
                <p>To approve a loan, you need to provide access to contacts in the application settings on your phone</p>
            </Base.WrapMessage>
            <Base.ButtonMessage source={"user"} className={"confirm-bank"}>
                <Base.Button theme="primary" onClick={handleConfirm}>
                    Provide access
                </Base.Button>
            </Base.ButtonMessage>
        </>
    );
};
