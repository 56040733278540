import * as Client from "modules/client";
import { useDownloadState } from "./use-download-state";
import React from "react";
import axios from "axios";
import { useStateContext } from "modules/client";

export function useDocumentDownload(onRequest: (api: Client.Api.Instance) => Promise<string>): ReturnType<typeof useDownloadState> {
    const [ url, setUrl ] = useDownloadState();
    const state = useStateContext();

    React.useEffect(() => {
        if (url !== "loading") {
            return;
        }
        const cancelToken = axios.CancelToken.source();
        onRequest(state.api.with({ cancelToken: cancelToken.token }))
            .then((url) => {
                setUrl(url);
            })
            .catch(err => {
                setUrl("failed");
                return Promise.reject(err);
            });

    }, [ setUrl, url, onRequest ]);

    return [url, setUrl];
}
