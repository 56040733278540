// tslint:disable:max-line-length
export const IconMastercardColored = () => (
    <svg
        width="27"
        height="17"
        viewBox="0 0 27 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_mastercard-colored"
    >
        <path
            d="M8.32516 16.6542C12.923 16.6542 16.6503 12.9261 16.6503 8.32712C16.6503 3.72818 12.923 0 8.32516 0C3.7273 0 0 3.72818 0 8.32712C0 12.9261 3.7273 16.6542 8.32516 16.6542Z"
            fill="#EE0005"
        />
        <path
            d="M18.6749 16.6542C23.2727 16.6542 27 12.9261 27 8.32712C27 3.72818 23.2727 0 18.6749 0C14.077 0 10.3497 3.72818 10.3497 8.32712C10.3497 12.9261 14.077 16.6542 18.6749 16.6542Z"
            fill="#F9A000"
        />
        <path
            d="M10.3467 8.32744C10.3456 9.58268 10.6288 10.8219 11.175 11.9521C11.7212 13.0822 12.5162 14.0741 13.5004 14.8531C14.4837 14.0732 15.278 13.0812 15.8241 11.9512C16.3701 10.8212 16.6537 9.58245 16.6537 8.32744C16.6537 7.07243 16.3701 5.83364 15.8241 4.70364C15.278 3.57364 14.4837 2.58164 13.5004 1.80176C12.5162 2.58081 11.7212 3.57264 11.175 4.70282C10.6288 5.833 10.3456 7.07219 10.3467 8.32744Z"
            fill="#FF6300"
        />
    </svg>
);
