import { useEffect } from "react";
import { LocalizedLink } from "app/locale";
import * as Client from "modules/client";
import * as HappyFace from "modules/components/bot/happy";
import { IconMonoFull } from "modules/components/Icons/IconMonoFull";

export const Layout = () => {
    const dispatch = Client.useDispatchContext();
    useEffect(() => {
        return () => {
            dispatch(new Client.Auth.RefreshAction());
        };
    }, []);

    return (
        <>
            <h1>Great!</h1>
            <p className="under-title">
                Your phone number has been succecfully approved
            </p>
            <div className="animation-container">
                <HappyFace.Layout />
            </div>
            <div className="btn-container">
                <p>
                    To continue registration you need to confirm your data with
                    mono
                </p>
                <LocalizedLink to={"/chat"} className="btn btn_blue">
                    <span>Confirm with</span>
                    <IconMonoFull />
                </LocalizedLink>
            </div>
        </>
    );
};

export default Layout;
