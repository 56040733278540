import * as React from "react";
import * as Base from "../base";
import * as Chat from "modules/chat";
import * as State from "app/chat/state";

export const FailureMessage: State.Message<
    State.MessageProps<Chat.Card.InitMessage>
> = () => {
    return (
        <Base.WrapMessage source="bot">
            <p>Apologies for the inconvenience. There was an error while linking your card. Please check your
                information
                and try again</p>
            <p>If the problem persists, consider using a different card or contacting your bank for assistance. Thank
                you
                for your understanding</p>
        </Base.WrapMessage>
    );
};
