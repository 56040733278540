import * as React from "react";
import * as Main from "modules/components/main";
import * as Header from "modules/components/header";
import { Logo } from "modules/components/header/Logo";
import * as Footer from "modules/components/footer";
import { useHistory } from "react-router-dom";
import { LocalizedLink } from "app/locale";
import { IconHamburger } from "modules/components/Icons/IconHamburger";
import "./styles.scss";

type LayoutContainerProps = {
    isInternal?: boolean;
    children: React.ReactNode;
};

export const LayoutContainer: React.FC<LayoutContainerProps> = ({
    isInternal,
    children,
}) => {
    const history = useHistory();
    const handleClick = () => {
        history.push("/nav");
    };
    return (
        <>
            <Header.Layout logo={<Logo type={"color"} />}>
                {isInternal ? (
                    <i className="icon icon_arrow-back" onClick={handleClick} />
                ) : (
                    <LocalizedLink to={"/nav"} className={"menu-hamburger"}>
                        <IconHamburger />
                    </LocalizedLink>
                )}
            </Header.Layout>
            <Main.Layout>{children}</Main.Layout>
        </>
    );
};
LayoutContainer.displayName = "LayoutContainer";

export default LayoutContainer;
