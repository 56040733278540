import * as React from "react";
import * as State from "../state";
import * as Base from "app/chat/base";
import * as Chat from "modules/chat";


type SuccessMessageProps = State.MessageProps<Chat.SmileId.SuccessMessage>;

export const SuccessMessage: React.FC<SuccessMessageProps> = () => {
    // const dispatch = Chat.useDispatchContext();

    const handleClick = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        location.reload(); // WTF bug Refresh is not working
        // dispatch([new Chat.PushAction([new Chat.RefreshClientStateMessage()])]);
    }, []);

    return (
        <>
            <Base.WrapMessage source="bot">
                <p>
                    The identification process has been successfully completed.
                </p>
            </Base.WrapMessage>
            <Base.ButtonMessage source={"user"}>
                <Base.Button theme="primary" onClick={handleClick}>
                    Continue
                </Base.Button>
            </Base.ButtonMessage>
        </>
    );
}
