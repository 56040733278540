import { IconArrow } from "modules/components/Icons/IconArrow";
import * as ErrorFace from "modules/components/bot/error";
import * as React from "react";
import {Browser} from "@capacitor/browser";

export const Layout = () => {
    const onClick = React.useCallback(async() => {
        await Browser.open({url: 'https://static.kobogo.ng/app/upgrade.html'});
    }, []);

    return (
        <>
            <h1>Upgrade Required</h1>
            <p className="under-title">
                To continue using this app, an important update is necessary.
            </p>
            <div className="animation-container">
                <ErrorFace.Layout />
            </div>
            <div className="btn-container">
                <p>
                    We apologize for the inconvenience caused. The upgrade will
                    enhance your app experience and ensure compatibility with
                    new features and improvements.
                </p>
                <button onClick={onClick} className="btn btn_white">
                    <span>Upgrade</span>
                    <IconArrow />
                </button>
            </div>
        </>
    );
};

export default Layout;
