import {useCallback} from "react";
import {useLocale} from "app/locale";
import * as Base from "../base";
import * as Client from "modules/client";
import * as React from "react";

export const ExitMessage = () => {
    const dispatch = Client.useDispatchContext();
    const {t} = useLocale("registration");

    const handleClick = useCallback(() => {
        dispatch(new Client.Auth.RefreshAction())
    }, []);

    return (
        <Base.ButtonMessage source={"user"}>
            <Base.Button theme={"secondary"} onClick={handleClick}>
                {t("goodbye")}
            </Base.Button>
        </Base.ButtonMessage>
    )
};
