import "./styles.scss";
import {FeedbackModal} from "../../feedback-modal";
import * as React from "react";
import {useHistory} from "react-router-dom";
import {Capacitor} from "@capacitor/core";
import * as Client from "../../../modules/client";

export const Layout = () => {
    const history = useHistory();
    const dispatch = Client.useDispatchContext();

    React.useEffect(() => {
        dispatch(new Client.Auth.RefreshAction());
    }, []);

    const onCloseModal = React.useCallback(() => {
        // because we have chrome://newtab/ at first in web
        if (Capacitor.isNativePlatform() ? history.length > 1 : history.length > 2) {
            history.goBack();
        } else {
            history.replace('/');
        }
    }, []);

    return (
        <FeedbackModal onClose={onCloseModal}></FeedbackModal>
    );
};

export default Layout;
