import * as React from "react";
import * as Info from "../Layout";

const Article = React.lazy(
    () => import(/* webpackChunkName: "logout" */ "./Layout")
);
export const Page: React.FC = () => {
    return (
        <Info.Layout>
            <Article />
        </Info.Layout>
    );
};
