import { WrapMessage } from "../base";
import * as State from "../state";
import * as Chat from "modules/chat";
import { Button } from "../button";
import { PhoneList } from "../base/phone-list";

export const ResendMessage: State.Message<
    State.MessageProps<Chat.SignUp.ResendMessage>
> = ({ value: { phone, time } }) => {
    return (
        <WrapMessage source="bot" className={"resend"} at={time}>
            <p>
                Didn't receive the message? Send SMS again or contact us.
                <PhoneList />
            </p>
            <Button
                id="resend-sms"
                className="btn btn_white"
                messages={[new Chat.SignUp.ResendOptionsLoadMessage(phone)]}
                text="Send again"
            />
        </WrapMessage>
    );
};
