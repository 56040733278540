import { keyboardLayoutFormat } from "modules/utils/keyboard-layout-format";
import toLower from "lodash/toLower";

export const emailFilter = (value: string): string => {
    return value
        .split("@", 2)
        .map(toLower)
        .map((p) => p
            .split(".")
            .map((sp) => keyboardLayoutFormat(sp, "en", /^\d+$/))
            .join(".")
        )
        .join("@");
};
