import * as React from "react";
import * as State from "app/chat/state";
import * as Chat from "modules/chat";
import * as Base from "../base";
import * as Resources from "app/resources";
import {useHistory} from "react-router-dom";

/**
 * @link https://docs.smileidentity.com/web-mobile-web/web-integration/usage
 */
export const WidgetMessage: State.Message<
    State.MessageProps<Chat.SmileId.WidgetMessage>
> = ({value: {config}}) => {
    const dispatch = Chat.useDispatchContext();
    const history = useHistory();

    React.useEffect(() => {
        const widgetConfig = {
            ...config,
            partner_details: {
                name: "Kobogo",
                logo_url: Resources.List.logo512,
                policy_url: Resources.List.privacyPolicy,
                theme_color: '#000',
                ...config.partner_details,
            },
        };
        console.log("SmileID configuration: ", widgetConfig);
        (window as any).SmileIdentity({
            ...widgetConfig,
            onSuccess: (...args: any) => {
                console.log(args);
                // TODO: implement waiting message
                dispatch([
                    new Chat.ReplaceAction([new Chat.SmileId.SuccessMessage()])
                ]);
            },
            onClose: (...args: any) => {
                console.log(args);
                dispatch([
                    new Chat.FlushAction([
                        new Chat.SmileId.InitMessage(),
                    ]),
                ]);
            },
            onError: (error: any) => {
                console.error(error);
                if (error === "SmileIdentity::ConsentDenied") {
                    dispatch([
                        new Chat.FlushAction([
                            new Chat.SmileId.InfoMessage(),
                            new Chat.SmileId.ButtonMessage(),
                        ]),
                    ]);
                    return;
                }
                // TODO: Change to ErrorMessage
                history.push("/failure");
            }
        });
    }, [config]);

    return <Base.LoadMessage/>;
}
