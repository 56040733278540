export type ResourceKeys = string

export enum RouteLanguage {
    ru = "ru",
    ua = "ua",
}

export enum LocaleLanguage{
    ru = "ru",
    uk = "uk",
}

export interface LocaleMatch {
    locale: RouteLanguage | undefined
}
