import * as Chat from "modules/chat";
import * as State from "../state";
import { InfoMessage } from "./info-message";
import { SelectOperatorMessage } from "./select-operator-message";
import { ConfirmFormMessage } from "./confirm-form-message";
import { ConfirmLoadMessage } from "./confirm-load-message";

export function MessageComponentFactory(
    type: Chat.PhoneId.Message["type"]
): State.Message | undefined {
    switch (type) {
        case "phone-id:info":
            return InfoMessage;
        case "phone-id:select-operator":
            return SelectOperatorMessage;
        case "phone-id:confirm-form":
            return ConfirmFormMessage;
        case "phone-id:confirm-load":
            return ConfirmLoadMessage;
    }
}
