import { RestructuringScheduleItem } from "../client/api/credit";

export const activeRestructuringPayment = (
    schedule: RestructuringScheduleItem[]
): RestructuringScheduleItem => {
    for (let i = 0; i < schedule.length; i++) {
        if (schedule[i].debt > 0.01) {
            return schedule[i];
        }
    }
    return schedule[schedule.length - 1];
};
