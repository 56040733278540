import { createContext } from "react";

export interface ContextValue {
    active: string | undefined;
    setActive: (id: string | undefined) => void;
}

export const ContextDefaultValue: ContextValue = {
    active: undefined,
    setActive: () => undefined,
};

export const Context = createContext<ContextValue>(ContextDefaultValue);
