import * as React from "react";
import classNames from "classnames";
import { Translate, useLocale } from "app/locale";
import { LoadingSpinner } from "../loading-spinner";
import { IconDownload } from "../Icons/IconDownload";

function getButtonName(url: string | undefined): string | undefined {
    switch (url) {
        case "loading":
            return "Loading...";
        case "failed":
            return "Loading error";
    }
}

export const ButtonDownload: React.FC<{
    url: string | undefined;
    setUrl: (arg: "loading") => void;
    id?: string;
    className?: string;
    name: string;
}> = ({ url, setUrl, id, className, name }) => {
    const handleClick = React.useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();

            setUrl("loading");
        },
        [setUrl]
    );

    return (
        <button
            id={id}
            className={classNames(`btn btn_pdf ${className}`, url && "loading")}
            onClick={handleClick}
            disabled={!!url}
        >
            <span>{getButtonName(url) ?? name}</span>
            <span className={classNames("icon-overlay", url && "download")}>
                {url ? <LoadingSpinner /> : <IconDownload />}
            </span>
        </button>
    );
};
ButtonDownload.displayName = "ButtonDownload";
