import * as State from "../state";
import * as Chat from "../../../modules/chat";
import {Control, ControlMessage, LabelMessage} from "../base/form";
import classNames from "classnames";
import * as React from "react";

const labelMessage = "Please enter your bank account number (NUBAN)";
export const SelectNubanMessage: State.Message<State.MessageProps<Chat.BankAccount.SelectNubanMessage>> =
    (props) => {
        const dispatch = Chat.useDispatchContext();
        const [value, setValue] = React.useState<string>(props.value.accountNumber);
        const isButtonEnabled = value.length === 10;
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            const newValue = e.target.value
                .replace(/\D/, '')
                .substring(0, 10);
            setValue(newValue);
        };
        const handleSubmit = (e: React.FormEvent | Event) => {
            e.preventDefault();
            if (!isButtonEnabled) {
                return;
            }
            dispatch(new Chat.ReplaceAction([
                new Chat.TextMessage(labelMessage, "bot"),
                new Chat.EditableMessage(value, new Chat.ReplaceAction([
                    new Chat.BankAccount.SelectNubanMessage(props.value.accountNumber, props.value.bankCode),
                ])),
                new Chat.BankAccount.SelectNubanLoadMessage(props.value.bankCode, value),
            ], props.value));
        };

        return (
            <form onSubmit={handleSubmit}>
                <LabelMessage id={"nuban-input"} message={"Please enter your bank account number (NUBAN)"}/>
                <ControlMessage>
                    <input
                        id={"nuban-input"}
                        className="form__control"
                        type={"tel"}
                        name={"nuban-input"}
                        placeholder={"0000000000"}
                        title={"Bank Account NUBAN"}
                        value={value}
                        onChange={handleChange}
                        autoCorrect="on"
                        autoFocus
                        required
                    />
                    <div className="btn-container v-align">
                        <button
                            id={"nuban-input-submit"}
                            className={classNames(
                                "btn btn_action",
                                isButtonEnabled ? "enabled" : "disabled"
                            )}
                            type="submit"
                        >
                            <i className="icon icon_arrow"/>
                        </button>
                    </div>
                </ControlMessage>
            </form>
        );
    };
