import { AxiosInstance, AxiosResponse } from "axios";

export type AuthResponse = { id: number, access: string, refresh: string };

export type RequestApiResponse = {
    expireTimeSeconds: number;
}
export type RequestResponse = {
    isSuccess: boolean;
} & RequestApiResponse;

export type RequestChannel = {
    name: string;
    title: string;
}
export type ChannelsResponse = {
    channels: Array<RequestChannel>,
}

export const Api = (endpoint: AxiosInstance) => {
    return {
        request: async (phone: string, channel?: string): Promise<RequestResponse> => {
            const Request: {
                phone: string,
                channel?: string,
            } = {
                phone,
                channel,
            };
            const response = await endpoint.post<RequestApiResponse, AxiosResponse<RequestApiResponse>>(
                "/v2/sign/request",
                {Request},
                {
                    validateStatus: (status: number) => [403, 423, 200].includes(status),
                }
            );
            return {
                isSuccess: response.status !== 403,
                expireTimeSeconds: response.data.expireTimeSeconds,
            };
        },
        confirm: async (phone: string, token: string): Promise<AuthResponse> => {
            const response = await endpoint.post("/v2/sign/confirm", {Confirm: {phone, token,}}, {
                validateStatus: (status: number) => [200, 201,].includes(status),
            });
            return response.data;
        },
        refresh: async (refreshToken: string): Promise<AuthResponse | false> => {
            const response: AxiosResponse<AuthResponse> = await endpoint.put("/v3/auth", {}, {
                params: {
                    refresh: refreshToken,
                },
                validateStatus: (status) => [201, 400].includes(status),
            });
            if (response.status === 400) {
                return false;
            }
            return response.data;
        },
        channels: async (): Promise<Array<RequestChannel>> => {
            const response: AxiosResponse<ChannelsResponse> = await endpoint.get("/v2/sign/channels");
            return response.data.channels;
        }
    } as const;
}
