import * as React from "react";
import * as State from "../state";
import * as Chat from "modules/chat";
import { OpenInCapacitorBrowser } from "../../../modules/components/open-in-capacitor-browser";
import { LocalizedRedirect } from "../../locale";
import { DeepLinkCloseBrowserSmileId } from "../../app-url-listener/AppUrlListener";

type OpenBrowserMessageProps =
    State.MessageProps<Chat.SmileId.OpenBrowserMessage>;

export const OpenBrowserMessage: React.FC<OpenBrowserMessageProps> = ({value: {url, jobId}}) => {
    const dispatch = Chat.useDispatchContext();

    if (!url || !jobId) {
        return <LocalizedRedirect to="/dashboard" />;
    }

    const onAppUrlOpen = (url: string)=> {
        const _url = url.slice(DeepLinkCloseBrowserSmileId.length);

        switch (_url) {
            case "success":
                dispatch(new Chat.FlushAction([
                    new Chat.SmileId.WaitingMessage(jobId),
                ]));
                break;
            case "error":
                dispatch(new Chat.FlushAction([
                    new Chat.SmileId.ErrorMessage,
                ]))
                break;
            default:
                dispatch(new Chat.FlushAction([
                    new Chat.SmileId.InitMessage,
                ]));
        }
    };

    const onBrowserFinished = ()=>{
        dispatch(new Chat.FlushAction([
            new Chat.SmileId.InitMessage,
        ]));
    };

    return (
        <OpenInCapacitorBrowser url={url} onBrowserFinished={onBrowserFinished} onAppUrlOpen={onAppUrlOpen}/>
    );
}
