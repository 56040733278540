import * as React from "react";
import * as Base from "../base";
import * as Chat from "modules/chat";
import * as State from "app/chat/state";
import {OpenInCapacitorBrowser} from "../../../modules/components/open-in-capacitor-browser";
import {Capacitor} from "@capacitor/core";

export const RedirectMessage: State.Message<
    State.MessageProps<Chat.Card.RedirectMessage>
> = ({value}) => {
    const dispatch = Chat.useDispatchContext();

    const onBrowserFinished = (): void =>{
        dispatch(new Chat.FlushAction([
            new Chat.Card.InitMessage, new Chat.Card.ConfirmMessage
        ]));
    };

    const onAppUrlOpen = (url: string)=> {
        const successUrl = 'kobogo://closeBrowser/chat/card-success';

        if (url.includes(successUrl)) {
            dispatch(new Chat.FlushAction([
                new Chat.Card.LoadCompleteMessage
            ]));
        }
    };

    React.useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            return;
        }

        const timeoutId = setTimeout(() => {
            window.location.href = value.action;
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, [value]);

    if(Capacitor.isNativePlatform()) {
        return <OpenInCapacitorBrowser url={value.action} onBrowserFinished={onBrowserFinished} onAppUrlOpen={onAppUrlOpen}/>
    } else {
        return <Base.LoadMessage/>
    }
}
