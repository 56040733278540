import { useEffect } from "react";
import { LocalizedLink } from "app/locale";
import { useHistory } from "react-router-dom";
import * as Client from "modules/client";
import WinkingBot from "./winking-bot.svg";
import { IconCardCheck } from "modules/components/Icons/IconCardCheck";
import "./styles.scss";

export const Layout = () => {
    const state = Client.useStateContext();
    const history = useHistory();
    const isUser = state.type !== "guest";

    useEffect(() => {
        if (isUser) {
            history.push("/chat");
        }
    }, [isUser]);

    return (
        <>
            <h1>Online credit</h1>
            <p className="under-title">
                Get up to ₦ 100,000 loan in just three steps
            </p>
            <img
                src={WinkingBot}
                width={328}
                height="328"
                alt="Bot"
                className="bot"
            />
            <div className="btn-container">
                <p>
                    Fill out an application, add your account number and get
                    money!
                </p>
                <LocalizedLink to={"/chat"} className="btn btn_white">
                    <IconCardCheck />
                    <span>Get started!</span>
                </LocalizedLink>
            </div>
        </>
    );
};

export default Layout;
