import * as Chat from "modules/chat";
import * as State from "../state";
import { FormGroup } from "./form-group";
import { useCalc } from "modules/hooks/use-calc";
import { useEffect } from "react";
import * as Promocode from "modules/components/promocode";
import { useStateContext } from "modules/client";
import { CalculatorStorage } from "./storage";
import { Capacitor } from "@capacitor/core";

export const CalculatorOnlyMessage: State.Message<
    State.MessageProps<Chat.Calculator.CalculatorOnlyMessage>
> = (props) => {
    const conditions = useCalc(props.value);
    const client = useStateContext();
    const [state, dispatch] = Promocode.useState("");
    const promocode = client.type === "user" && client.request.promocode;

    useEffect(() => {
        state.isValid &&
            CalculatorStorage.setPromoCode(state.value, state.discount);
    }, [state.isValid]);

    useEffect(() => {
        if (promocode) {
            dispatch(new Promocode.ChangeAction(promocode.value));
        }
        return () => CalculatorStorage.removePromoCode();
    }, []);

    return (
        <div
            className={
                Capacitor.isNativePlatform() ? "calc calc_capacitor" : "calc"
            }
        >
            <div className="agreement-term">
                <span>Term of the agreement</span>
                <ul>
                    <li>90</li>
                    <li className="locked">120</li>
                    <li className="locked">180</li>
                </ul>
            </div>
            <FormGroup
                message={props.value}
                notice="max amount of the first loan"
                label="Loan amount"
                field="amount"
                messageRender={(conditions) =>
                    new Chat.Calculator.CalculatorOnlyMessage(conditions)
                }
            />
            <FormGroup
                message={props.value}
                notice={`until ${conditions.returnDate.format("DD.MM.YYYY")} ${
                    conditions.days[conditions.returnDate.day()]
                }`}
                label="Exemption period, days"
                field="term"
                messageRender={(conditions) =>
                    new Chat.Calculator.CalculatorOnlyMessage(conditions)
                }
            />
            <p className="calc-total">
                <span className="calc-total__label">Total payment:</span>
                <span className="calc-total__value with-currency">
                    {conditions.returnAmount}
                </span>
            </p>
            <Promocode.Layout
                message={props.value}
                state={state}
                dispatch={dispatch}
            />
        </div>
    );
};
CalculatorOnlyMessage.displayName = "Calculator.CalculatorOnlyMessage";
