import * as Chat from "modules/chat";
import * as Client from "modules/client";
import { useCallback } from "react";
import * as Base from "../base";
import * as State from "../state";

export const RefuseSendMessage: State.Message<
    State.MessageProps<Chat.Agreement.AgreementRefuseSendMessage>
> = ({ value }) => {
    const handleRequest = useCallback((api: Client.Api.Instance) => {
        return api.user.agreement.refuse();
    }, []);

    const handleResponse = useCallback(async () => {
        return new Chat.ReplaceAction(
            [
                new Chat.TextMessage("Changed my mind", "user"),
                new Chat.TextMessage(
                    [
                        "See you then!",
                        "Subscribe to our groups in social networks and receive discounts and prizes.",
                    ],
                    "bot"
                ),
                new Chat.Agreement.AgreementExitMessage(),
            ],
            value
        );
    }, [value]);

    return (
        <Base.DelaySubmitMessage
            value={undefined}
            onRequest={handleRequest}
            onFinish={handleResponse}
        />
    );
};
