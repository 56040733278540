import { useCallback } from "react";
import * as Client from "modules/client";
import * as Chat from "modules/chat";
import * as Base from "../base";
import * as State from "../state";

export const EmailLoadMessage: State.Message<State.MessageProps<Chat.Agreement.AgreementEmailLoadMessage>> =
    (props) => {
        const handleRequest = useCallback((api: Client.Api.Instance) => {
            return api.user.data.attribute("email");
        }, []);
        const handleResponse = useCallback(async (schema) => {
            return new Chat.ReplaceAction([
                new Chat.Agreement.AgreementConfirmEmailFormMessage(schema),
            ], props.value);
        }, [props.value]);
        return <Base.DelaySubmitMessage value={undefined} onRequest={handleRequest} onFinish={handleResponse}/>;
    };
