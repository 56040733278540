import * as React from "react";
import * as Internal from "../Layout";

const Article = React.lazy(
    () => import(/* webpackChunkName: "docs" */ "./Layout")
);
export const Page: React.FC = () => {
    return (
        <Internal.Layout>
            <Article />
        </Internal.Layout>
    );
};
