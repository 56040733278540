import * as Client from "modules/client";
import { prepareTerm } from "modules/utils/prepare-term";
import * as React from "react";
import { plural } from "modules/utils/pluralNumber";
import * as Contacts from "modules/contacts";
import classNames from "classnames";
import { useOpenState } from "@horat1us/react-hooks";

const PhoneList = Contacts.withPhoneNumbers((props) => {
    return (
        <>
            {props.phones.map((phone) => {
                return <Contacts.PhoneLink phoneNumber={phone} key={phone} />;
            })}
        </>
    );
});

export const DebtInfo: React.FC<{}> = React.memo(() => {
    const [isOpen, handleOpenChange] = useOpenState();
    const conditions = usePreparedConditions();
    const example = useExample(conditions);
    const maxInterestRate = (
        conditions.interestRate * 100 +
        example.fine
    ).toFixed(2);

    return (
        <>
            <div className="calc-info">
                <p>
                    Interest rate from {conditions.interestRate}% to{" "}
                    {maxInterestRate}% per day
                </p>
                <p>
                    Actual annual interest rate, percentage per annum from 36.5%
                    to 1095%
                </p>
                <p>
                    Maximum loan amount from {conditions.amount.min} naira to{" "}
                    {conditions.amount.max} naira
                </p>
            </div>
            <div className="calc-details">
                <p
                    className={classNames(
                        "calc-details__heading",
                        isOpen && "is-active"
                    )}
                    onClick={handleOpenChange}
                >
                    <span>
                        {isOpen
                            ? "Hide APR Information"
                            : "Read more APR Information"}
                    </span>
                    <i className="icon icon_arrow-circle" />
                </p>
                {isOpen && (
                    <div className="calc-details__body">
                        <p>Borrower's age from 20 to 60 years</p>
                        <p>
                            Amount of the first loan from{" "}
                            {conditions.amount.min} naira to{" "}
                            {conditions.amount.max} naira
                        </p>
                        <p>
                            Loan term from {conditions.term.min} to{" "}
                            {plural(example.maxTerm, "days")}
                        </p>
                        <p>Application consideration up to 5 minutes</p>
                        <p>Requirements BVN, Nigerian citizenship</p>
                        <h5>How to use the loan. Order of repayment.</h5>
                        <p>
                            The loan agreement term is{" "}
                            {plural(example.maxTerm, "days")}. During this term,
                            the maximum interest rate on the loan is{" "}
                            {maxInterestRate}% per day.
                        </p>
                        <p>
                            In the calculator of the personal account, the
                            borrower chooses a Grace Period. The Grace Period
                            can last from {conditions.term.min} to{" "}
                            {plural(conditions.term.max, "days")} from the date
                            of loan issuance, at the borrower's choice.
                        </p>
                        <p>
                            During the Grace Period, the loan rate will be from{" "}
                            {example.minInterestRate}% per day to a maximum of{" "}
                            {example.maxInterestRate}% per day (may be less for
                            repeat borrowers or in case of a change in the
                            borrower's risk level, or when using a promo code).
                        </p>
                        <p className="indented">
                            After the end of the Grace Period, the borrower can
                            either pay off the loan in full or, in the absence
                            of funds, extend its term, paying the interest
                            accrued to date.
                        </p>
                        <p>
                            In this case, the Grace Period will be extended for
                            the term chosen by the borrower in the personal
                            account. The borrower can extend the Grace Period an
                            unlimited number of times.
                        </p>
                        <p className="indented">
                            Thus, each time when the Grace Period ends, the
                            client pays the accrued Grace Period interest and
                            uses the loan funds at the preferential rate.
                        </p>
                        <p className="indented">
                            If the borrower paid the interest for the use of the
                            Loan e within the term of the previous Grace Period,
                            the client in the personal account chooses the next
                            Grace Period, in the meaning from{" "}
                            {conditions.term.min} to{" "}
                            {plural(conditions.term.max, "days")}.
                        </p>
                        <p>
                            If even after this term the payment is not received,
                            the Grace Period will end and every subsequent day,
                            until the end of the loan term, interest will be
                            calculated at the Standard rate of {maxInterestRate}
                            % per day.
                        </p>
                        <p className="indented">
                            The company will definitely remind the borrower that
                            the term of the Grace Period is coming to an end.
                        </p>
                        <p>
                            After the end of the loan term, as well as after the
                            end of quarantine restrictions, penalties may be
                            charged according to the terms of the contract.
                        </p>
                        <h5 className="indented">Loan calculation example:</h5>
                        <p className="indented">
                            Commissions, additional and accompanying services
                            and loan payments - none
                        </p>
                        <h6>
                            Situation #1 (payment made during the Grace Period
                            up to {plural(conditions.term.max, "days")})
                        </h6>
                        <p>Loan received on August 1st.</p>
                        <p>
                            A {conditions.term.max}-day Grace Period has been
                            chosen in the loan calculator.
                        </p>
                        <p>Loan amount - {example.credit} naira.</p>
                        <p>
                            Interest rate during the Grace Period –{" "}
                            {example.maxInterestRate}%.
                        </p>
                        <p>
                            The loan is fully repaid after 10 days, that is, on
                            August 11th.
                        </p>
                        <p className="indented">
                            Total amount to be paid {example.credit} naira loan
                            + ({example.credit} * {example.maxInterestRate}/100
                            * 10) = {example.credit + example.calculation(10)}{" "}
                            naira.
                        </p>
                        <p className="indented">Where:</p>
                        <ul>
                            <li>
                                {example.calculation(10)} naira accrued interest
                                for 10 days;
                            </li>
                            <li>{example.credit} loan amount;</li>
                            <li>
                                The total cost of the loan is{" "}
                                {example.credit + example.calculation(10)}{" "}
                                naira.
                            </li>
                        </ul>
                        <h6>
                            Situation #2 (choosing a new Grace Period, extended
                            term)
                        </h6>
                        <p>The loan was obtained on August 1.</p>
                        <p>
                            A Grace Period of{" "}
                            {plural(conditions.term.max, "days")} was chosen in
                            the loan calculator.
                        </p>
                        <p>Loan amount - {example.credit} naira.</p>
                        <p>
                            Interest rate during the Grace Period –{" "}
                            {example.maxInterestRate}%.
                        </p>
                        <p>
                            On August 31, the borrower didn't have the full
                            amount to close the loan, so he only paid the
                            interest accrued to date, {example.credit} *{" "}
                            {example.maxInterestRate}/100 * 30 days ={" "}
                            {example.calculation(30)} naira.
                        </p>
                        <p className="indented">
                            A new Grace Period was chosen (extended) for 15
                            days, that is, until September 15.
                        </p>
                        <p>
                            On September 15, the loan was fully repaid. The
                            borrower paid {example.calculation(15)} naira of
                            interest of the new Grace Period and{" "}
                            {example.credit} naira of the loan principal. A
                            total of {example.credit + example.calculation(15)}{" "}
                            naira was paid.
                        </p>
                        <h6>
                            Situation #3 (interest for the Grace Period of the
                            loan, the loan principal is not paid within{" "}
                            {plural(conditions.term.max, "days")}, and the loan
                            is repaid on the 65th day)
                        </h6>
                        <p>The loan was obtained on August 1.</p>
                        <p>
                            A Grace Period of{" "}
                            {plural(conditions.term.max, "days")} was chosen in
                            the loan calculator.
                        </p>
                        <p>Loan amount - {example.credit} naira.</p>
                        <p>
                            Interest rate during the Grace Period –{" "}
                            {example.maxInterestRate}%.
                        </p>
                        <p>The standard rate is {maxInterestRate}%.</p>
                        <p>
                            On August 31 (end of the Grace Period) the borrower
                            didn't make any payments.
                        </p>
                        <p>The repayment took place on September 20.</p>
                        <p className="indented">
                            The total amount to be repaid is {example.credit}{" "}
                            naira of the loan + ({example.credit} *{" "}
                            {example.maxInterestRate}/100 * 30) + (
                            {example.credit} * {maxInterestRate}/100 * 35) ={" "}
                            {example.totalSum} naira.
                        </p>
                        <p className="indented">Where:</p>
                        <ul>
                            <li>
                                {example.calculation(30)} naira is the accrued
                                interest of the Grace Period for{" "}
                                {plural(conditions.term.max, "days")};
                            </li>
                            <li>
                                {example.calculation(35, true)} naira is the
                                accrued interest of the Standard Period for{" "}
                                {plural(35, "days")};
                            </li>
                            <li>{example.credit} naira is the loan amount.</li>
                            <li>
                                The total loan cost will be {example.totalSum}{" "}
                                naira.
                            </li>
                        </ul>
                        <h5>State registration of a financial institution</h5>
                        <p>
                            KOBOGO NIGERIA LIMITED with its registered office at
                            48 Awolowo Road, Ikoyi, Lagos State, Federal
                            Republic of Nigeria, registered under registration
                            number 2020625.
                        </p>
                        <h6>
                            Mechanism for consumer rights protection and dispute
                            resolution
                        </h6>
                        <p>
                            If you believe that your rights as a consumer of a
                            financial service have been violated, or in case of
                            any other disputes related to the KoboGo service, we
                            urge you to contact us in one of the following ways:
                        </p>
                        <ul className="indented">
                            <li>
                                Write a letter to the mailbox{" "}
                                <strong>
                                    <a href="mailto:info@kobogo.ng">
                                        info@kobogo.ng
                                    </a>
                                </strong>
                                . Indicate "Complaint" in the subject of the
                                letter. Complaints received by email are
                                processed within 7 working days.
                            </li>
                            <li>
                                If it is impossible to resolve the issue through
                                negotiations with the company, you can contact
                                the government bodies responsible for the
                                protection of the rights of financial service
                                consumers.
                            </li>
                            <li>
                                If it is impossible to resolve the problem out
                                of court, you have the right to go to court.
                            </li>
                        </ul>
                        <h5>Potential fraud</h5>
                        <p>
                            The KoboGo service cares about the preservation of
                            its customers' confidential information. That's why
                            the service has a high degree of personal data
                            protection. Please note, the following actions may
                            pose a threat not only to information but also to
                            financial security:
                        </p>
                        <ul className="indented">
                            <li>
                                transfer of your phone or SIM card to third
                                parties;
                            </li>
                            <li>
                                transfer of your personal account login and
                                password to third parties;
                            </li>
                            <li>
                                transfer of your passport, ID-card, or Taxpayer
                                Identification Number or their copies to third
                                parties;
                            </li>
                            <li>
                                transfer of your bank card number and CVV code
                                to third parties.
                            </li>
                        </ul>
                        <p className="indented">
                            If you discover that someone has used your personal
                            data to get a loan at KoboGo, we recommend that you
                            immediately report it to:{" "}
                            <strong>
                                <a href="mailto:info@kobogo.ng">
                                    info@kobogo.ng
                                </a>
                            </strong>
                            .
                        </p>
                    </div>
                )}
            </div>
        </>
    );
});

const defaultConditions: Client.Api.Conditions = {
    amount: { min: 500, max: 5000, limit: 3000 },
    term: { min: 1, max: 30 },
    interestRate: 0.0198,
};

const usePreparedConditions = (): Client.Api.Conditions => {
    const conditions = Client.Api.useConditions() ?? defaultConditions;

    return React.useMemo(
        () => ({
            ...conditions,
            term: prepareTerm(conditions.term, conditions?.isBerserk),
        }),
        [conditions]
    );
};

const useExample = (conditions: Client.Api.Conditions) => {
    return React.useMemo(() => {
        const credit = 20000;
        const term = Math.max(30, conditions.term.min);
        const maxTerm = Math.max(90, conditions.term.min);

        const yearInterestRate = 28980.9;
        const minInterestRate = 0.01;
        const maxInterestRate = Number(
            (conditions.interestRate * 100).toFixed(2)
        );
        const fine = 2;
        const calculation = (term: number, withFine?: boolean): number =>
            Number(
                (
                    ((credit *
                        (withFine ? maxInterestRate + fine : maxInterestRate)) /
                        100) *
                    term
                ).toFixed(2)
            );
        const totalSum = credit + calculation(30) + calculation(35, true);
        return {
            minInterestRate,
            maxInterestRate,
            minYearInterestRate: 3.71,
            fine,
            yearInterestRate,
            credit: credit,
            term: term,
            maxTerm,
            totalSum,
            calculation,
            debt: {
                first: credit * conditions.interestRate,
                last: credit * term * conditions.interestRate,
            } as const,
        };
    }, []);
};
