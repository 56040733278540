import {LocationDescriptorObject} from "history";
import * as React from "react";
import {useMemo} from "react";
import {Link as BaseLink, LinkProps, NavLink as BaseNavLink, NavLinkProps, useLocation} from "react-router-dom";
import {useLocale} from "../hooks";

type BaseStateObject = { [key: string]: any }
type BaseState = BaseStateObject | string | undefined
type ExcludeProps = {
    exclude?: (string | RegExp)[]
}

export type StateWithFrom = BaseStateObject & { from: LocationDescriptorObject<BaseStateObject> } | undefined

export type LocalizedLinkProps = LocalizedBaseLinkProps & LinkProps

export const LocalizedLink: React.FC<LocalizedLinkProps> = ({children, exclude, ...props}) => {
    return <LinkFactory exclude={exclude} component={
        <BaseLink {...props}>{children}</BaseLink>
    }/>;
};

export type LocaleNavLinkProps = LocalizedBaseLinkProps & NavLinkProps

export const LocalizedNavLink: React.FC<LocaleNavLinkProps> = ({children, exclude, ...props}) => {
    return <LinkFactory exclude={exclude} component={
        <BaseNavLink {...props}>{children}</BaseNavLink>
    }/>;
};

type LocalizedBaseLinkProps = {
    to: string | LocationDescriptorObject<BaseState>,
} & ExcludeProps

type LocalizedLinkFactoryProps = {
    component: React.ReactElement<LocalizedBaseLinkProps>,
} & ExcludeProps

const LinkFactory: React.FC<LocalizedLinkFactoryProps> = ({component, exclude}) => {
    const nextLocation = useNextLocation({prevTo: component.props.to, exclude});
    
    return React.cloneElement(
        component,
        {
            ...component.props,
            to: nextLocation
        }
    );
};

type UseNextLocationProps = {
    prevTo: string | LocationDescriptorObject<BaseState>
}

const useNextLocation = ({prevTo, exclude}: UseNextLocationProps & ExcludeProps) => {
    const nextTo = useNextTo({prevTo});
    const nextState = useNextState({nextTo});
    
    const from = useFrom({exclude});
    
    return useMemo<LocationDescriptorObject<StateWithFrom>>(() => {
        return {
            ...nextTo,
            state: {
                ...nextState,
                from
            }
        };
    }, [nextTo, nextState, from]);
};

const useNextTo = ({prevTo}: UseNextLocationProps) => {
    const {joinBasePath} = useLocale();
    
    return useMemo<LocationDescriptorObject<BaseState>>(
        () => parsePath(prevTo, joinBasePath),
        [joinBasePath, prevTo]
    );
};

const parsePath = (path: BaseState, pathNameHandler: (pathname: string) => string) => {
    switch (typeof path) {
        case "string":
            const url = new URL(path, location.origin);
            
            return {
                hash: url.hash,
                search: url.search,
                pathname: pathNameHandler(url.pathname),
            };
        default:
            return {
                ...path,
                pathname: pathNameHandler(path?.pathname || "")
            };
    }
};

type UseNextStateProps = {
    nextTo: LocationDescriptorObject<BaseState>
}

const useNextState = ({nextTo}: UseNextStateProps) => {
    return useMemo<BaseStateObject>(() => {
        switch (typeof nextTo.state) {
            case "number":
            case "string": {
                return {
                    [Symbol.toPrimitive]() {
                        return nextTo.state;
                    }
                };
            }
            default: {
                return nextTo.state ?? {};
            }
        }
    }, [nextTo.state]);
};

type UseFromProps = {} & ExcludeProps

const useFrom = ({exclude}: UseFromProps) => {
    const location = useLocation<BaseStateObject>();
    
    if (matchString(exclude ?? [], location.pathname)) {
        return location.state?.from ?? {};
    }
    
    return location;
};

const matchString = (list: (string | RegExp)[], origin: string) => {
    return list.some(item => {
        if (typeof item === "string") {
            return item === origin;
        }
        
        return item.test(origin);
    });
};
