// tslint:disable:max-line-length
export const Chart = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_252_1610)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.046 14.5757C38.8693 13.906 39.0659 12.7402 38.4793 11.9123C38.3335 11.7626 38.1854 11.6152 38.0352 11.4702C36.4588 9.94879 34.6404 8.69082 32.6491 7.75033C30.659 6.81039 28.5331 6.20502 26.3571 5.95321C26.2313 5.93865 26.1053 5.92527 25.9791 5.91308C24.8735 5.87779 23.9951 6.81425 23.9998 7.94164L23.9998 8.94609C20.6375 8.94611 17.3508 9.94213 14.5553 11.8082C11.7596 13.6743 9.58073 16.3266 8.29405 19.4297C7.03353 22.4698 6.6848 25.8088 7.28786 29.0407C7.5089 30.2256 7.85666 31.3895 8.32902 32.5118C9.63221 35.6081 11.8252 38.2488 14.6307 40.1C17.4362 41.9512 20.7282 42.9297 24.0905 42.9119C27.4527 42.894 30.7341 41.8805 33.5197 39.9996C36.3053 38.1187 38.4701 35.4549 39.7402 32.3449C41.0104 29.2349 41.3289 25.8186 40.6554 22.5278C40.11 19.863 38.934 17.3775 37.2373 15.2739L38.046 14.5757ZM24.0082 17.4378L23.9998 15.5422L23.9998 11.614C21.1657 11.614 18.3954 12.4536 16.039 14.0265C15.2333 14.5643 14.4884 15.1795 13.8135 15.861C11.8202 17.8736 10.4696 20.4319 9.93145 23.2115C9.58734 24.9891 9.58559 26.8063 9.91632 28.5681C9.92592 28.6192 9.9358 28.6703 9.94597 28.7214C10.4989 31.4982 11.8636 34.0488 13.8676 36.0507C15.8716 38.0527 18.4248 39.416 21.2044 39.9684C23.984 40.5207 26.8651 40.2372 29.4834 39.1538C32.1018 38.0703 34.3397 36.2356 35.9142 33.8815C37.2913 31.8227 38.1068 29.4475 38.2897 26.9901L25.6273 26.9901C25.616 26.9904 25.6048 26.9905 25.5935 26.9905C24.7133 26.9905 23.9998 26.2777 23.9998 25.3984V17.4378H24.0082ZM26.7628 24.3166L26.7811 24.3366H38.2403C38.1925 23.9102 38.1254 23.485 38.0389 23.0622C37.5854 20.8462 36.6145 18.7774 35.2152 17.0196L26.7628 24.3166ZM26.4272 21.6922L36.2282 13.0614C34.8722 11.7809 33.3189 10.7187 31.6233 9.91786C29.9579 9.13127 28.1845 8.61067 26.3679 8.37042L26.4272 21.6922Z"
        fill="url(#paint0_linear_252_1610)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.046 14.5757C38.8693 13.906 39.0659 12.7402 38.4793 11.9123C38.3335 11.7626 38.1854 11.6152 38.0352 11.4702C36.4588 9.94879 34.6404 8.69082 32.6491 7.75033C30.659 6.81039 28.5331 6.20502 26.3571 5.95321C26.2313 5.93865 26.1053 5.92527 25.9791 5.91308C24.8735 5.87779 23.9951 6.81425 23.9998 7.94164L23.9998 8.94609C20.6375 8.94611 17.3508 9.94213 14.5553 11.8082C11.7596 13.6743 9.58073 16.3266 8.29405 19.4297C7.03353 22.4698 6.6848 25.8088 7.28786 29.0407C7.5089 30.2256 7.85666 31.3895 8.32902 32.5118C9.63221 35.6081 11.8252 38.2488 14.6307 40.1C17.4362 41.9512 20.7282 42.9297 24.0905 42.9119C27.4527 42.894 30.7341 41.8805 33.5197 39.9996C36.3053 38.1187 38.4701 35.4549 39.7402 32.3449C41.0104 29.2349 41.3289 25.8186 40.6554 22.5278C40.11 19.863 38.934 17.3775 37.2373 15.2739L38.046 14.5757ZM24.0082 17.4378L23.9998 15.5422L23.9998 11.614C21.1657 11.614 18.3954 12.4536 16.039 14.0265C15.2333 14.5643 14.4884 15.1795 13.8135 15.861C11.8202 17.8736 10.4696 20.4319 9.93145 23.2115C9.58734 24.9891 9.58559 26.8063 9.91632 28.5681C9.92592 28.6192 9.9358 28.6703 9.94597 28.7214C10.4989 31.4982 11.8636 34.0488 13.8676 36.0507C15.8716 38.0527 18.4248 39.416 21.2044 39.9684C23.984 40.5207 26.8651 40.2372 29.4834 39.1538C32.1018 38.0703 34.3397 36.2356 35.9142 33.8815C37.2913 31.8227 38.1068 29.4475 38.2897 26.9901L25.6273 26.9901C25.616 26.9904 25.6048 26.9905 25.5935 26.9905C24.7133 26.9905 23.9998 26.2777 23.9998 25.3984V17.4378H24.0082ZM26.7628 24.3166L26.7811 24.3366H38.2403C38.1925 23.9102 38.1254 23.485 38.0389 23.0622C37.5854 20.8462 36.6145 18.7774 35.2152 17.0196L26.7628 24.3166ZM26.4272 21.6922L36.2282 13.0614C34.8722 11.7809 33.3189 10.7187 31.6233 9.91786C29.9579 9.13127 28.1845 8.61067 26.3679 8.37042L26.4272 21.6922Z"
        fill="url(#paint1_radial_252_1610)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_252_1610"
        x1="24"
        y1="5.91211"
        x2="35.5276"
        y2="38.8815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B2FF" />
        <stop offset="1" stopColor="#0F85FF" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_252_1610"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.4088 5.91211) rotate(88.5355) scale(44.9433 15.6798)"
      >
        <stop stopColor="#72D9FF" />
        <stop offset="1" stopColor="#65C4FF" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_252_1610">
        <rect
          width="33.9999"
          height="37"
          fill="white"
          transform="translate(7 5.91211)"
        />
      </clipPath>
    </defs>
  </svg>
);
