import * as React from "react";
import * as Base from "../button";
import { ControlProps } from "./control";
import { JSONSchemaEnum } from "modules/client/api";

export const ButtonControl: React.FC<ControlProps<JSONSchemaEnum>> = (props) => {
    return (
        <div className="btn-container">
            {props.schema.oneOf.map(({ const: value, title }, i) => (
                <Base.Button
                    type="submit"
                    key={i}
                    theme={i === 0 ? "primary" : "secondary"}
                    onClick={(() => props.onChange(value))}
                >
                    {title}
                </Base.Button>
            ))}
        </div>
    );
};
