import { State } from "./state";

export type CapacitorStoreAction =
    | { type: "SET_LIVE_UPDATE_VERSION"; payload: State["liveUpdateVersion"] }
    | { type: "SET_PUSH_TOKEN"; payload: State["pushToken"] }
    | { type: "SET_DEVICE_INFO"; payload: State["deviceInfo"] }

export function reducer(state: State, action: CapacitorStoreAction): State {
    switch (action.type) {
        case "SET_LIVE_UPDATE_VERSION": {
            const { liveUpdateVersion, ...rest } = state;
            return !action.payload
                ? { ...rest }
                : { ...state, liveUpdateVersion: action.payload };
        }
        case "SET_PUSH_TOKEN": {
            const { pushToken, ...rest } = state;
            return !action.payload
                ? { ...rest }
                : { ...state, pushToken: action.payload };
        }
        case "SET_DEVICE_INFO": {
            const { deviceInfo, ...rest } = state;
            return !action.payload
                ? { ...rest }
                : { ...state, deviceInfo: action.payload };
        }
        default:
            return state;
    }
}
