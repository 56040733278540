// tslint:disable:max-line-length
export const IconCardCheck = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_card-check"
    >
        <mask
            id="mask0_631_1731"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_631_1731)">
            <path
                d="M4 8H20V6H4V8ZM2 6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.8043 4.97933 22 5.45 22 6V12H4V18H8.1V20H4C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6ZM14.95 22L10.7 17.75L12.1 16.35L14.95 19.15L20.6 13.5L22 14.95L14.95 22ZM4 6V18V13.5V16.325V6Z"
                fill="url(#paint0_linear_631_1731)"
            />
            <path
                d="M4 8H20V6H4V8ZM2 6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.8043 4.97933 22 5.45 22 6V12H4V18H8.1V20H4C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6ZM14.95 22L10.7 17.75L12.1 16.35L14.95 19.15L20.6 13.5L22 14.95L14.95 22ZM4 6V18V13.5V16.325V6Z"
                fill="url(#paint1_radial_631_1731)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_631_1731"
                x1="12"
                y1="4"
                x2="16.8034"
                y2="20.611"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#38B2FF" />
                <stop offset="1" stopColor="#0F85FF" />
            </linearGradient>
            <radialGradient
                id="paint1_radial_631_1731"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(3.5 4) rotate(80.8161) scale(22.141 9.33735)"
            >
                <stop stopColor="#72D9FF" />
                <stop offset="1" stopColor="#65C4FF" stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>
);
