import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as Base from "../base"
import * as State from "../state"

import {DataResult} from "modules/client/api";
import {ContactsState, ContactsStateType} from "modules/client/api/contacts-api";

enum InitType {
    calculator,
    contacts,
    data,
}

type BaseInitResult<D extends InitType = InitType> = {
    type: D,
}
type InitResultData = BaseInitResult<InitType.data> & {
    data: DataResult,
}
type InitResultContacts = BaseInitResult<InitType.contacts> & {
    state: ContactsState,
}
type InitResult = BaseInitResult<InitType.calculator>
    | InitResultContacts
    | InitResultData;

const handleRequest = async (api: Client.Api.Instance): Promise<InitResult> => {
    const isCalcShown = localStorage.getItem("firstCalculator.done");
    if (!isCalcShown) {
        return {
            type: InitType.calculator
        };
    }

    const contactsState = await api.user.contacts.getContacts();
    if (contactsState.state !== ContactsStateType.exists) {
        return {
            type: InitType.contacts,
            state: contactsState,
        };
    }

    const data = await api.user.data.get();
    return {
        type: InitType.data,
        data,
    };
};

const handleFinish = async (result: InitResult): Promise<Chat.Action> => {
    switch (result.type) {
        case InitType.data:
            return new Chat.ReplaceAction([
                new Chat.Registration.CompleteMessage(
                    result.data.schema,
                    result.data.values
                ),
                new Chat.Registration.CompleteFormMessage(),
            ]);
        case InitType.calculator:
            return new Chat.ReplaceAction([
                new Chat.TextMessage(
                    "Change amount and term",
                    "bot"
                ),
                new Chat.Calculator.EditLoadingMessage(),
            ]);
        case InitType.contacts:
            return new Chat.FlushAction([
                new Chat.ShareContacts.ShareContactsMessage(result.state),
            ]);
    }
};

export const CompleteInitMessage: State.Message<State.MessageProps<Chat.Registration.CompleteInitMessage>> =
    (props) => {
        return <Base.DelaySubmitMessage
            delayMs={2000}
            value={props.value}
            onRequest={handleRequest}
            onFinish={handleFinish}
        />
    };
