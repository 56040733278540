import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as Base from "../base"
import * as State from "../state"

import {ContactsState} from "modules/client/api/contacts-api";

const handleRequest = (api: Client.Api.Instance): Promise<ContactsState> => {
    return api.user.contacts.getContacts();
};

const handleFinish = async (state: ContactsState): Promise<Chat.Action> => {
    return new Chat.ReplaceAction([
        new Chat.ShareContacts.ShareContactsMessage(state),
    ]);
};

export const InitMessage: State.Message<State.MessageProps<Chat.ShareContacts.ShareContactsInitMessage>> =
    (props) => {
        return <Base.DelaySubmitMessage
            delayMs={2000}
            value={props.value}
            onRequest={handleRequest}
            onFinish={handleFinish}
        />
    };
