import classNames from "classnames";
import * as React from "react";
import * as State from "../../state";

type InputMessageProps = State.MessageProps & {
    onSubmit: () => void;
    value: string;
    onChange: (value: string) => void;
    disabled: boolean;
};

export const InputMessage: State.Message<InputMessageProps> = ({
    onSubmit,
    value,
    onChange,
    disabled,
}) => {
    const handleSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            onSubmit();
        },
        [onSubmit]
    );

    const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.currentTarget.value);
        },
        [onChange]
    );

    return (
        <form onSubmit={handleSubmit} className="message user-message editing">
            <div className="form-group">
                <input
                    onChange={handleChange}
                    value={value}
                    id="sign-token"
                    className="form__control"
                    inputMode="tel"
                    autoFocus
                />
                <div className="btn-container v-align">
                    <button
                        id={"sign-token-submit"}
                        type="submit"
                        className={classNames(
                            "btn btn_action",
                            disabled ? "disabled" : "enabled"
                        )}
                    >
                        <i className="icon icon_arrow" />
                    </button>
                </div>
            </div>
        </form>
    );
};
