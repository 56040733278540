import * as React from "react";
import * as State from "../state";
import * as Client from "modules/client";
import { PromoPageWelcome } from "modules/chat/promo-page";
import * as Chat from "modules/chat";
import { PromoPageInfo } from "modules/client/api/promo-page";
import { LocalizedRedirect, useLocale } from "app/locale";

export const WelcomeMessage: React.FC<State.MessageProps<PromoPageWelcome>> = ({
    value: { promoShortUrl },
}) => {
    const { currentLanguage } = useLocale();
    const [state, setState] = React.useState<PromoPageInfo | undefined | null>(
        undefined
    );
    const clientState = Client.useStateContext();
    const dispatch = Chat.useDispatchContext();

    React.useEffect(() => {
        document.body.classList.add("promocode");

        return () => document.body.classList.remove("promocode");
    }, []);

    React.useEffect(() => {
        clientState.api.guest.promoPage
            .validate(promoShortUrl)
            .then((data) => (data.promocode ? setState(data) : setState(null)));
    }, []);
    React.useEffect(() => {
        if (!state) {
            return;
        }

        dispatch(
            new Chat.PushAction([
                new Chat.PromoPage.IntroMessage(
                    state.welcomeRu,
                    state.welcomeUa
                ),
                new Chat.PromoPage.LoadingMessage({
                    code: state.promocode,
                    discount: state.discount,
                }),
            ])
        );
    }, [state, currentLanguage]);

    if (state === null) {
        return <LocalizedRedirect to="/chat" />;
    }

    if (clientState.type === "user") {
        return <LocalizedRedirect to="/dashboard" />;
    }

    return (
        <>
            <h1 className="title">
                {!state
                    ? ""
                    : currentLanguage === "ru"
                    ? state.titleRu
                    : state.titleUa}
            </h1>
            <h2 className="under-title">
                {!state
                    ? ""
                    : currentLanguage === "ru"
                    ? state.subtitleRu
                    : state.subtitleUa}
            </h2>
        </>
    );
};
