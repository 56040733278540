import {Translate} from "app/locale";
import {pathNameCheck} from "../utils/pathname-check";
import {restructuringPathNameCheck} from "../utils/restructuring-pathname-check";
import * as Agreement from "./agreement";
import * as BankId from "./bank-id";
import * as BankAccount from "./bank-account";
import * as PhoneId from "./phone-id";
import * as SmileId from "./smile-id";
import * as Card from "./card";
import * as DirectDebit from "./direct-debit";

import {
    FailureMessage,
    LoadMessage,
    Message,
    RedirectMessage,
    TextMessage,
} from "./message";
import {ClientStatus} from "./reducer";
import * as Registration from "./registration";
import * as Restructuring from "./restructuring";
import * as SignUp from "./sign-up";
import * as PromoPage from "./promo-page";
import * as ShareContacts from "./share-сontacts";
import {chatBankAccountPathnameCheck} from "../utils/chat-bank-account-pathname-check";

export interface BaseState {
    messages: Array<Message>;
}

export class State implements BaseState {
    public static getInitialMessages(
        status: ClientStatus,
        translate: Translate,
        location?: string,
        isEditableTerm?: boolean,
        match?: { promoShortUrl?: string }
    ): State["messages"] {
        if (match?.promoShortUrl) {
            return [new PromoPage.PromoPageWelcome(match.promoShortUrl)];
        }
        if (status === null) {
            if (pathNameCheck(location)) {
                return [
                    new TextMessage(translate("welcome")),
                    new SignUp.RequestFormMessage(),
                ];
            }
            return [
                new SignUp.InfoMessage(),
                new TextMessage("Enter your mobile phone number:"),
                new SignUp.RequestFormMessage(),
            ];
        } else if (restructuringPathNameCheck(location)) {
            if (status === "restructuring") {
                return [
                    new Restructuring.RestructuringWelcomeMessage(),
                    new Restructuring.RestructuringOpenMessage(),
                    new Restructuring.RestructuringConfirmRequestMessage(),
                ];
            }
            return [new RedirectMessage("/chat")];
        } else if (chatBankAccountPathnameCheck(location)) {
            return [
                new BankAccount.WelcomeMessage(),
                new BankAccount.InitDashboardMessage(),
            ];
        } else if (/^(\/ru)?\/chat\/card-success$/.test(location ?? "")) {
            return [
                new Card.LoadCompleteMessage,
            ];
        } else if (pathNameCheck(location)) {
            return [new RedirectMessage("/chat")];
        }

        switch (status) {
            case false:
                return [new FailureMessage()];
            case undefined:
                return [];
            case "registration":
                return [
                    new Registration.WelcomeMessage(),
                    isEditableTerm
                        ? new Registration.ValidateTermMessage()
                        : new Registration.ValidateMessage(),
                ];
            case "bankId":
                return [new BankAccount.WelcomeMessage, new BankAccount.InitMessage,];
            case "phoneId":
                return [
                    new PhoneId.InfoMessage(),
                    new PhoneId.SelectOperatorMessage(),
                ];
            case "contacts":
                return [
                    new ShareContacts.ShareContactsInitMessage(),
                ];
            case "agreement":
                return [
                    new Agreement.AgreementWelcomeMessage(),
                    new Agreement.AgreementInfoMessage(),
                    new Agreement.AgreementConfirmRequestMessage(),
                ];
            case "dashboard":
            case "pending":
                return [new RedirectMessage("/dashboard")];
            case "restructuring":
                return [new RedirectMessage("/dashboard/restructuring")];
            case "faceId":
                return [new SmileId.InitMessage(),];
            case "card":
                return [new Card.InitMessage, new Card.ConfirmMessage];
            case "directDebit":
                return [new DirectDebit.InitMessage(),];
        }
        return [new TextMessage(status)];
    }

    constructor(public messages: Array<Message> = [LoadMessage]) {
    }
}
