import {AxiosInstance, AxiosResponse} from "axios";

export type CreateResponseFailed = {
    status: "failed",
    message: string,
}
export type CreateResponseSuccess = {
    status: "success",
    redirectUrl: string;
}
export type CreateResponse = CreateResponseFailed | CreateResponseSuccess;


export type MandateStatus = "successful" | "approved" | "rejected";
export type MandateEntityAccount = {
    name: string; // account owner name
    number: string; // censored account number (last 4 digits)
    bank: string; // bank name
}
export type MandateEntity = {
    status: MandateStatus;
    account: MandateEntityAccount;
    createdAt: string; // date format Y-m-d H:i:s
};
export type MandatesResponse = {
    mandates: Array<MandateEntity>
};

export const Api = (endpoint: AxiosInstance) => ({
    create: async (): Promise<CreateResponse> => {
        const response= await endpoint.post<CreateResponse, AxiosResponse<CreateResponse>>(
            "v3/mono/mandate",
            {},
            {
                validateStatus: (status) => [422, 423, 201].includes(status),
            },
        );
        return response.data;
    },
    getMandates: async (): Promise<Array<MandateEntity>> => {
        const response= await endpoint.get<MandatesResponse, AxiosResponse<MandatesResponse>>(
            "v3/mono/mandates",
        );
        return response.data.mandates;
    },
} as const);
