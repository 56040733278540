import * as Client from "modules/client";

export const innFilter = (schema: Client.Api.JSONSchemaInn) => (value: string): string => {
    value = value.replace(/\D/g, '').substr(0, 11);
    if (("min" in schema) && schema.max && (schema.max < parseInt(value.padEnd(10, '0'), 10))) {
        return value.substr(0, value.length - 1);
    }
    if (("max" in schema) && schema.min && (schema.min > parseInt(value.padEnd(10, '9'), 10))) {
        return value.substr(0, value.length - 1);
    }
    return value;
}

