import { useCallback } from "react";
import * as Client from "modules/client";
import * as Chat from "modules/chat";

export function useHandleDataChange(
    value: string,
    attribute: Client.Api.DataAttributeRecord<any>
) {
    const dispatch = Chat.useDispatchContext();

    const name = attribute.name;

    return useCallback(() => {
        const item = attribute.schema;
        if (item === undefined) {
            return;
        }
        dispatch(
            new Chat.ReplaceAction([
                new Chat.Registration.FormMessage(
                    name,
                    item.title || name,
                    value,
                    item
                ),
            ])
        );
    }, [dispatch, value, attribute.name, attribute.schema]);
}
