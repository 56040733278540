import * as React from "react";
import { IconMono } from "modules/components/Icons/IconMono";
import { Details } from "modules/chat/bank-id";
import { LoadingSpinner } from "modules/components/loading-spinner";

export const Data: React.FC<{ data: Details | undefined }> = ({ data }) => {
    if (!data) {
        return (
            <div className="spinner-container">
                <LoadingSpinner />
            </div>
        );
    }

    const formattedString = (str: string): string => {
        return str.replace(/_/g, " ");
    };

    const { name, type, institution, accountNumber } = data;

    return (
        <ul className="mono-data">
            <li className="full">
                <span className="label">Full Name</span>
                <span className="value">{name}</span>
            </li>
            <li>
                <span className="label">Service type</span>
                <span className="value">
                    {formattedString(institution.type)}
                </span>
            </li>
            <li>
                <span className="label">Bank name</span>
                <span className="value">{institution.name}</span>
            </li>
            <li>
                <span className="label">Account type</span>
                <span className="value">{formattedString(type)}</span>
            </li>
            <li>
                <span className="label">Bank code</span>
                <span className="value">{institution.bankCode}</span>
            </li>
            <li className="with-icon">
                <IconMono />
                <span>Connected via MONO</span>
            </li>
            <li>
                <span className="label">Account number</span>
                <span className="value">{accountNumber}</span>
            </li>
        </ul>
    );
};
