import * as React from "react";
import White from "./white-logo.svg";
import Color from "./color-logo.svg";

const logo = {
    white: White,
    color: Color,
} as const;

export type LogoProps = {
    type: keyof typeof logo;
}
export const Logo: React.FC<LogoProps> = (props) => {
    return (
        <img
            src={logo[props.type]}
            alt="LoviLave"
        />
    );
};

Logo.displayName = "Logo";
