import * as React from "react";
import * as Chat from "modules/chat";
import { useInputState } from "@horat1us/react-hooks";
import * as State from "../state";
import { PhoneInput } from "modules/react-phone-input";
import classNames from "classnames";

export const RequestFormMessage: React.FC<State.MessageProps<Chat.SignUp.RequestFormMessage>> = (props) => {
    const dispatch = Chat.useDispatchContext();
    const [value, handleChange] = useInputState(props.value.phone);
    const rawValue = value.replace(/\D/g, "");
    const isSubmitEnable = rawValue.length === 13;
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isSubmitEnable) {
            return false;
        }
        dispatch(
            new Chat.ReplaceAction([
                new Chat.TextMessage(value, "user"),
                new Chat.SignUp.RequestMessage(rawValue),
            ])
        );
    };

    return (
        <form onSubmit={handleSubmit} className="message user-message editing">
            <div className="form-group">
                <PhoneInput
                    onChange={handleChange}
                    value={value}
                    id="sign-phone"
                    className="form__control"
                    type="tel"
                    autoFocus
                />
                <i className="icon icon_phone" />
                <div className="btn-container v-align">
                    <button
                        id={"sign-phone-submit"}
                        type="submit"
                        className={classNames(
                            "btn btn_action",
                            isSubmitEnable ? "enabled" : "disabled"
                        )}
                    >
                        <i className="icon icon_arrow" />
                    </button>
                </div>
            </div>
        </form>
    );
}
