import * as React from "react";
import * as Base from "app/chat/base";
import * as State from "app/chat/state";
import * as Chat from "modules/chat";

export const InfoMessage: State.Message<
    State.MessageProps<Chat.SmileId.InfoMessage>
> = ({value}) => {
    // todo: consider different messages
    let message = "To continue with the registration, you need to verify your identity and documents.";
    switch (value.status) {
        case "Rejected":
            message = "Unfortunately, we were unable to identify your identity. Please try again.";
            break;
        case "Error":
            message = "Unfortunately, an error occurred during the identification process. Please try again.";
            break;
        case "Inconclusive":
            message = "Unfortunately, we were unable to identify you in the photograph. Please try again and ensure that the photo is taken clearly.";
            break;
    }

    return (
        <Base.WrapMessage source="bot">
            <p>{message}</p>
        </Base.WrapMessage>
    );
};
