import * as React from "react";
import * as Client from "modules/client";
import { ControlProps } from "./control";
import Select from "react-select";

export type SelectControlProps = ControlProps<Client.Api.JSONSchemaEnum>;
const DropdownIndicator = () => <i className="icon icon_select-arrow"/>;
export const SelectControl: React.FC<SelectControlProps> = ({ schema, onChange }) => {
    const options = schema.oneOf.map(({ const: value, title: label }) => ({ label, value }));
    const handleChange = ({ value }: any) => {
        onChange(value);
    };
    return (
        <Select
            options={options}
            components={{ DropdownIndicator }}
            placeholder={"Выберите из списка"}
            onChange={handleChange}
            className="form__control-select"
            classNamePrefix="select"
        />
    );
};
