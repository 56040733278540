// tslint:disable:max-line-length
export const IconCard = () => (
    <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_card"
    >
        <mask
            id="mask0_766_10019"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="25"
            height="24"
        >
            <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_766_10019)">
            <path
                d="M22.5 6V18C22.5 18.55 22.3043 19.021 21.913 19.413C21.521 19.8043 21.05 20 20.5 20H4.5C3.95 20 3.47933 19.8043 3.088 19.413C2.696 19.021 2.5 18.55 2.5 18V6C2.5 5.45 2.696 4.97933 3.088 4.588C3.47933 4.196 3.95 4 4.5 4H20.5C21.05 4 21.521 4.196 21.913 4.588C22.3043 4.97933 22.5 5.45 22.5 6ZM4.5 8H20.5V6H4.5V8ZM4.5 12V18H20.5V12H4.5Z"
                fill="url(#paint0_linear_766_10019)"
            />
            <path
                d="M22.5 6V18C22.5 18.55 22.3043 19.021 21.913 19.413C21.521 19.8043 21.05 20 20.5 20H4.5C3.95 20 3.47933 19.8043 3.088 19.413C2.696 19.021 2.5 18.55 2.5 18V6C2.5 5.45 2.696 4.97933 3.088 4.588C3.47933 4.196 3.95 4 4.5 4H20.5C21.05 4 21.521 4.196 21.913 4.588C22.3043 4.97933 22.5 5.45 22.5 6ZM4.5 8H20.5V6H4.5V8ZM4.5 12V18H20.5V12H4.5Z"
                fill="url(#paint1_radial_766_10019)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_766_10019"
                x1="12.5"
                y1="4"
                x2="16.3577"
                y2="19.0084"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#38B2FF" />
                <stop offset="1" stopColor="#0F85FF" />
            </linearGradient>
            <radialGradient
                id="paint1_radial_766_10019"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(4 4) rotate(79.6914) scale(19.7473 9.36651)"
            >
                <stop stopColor="#72D9FF" />
                <stop offset="1" stopColor="#65C4FF" stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>
);
