// tslint:disable:max-line-length
export const IconVisaColored = () => (
    <svg
        width="30"
        height="24"
        viewBox="0 0 30 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_visa-colored"
    >
        <path
            d="M14.3309 7.57422H11.9099L10.3979 16.4462H12.8149L14.3309 7.57422Z"
            fill="#3071D8"
        />
        <path
            d="M7.12698 16.4371L10.934 7.58008H8.37198L6.00398 13.6311L5.75098 12.7171C4.98234 11.2148 3.8261 9.94532 2.40198 9.04007L4.56698 16.4401L7.12698 16.4371Z"
            fill="#3071D8"
        />
        <path
            d="M21.236 13.546C21.245 12.539 20.598 11.77 19.205 11.136C18.358 10.726 17.842 10.453 17.848 10.036C17.848 9.66904 18.285 9.27404 19.232 9.27404C19.8524 9.26037 20.4689 9.37616 21.042 9.61404L21.261 9.71405L21.589 7.79205C20.8939 7.54158 20.1599 7.41598 19.421 7.42105C17.031 7.42105 15.349 8.62105 15.334 10.348C15.319 11.623 16.534 12.334 17.453 12.759C18.394 13.192 18.71 13.469 18.704 13.859C18.698 14.453 17.954 14.724 17.259 14.724C16.4726 14.7495 15.691 14.5916 14.976 14.263L14.667 14.121L14.327 16.109C15.189 16.4269 16.1013 16.5865 17.02 16.58C19.566 16.576 21.218 15.389 21.236 13.546Z"
            fill="#3071D8"
        />
        <path
            d="M24.111 15.1164H27.211C27.284 15.4254 27.506 16.4464 27.506 16.4464H29.75L27.792 7.58044H25.925C25.6625 7.54612 25.3959 7.59958 25.167 7.73252C24.938 7.86547 24.7594 8.07042 24.659 8.31544L21.067 16.4464H23.609C23.609 16.4464 24.017 15.3564 24.111 15.1164ZM25.769 10.8164C25.757 10.8404 25.969 10.3034 26.091 9.96943L26.255 10.7344C26.255 10.7344 26.717 12.8534 26.814 13.2974H24.807C25.007 12.7904 25.769 10.8204 25.769 10.8204V10.8164Z"
            fill="#3071D8"
        />
        <path
            d="M5.651 12.1376L4.834 8.21757C4.77482 7.99034 4.63563 7.79209 4.442 7.65927C4.24836 7.52644 4.0133 7.46796 3.78 7.49456H0.032999L0 7.67356C1.23427 7.90112 2.39426 8.42675 3.37912 9.20474C4.36397 9.98272 5.14387 10.9895 5.651 12.1376Z"
            fill="#FFD000"
        />
    </svg>
);
