import { useCallback } from "react";
import * as Chat from "modules/chat";
import {useLocale} from "app/locale";
import * as State from "../state";
import * as Base from "../base";

export const ConfirmEmailFormMessage: State.Message<State.MessageProps<Chat.Agreement.AgreementConfirmEmailFormMessage>> =
    (props) => {
        const {t} = useLocale("registration")
    
        const handleSubmit = useCallback((_, value: string): Chat.Action => {
            return new Chat.PushAction([
                new Chat.Agreement.AgreementConfirmEmailSubmitMessage({
                    ...props.value.record,
                    value,
                }),
            ])
        }, [props.value.record]);
        return (
            <Base.Form.Message
                onSubmit={handleSubmit}
                attribute={props.value.record as any}
                label={props.value.error ?? t("enter-email")}
            />
        );
    };
