import * as React from "react";
import * as State from "../state";
import * as Chat from "modules/chat";
import { CalculatorStorage } from "../calculator/storage";

export const SetPromocodeMessage: React.FC<
    State.MessageProps<Chat.PromoPage.SetPromocodeMessage>
> = ({ value: { promo } }) => {
    if (!promo) {
        return null;
    }
    React.useEffect(() => {
        CalculatorStorage.setPromoCode(promo.code, promo.discount);
    }, [promo.code, promo.discount]);

    return null;
};
