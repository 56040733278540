import {AxiosInstance} from "axios";

export const Api = (endpoint: AxiosInstance) => {
    return {
        avatar: async (file: File) => {
            const formData = new FormData();
            formData.append("PhotoForm[file]", file);
            return endpoint.post("/v2/user/photo", formData);
        },
    } as const
}
