// tslint:disable:max-line-length
export const IconArrow = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_arrow-forward"
    >
        <mask
            id="mask0_638_2938"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_638_2938)">
            <path
                d="M11.3 19.2998C11.1167 19.1165 11.0207 18.8831 11.012 18.5998C11.004 18.3165 11.0917 18.0831 11.275 17.8998L16.175 12.9998H5C4.71667 12.9998 4.479 12.9038 4.287 12.7118C4.09567 12.5205 4 12.2831 4 11.9998C4 11.7165 4.09567 11.4788 4.287 11.2868C4.479 11.0955 4.71667 10.9998 5 10.9998H16.175L11.275 6.0998C11.0917 5.91647 11.004 5.68314 11.012 5.3998C11.0207 5.11647 11.1167 4.88314 11.3 4.6998C11.4833 4.51647 11.7167 4.4248 12 4.4248C12.2833 4.4248 12.5167 4.51647 12.7 4.6998L19.3 11.2998C19.4 11.3831 19.471 11.4871 19.513 11.6118C19.5543 11.7371 19.575 11.8665 19.575 11.9998C19.575 12.1331 19.5543 12.2581 19.513 12.3748C19.471 12.4915 19.4 12.5998 19.3 12.6998L12.7 19.2998C12.5167 19.4831 12.2833 19.5748 12 19.5748C11.7167 19.5748 11.4833 19.4831 11.3 19.2998Z"
                fill="url(#paint0_linear_638_2938)"
                className="icon-fill"
            />
            <path
                d="M11.3 19.2998C11.1167 19.1165 11.0207 18.8831 11.012 18.5998C11.004 18.3165 11.0917 18.0831 11.275 17.8998L16.175 12.9998H5C4.71667 12.9998 4.479 12.9038 4.287 12.7118C4.09567 12.5205 4 12.2831 4 11.9998C4 11.7165 4.09567 11.4788 4.287 11.2868C4.479 11.0955 4.71667 10.9998 5 10.9998H16.175L11.275 6.0998C11.0917 5.91647 11.004 5.68314 11.012 5.3998C11.0207 5.11647 11.1167 4.88314 11.3 4.6998C11.4833 4.51647 11.7167 4.4248 12 4.4248C12.2833 4.4248 12.5167 4.51647 12.7 4.6998L19.3 11.2998C19.4 11.3831 19.471 11.4871 19.513 11.6118C19.5543 11.7371 19.575 11.8665 19.575 11.9998C19.575 12.1331 19.5543 12.2581 19.513 12.3748C19.471 12.4915 19.4 12.5998 19.3 12.6998L12.7 19.2998C12.5167 19.4831 12.2833 19.5748 12 19.5748C11.7167 19.5748 11.4833 19.4831 11.3 19.2998Z"
                fill="url(#paint1_radial_638_2938)"
                className="icon-fill"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_638_2938"
                x1="11.7875"
                y1="4.4248"
                x2="16.101"
                y2="18.2267"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#38B2FF" />
                <stop offset="1" stopColor="#0F85FF" />
            </linearGradient>
            <radialGradient
                id="paint1_radial_638_2938"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(7.39388 4.4248) rotate(88.3616) scale(18.404 7.18217)"
            >
                <stop stopColor="#72D9FF" />
                <stop offset="1" stopColor="#65C4FF" stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>
);
