declare global {
    namespace NodeJS {
        interface ProcessEnv {
            BUILD_TIMESTAMP: string;
            BUILD_NAME: string;
            BUILD_VERSION: string;
            BUILD_SOURCE: string;
        }
    }
}

export interface BuildTag {
    name: string;
    version: string;
    timestamp: string;
    source: string;
}

export class BuildTag implements BuildTag {
    constructor() {
        this.name = process.env.BUILD_NAME;
        this.version = process.env.BUILD_VERSION;
        this.timestamp = process.env.BUILD_TIMESTAMP;
        this.source = process.env.BUILD_SOURCE;
        Object.freeze(this);
    }

    public toString() {
        return Object.values(this).join(' ');
    }
}

export default new BuildTag();
