// tslint:disable:max-line-length
import { forwardRef } from "react";

import "./hamburger.scss";

export const IconHamburger = forwardRef<
    SVGSVGElement,
    React.SVGProps<SVGSVGElement>
>((props, ref) => (
    <svg
        width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.5238 1L23 1M1 17H11.4762M1 9H23"
            stroke="url(#paint0_linear_522_3723)"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_522_3723"
                x1="12"
                y1="1"
                x2="12"
                y2="17"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#38B2FF" />
                <stop offset="1" stopColor="#0073D6" />
            </linearGradient>
        </defs>
    </svg>
));
IconHamburger.displayName = "IconHamburger";
