// tslint:disable:max-line-length
export const IconDownload = () => (
    <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_download"
    >
        <path
            d="M2 16.5C1.45 16.5 0.979333 16.3043 0.588 15.913C0.196 15.521 0 15.05 0 14.5V11.5H2V14.5H14V11.5H16V14.5C16 15.05 15.8043 15.521 15.413 15.913C15.021 16.3043 14.55 16.5 14 16.5H2ZM8 12.5L3 7.5L4.4 6.05L7 8.65V0.5H9V8.65L11.6 6.05L13 7.5L8 12.5Z"
            fill="url(#paint0_linear_905_3046)"
        />
        <path
            d="M2 16.5C1.45 16.5 0.979333 16.3043 0.588 15.913C0.196 15.521 0 15.05 0 14.5V11.5H2V14.5H14V11.5H16V14.5C16 15.05 15.8043 15.521 15.413 15.913C15.021 16.3043 14.55 16.5 14 16.5H2ZM8 12.5L3 7.5L4.4 6.05L7 8.65V0.5H9V8.65L11.6 6.05L13 7.5L8 12.5Z"
            fill="url(#paint1_radial_905_3046)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_905_3046"
                x1="8"
                y1="0.5"
                x2="12.6597"
                y2="15.0028"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#38B2FF" />
                <stop offset="1" stopColor="#0F85FF" />
            </linearGradient>
            <radialGradient
                id="paint1_radial_905_3046"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(3.48649 0.5) rotate(88.4063) scale(19.4361 7.37832)"
            >
                <stop stopColor="#72D9FF" />
                <stop offset="1" stopColor="#65C4FF" stopOpacity="0" />
            </radialGradient>
        </defs>
    </svg>
);
