// tslint:disable:max-line-length
export const IconMonoFull = () => (
    <svg
        width="81"
        height="12"
        viewBox="0 0 81 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_mono-full"
    >
        <g clip-path="url(#clip0_534_54646)">
            <path
                d="M39.3432 0.00244767C38.423 -0.0125356 37.5263 0.289605 36.8072 0.856916C36.0489 1.47664 35.5232 2.32977 35.3141 3.27965C35.156 1.09485 34.0894 0.00244767 32.1144 0.00244767C31.2262 -0.0178572 30.36 0.277035 29.6732 0.833603C28.968 1.42973 28.4845 2.24114 28.2989 3.13936V2.97541C28.3069 2.33228 28.2102 1.69206 28.0126 1.0792C27.9461 0.804896 27.8507 0.538185 27.7282 0.283373H25.3342V11.6362H28.3461V5.50237C28.3619 4.47239 28.5989 3.67647 29.0571 3.11461C29.2801 2.83875 29.5659 2.61869 29.8913 2.47227C30.2166 2.32585 30.5722 2.25718 30.9294 2.27184C31.5297 2.27159 31.9524 2.416 32.1974 2.70506C32.4424 2.99414 32.5648 3.47403 32.5648 4.14473V11.6362H35.5749V5.50416C35.5907 4.47418 35.8237 3.67826 36.2741 3.1164C36.498 2.83841 36.7856 2.617 37.1132 2.47046C37.4408 2.32391 37.799 2.25643 38.1582 2.27358C38.7586 2.27334 39.1812 2.41776 39.4263 2.70684C39.6712 2.99589 39.7937 3.47577 39.7937 4.14648V11.638H42.8038V4.00513C42.8047 2.67854 42.4966 1.67978 41.8792 1.00885C41.2619 0.337916 40.4166 0.00244767 39.3432 0.00244767Z"
                fill="white"
            />
            <path
                d="M50.0136 0.00390754C48.3072 0.00296231 46.9208 0.521235 45.8543 1.55873C44.7878 2.59627 44.2546 4.00471 44.2546 5.78405V5.83069C44.2558 7.8282 44.8093 9.33807 45.9151 10.3603C47.0508 11.3481 48.512 11.8933 50.0253 11.8935C51.5386 11.8939 53.0001 11.3494 54.1361 10.362C55.2422 9.34007 55.7952 7.8224 55.7952 5.80913V5.76249C55.7961 3.98336 55.2595 2.57881 54.1852 1.54886C53.1108 0.518879 51.7203 0.00389542 50.0136 0.00390754ZM52.7407 6.15793C52.7405 8.68593 51.8319 9.94993 50.0151 9.94993V9.95073C48.2128 9.95073 47.3122 8.68667 47.3134 6.15863V5.31582C47.3136 4.25475 47.5545 3.43552 48.0361 2.85811C48.2755 2.57229 48.579 2.34529 48.9224 2.19499C49.2658 2.04469 49.6399 1.9752 50.0151 1.99199C50.392 1.97557 50.7677 2.04517 51.113 2.19537C51.4584 2.34559 51.7639 2.57237 52.0062 2.85811C52.4959 3.43645 52.7407 4.25569 52.7407 5.31582V6.15793Z"
                fill="white"
            />
            <path
                d="M64.3113 0.00210714C63.3598 -0.028452 62.4279 0.274201 61.6804 0.856573C60.9514 1.45615 60.457 2.28805 60.2822 3.20897V2.97507C60.2913 2.332 60.1952 1.6917 59.9978 1.07886C59.9306 0.804493 59.8346 0.537781 59.7115 0.283033H57.3143V11.6359H60.3287V5.50381C60.3285 4.48938 60.5852 3.69735 61.0991 3.12771C61.3485 2.84814 61.6576 2.62655 62.004 2.47882C62.3504 2.33109 62.7255 2.26089 63.1027 2.27325C64.3823 2.27325 65.0222 2.89755 65.0222 4.14615V11.6377H68.0089V4.00479C68.0089 2.6782 67.6856 1.67944 67.037 1.00851C66.3886 0.337575 65.4801 0.00210714 64.3113 0.00210714Z"
                fill="white"
            />
            <path
                d="M79.3881 1.54885C78.3135 0.518877 76.9237 0.00389542 75.2173 0.00390754C73.5102 0.00296231 72.1238 0.521235 71.0573 1.55873C69.9908 2.59627 69.4575 4.00471 69.4575 5.78405V5.83069C69.4575 7.8282 70.011 9.33807 71.1167 10.3603C72.2534 11.3484 73.7147 11.8935 75.2288 11.8935C76.7428 11.8935 78.2048 11.3484 79.3409 10.3603C80.4472 9.33827 81 7.82067 81 5.80737V5.76074C81 3.98276 80.4627 2.5788 79.3881 1.54885ZM77.9429 6.15793C77.9423 8.68593 77.0337 9.94993 75.2173 9.94993V9.95073C73.4157 9.95073 72.5153 8.68667 72.5153 6.15863V5.31582C72.5159 4.25475 72.7569 3.43552 73.2382 2.85811C73.4778 2.57225 73.7809 2.34523 74.1245 2.19493C74.468 2.04462 74.842 1.97515 75.2173 1.99199C75.5939 1.97565 75.9699 2.04528 76.3148 2.19549C76.6604 2.34569 76.9655 2.57243 77.2079 2.85811C77.6979 3.43645 77.9429 4.25569 77.9429 5.31582V6.15793Z"
                fill="white"
            />
            <path
                d="M15.0379 0.0816034C14.9756 0.0367165 14.9018 0.00976449 14.8249 0.00373499C14.7479 -0.0022945 14.6709 0.0128342 14.6021 0.0474437C14.5334 0.0820534 14.4756 0.134791 14.4354 0.199811C14.3951 0.264832 14.3739 0.339594 14.374 0.415809V4.80697L7.85092 0.0816034C7.78855 0.0367165 7.71478 0.00976449 7.6379 0.00373499C7.56095 -0.0022945 7.48386 0.0128342 7.41508 0.0474437C7.34636 0.0820534 7.28865 0.134791 7.24835 0.199811C7.20812 0.264832 7.18686 0.339594 7.187 0.415809V4.80697L0.663911 0.0816034C0.601545 0.0367162 0.527812 0.00976416 0.450885 0.00373487C0.373959 -0.0022944 0.296844 0.0128347 0.228091 0.0474448C0.159338 0.0820548 0.101633 0.134794 0.0613724 0.199815C0.021112 0.264836 -0.000130241 0.339599 6.00803e-07 0.415815V6.62039C0.000240814 6.6853 0.0159367 6.7493 0.0458206 6.8071C0.0757046 6.8649 0.118939 6.91496 0.172032 6.9533L6.88244 11.8144C6.94481 11.8592 7.01852 11.8862 7.09547 11.8922C7.17235 11.8982 7.2495 11.8831 7.31821 11.8485C7.387 11.8139 7.44471 11.7612 7.48494 11.6962C7.52524 11.6311 7.54643 11.5564 7.5463 11.4802V7.08896L14.0694 11.8144C14.1318 11.8593 14.2055 11.8862 14.2824 11.8923C14.3593 11.8983 14.4364 11.8832 14.5052 11.8486C14.5739 11.814 14.6316 11.7612 14.6719 11.6962C14.7121 11.6312 14.7332 11.5564 14.7331 11.4802V7.08876L21.2575 11.8144C21.3198 11.8593 21.3935 11.8862 21.4705 11.8923C21.5474 11.8983 21.6245 11.8832 21.6932 11.8486C21.762 11.814 21.8196 11.7612 21.8599 11.6962C21.9001 11.6312 21.9213 11.5564 21.9211 11.4802V5.27637C21.9209 5.21126 21.9051 5.14711 21.8751 5.08914C21.845 5.03116 21.8016 4.98099 21.7483 4.94268L15.0379 0.0816034Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_534_54646">
                <rect width="81" height="12" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
