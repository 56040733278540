import { useCallback } from "react";
import * as Base from "../base";
import * as Client from "modules/client";

export const ExitMessage = () => {
    const dispatch = Client.useDispatchContext();

    const handleClick = useCallback(() => {
        dispatch(new Client.Auth.RefreshAction());
    }, []);

    return (
        <Base.ButtonMessage source={"user"}>
            <Base.Button theme={"secondary"} onClick={handleClick}>
                See you
            </Base.Button>
        </Base.ButtonMessage>
    );
};
