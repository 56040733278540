import * as React from "react";
import * as Api from "./api";
import * as Auth from "./auth";
import { gtag } from "../utils/send-conversion";
import { State, StateContext, useStateContext } from "./state-context";
import * as Sentry from "@sentry/capacitor";
import LogRocket from "logrocket";
import {getCurrentConfig} from "../../app/config";

export { Api, Auth, State, StateContext, useStateContext };

export function withState<P = {}, SP = {}>(
    component: React.ComponentType<P & SP>,
    mapStateToProps: (state: State, props: P) => SP | false,
    fallback: React.ReactElement | null = null
): React.ComponentType<P> {
    const componentType: React.ComponentType<P> = (props) => {
        const state = useStateContext();
        const stateProps = mapStateToProps(state, props);
        // noinspection JSUnreachableSwitchBranches
        switch (stateProps) {
            case false:
                return fallback;
            case true:
                return React.createElement(component, props as P & SP);
        }
        return React.createElement(component, { ...props, ...stateProps });
    };
    componentType.displayName = `withState(${
        component.displayName || (component as any).name
    })`;
    return componentType;
}

export function withApi<P = {}>(
    component: React.ComponentType<P & { api: Api.Instance }>
): React.ComponentType<P> {
    return withState<P, { api: Api.Instance }>(component, ({ api }) => ({
        api,
    }));
}

export type Action = Auth.Action;
export type Dispatch = React.Dispatch<Action>;

export const DispatchContext = React.createContext<Dispatch>(() => undefined);
DispatchContext.displayName = "Client.DispatchContext";

export function useDispatchContext() {
    return React.useContext(DispatchContext);
}

export function Reducer(prevState: State, action: Action): State {
    console.log(`CLIENT DISPATCH`, action, prevState);
    switch (action.type) {
        case "auth":
        case "logout":
        case "login":
        case "refresh":
            return Object.freeze({
                ...prevState,
                ...Auth.Reducer(prevState, action),
            });
    }
}

export const Provider: React.FC = ({ children, ...props }) => {
    const [state, dispatch] = React.useReducer(Reducer, props, State);

    Api.useEffect(state, state.user);
    Auth.useEffect(state, state.api, dispatch);
    React.useEffect(() => {
        if (state.type !== "user") {
            return;
        }

        gtag({ user_id: state.user.id.toString() });

        Sentry.setUser({
            id: localStorage.getItem("capacitor:device-id") || undefined,
            phone: state.user.phone,
            username: state.user.firstName,
            email: state.user.email
        });

        if (getCurrentConfig().config.logRocket) {
            const { firstName, lastName, phone, status, email, id } = state.user;
            LogRocket.identify(id.toString(), {
                firstName: firstName || '',
                lastName: lastName || '',
                email: email || '',
                phone,
                status,
            });
        }

        return () => Sentry.setUser({ id: localStorage.getItem("capacitor:device-id") || undefined });
    }, [state.type, state.user]);

    return React.createElement(StateContext.Provider, {
        value: state,
        children: React.createElement(DispatchContext.Provider, {
            children,
            value: dispatch,
        }),
    });
};
Provider.displayName = "Client.Provider";
