import * as React from "react";
import * as Base from "modules/components/header";
import { Logo } from "modules/components/header/Logo";
import { LocalizedLink } from "app/locale";
import { IconHamburger } from "modules/components/Icons/IconHamburger";
import "./styles.scss";

export const Header: React.FC = React.memo(() => {
    return (
        <Base.Layout logo={<Logo type={"color"} />}>
            <LocalizedLink to={"/nav"} className={"menu-hamburger"}>
                <IconHamburger />
            </LocalizedLink>
        </Base.Layout>
    );
});
Header.displayName = "Chat.header";
