import { Contacts } from "./Contacts";
import "./styles.scss";

export const Layout = () => {
    return (
        <article>
            <h1>Contacts</h1>
            <p className="under-title">Get in touch</p>
            <Contacts />
        </article>
    );
};

export default Layout;
