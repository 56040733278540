import * as React from "react";
import { Message } from "../message";
import { Markdown } from "../../../../modules/components/markdown";

export interface LabelMessageProps {
    id: string;
    message: string;
}

export const LabelMessage: React.FC<LabelMessageProps> = ({ id, message }) => {
    return (
        <Message source="bot">
            <label className="wrap" htmlFor={id}>
                <div className="animated">
                    <Markdown content={message}/>
                </div>
            </label>
        </Message>
    );
};
