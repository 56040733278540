import * as Chat from "modules/chat";
import { Message } from "modules/chat";
import * as Client from "modules/client";
import * as React from "react";
import * as Base from "../base";
import * as State from "../state";

type CalculatorMessageProps = {
    submitMessage: Message | Message[];
    defaultTerm?: number;
    defaultAmount?: number;
} & State.MessageProps;

export const CalculatorMessage: React.FC<CalculatorMessageProps> = React.memo(
    ({ value, submitMessage, defaultTerm, defaultAmount }) => {
        const conditions = Client.Api.useConditions();
        const dispatch = Chat.useDispatchContext();

        React.useEffect(() => {
            if (conditions) {
                const preparedConditions = prepareConditions({
                    ...conditions,
                    defaultAmount,
                    defaultTerm,
                });

                const messages = createMessages(
                    [
                        new Chat.Calculator.CalculatorOnlyMessage(
                            preparedConditions
                        ),
                    ],
                    submitMessage
                );

                dispatch(new Chat.ReplaceAction(messages, value));
            }
        }, [dispatch, conditions]);

        return <Base.LoadMessage />;
    }
);
CalculatorMessage.displayName = "Calculator.CalculatorMessage";

type PrepareConditionsProps = {
    defaultTerm?: number;
    defaultAmount?: number;
} & Client.Api.Conditions<Client.Api.ConditionsItem>;

export const prepareConditions = (conditions: PrepareConditionsProps) => {
    const { term, amount, interestRate, defaultAmount, defaultTerm } =
        conditions;
    return {
        term: {
            ...term,
            step: 1,
            value: 0,
            limit: term.limit || term.max,
        },
        amount: {
            ...amount,
            step: 50,
            value:
                defaultAmount ||
                Math.round(((amount.limit || amount.max) * 0.8) / 100) * 100,
            limit: amount.limit || amount.max,
        },
        interestRate,
    };
};

const createMessages = (
    calculatorMessages: Message[],
    submitMessage: Message | Message[]
) => {
    if (Array.isArray(submitMessage)) {
        return [...calculatorMessages, ...submitMessage];
    }

    return [...calculatorMessages, submitMessage];
};
