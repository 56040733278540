import * as React from "react";
import * as Base from "../base";

export const InfoMessage: React.FC = () => {
    return (
        <Base.WrapMessage source="bot">
            <p>Choose your mobile operator</p>
       </Base.WrapMessage>
    );
};
