import * as React from "react";
import classNames from "classnames";
import { Context } from "./Context";
import { Title } from "./Title";
import { IconAccordionArrow } from "modules/components/Icons/IconAccordionArrow";
import "./styles.scss";

export interface ItemProps {
    id: string;
    title?: string | React.ReactElement;
    disabled?: boolean;
    icon?: string;
}

export const Item: React.FC<ItemProps> = React.memo((props) => {
    const context = React.useContext(Context);
    const isDisabled = props.disabled || !props.children;
    const isActive = props.id === context.active;
    const handleClick = React.useCallback(() => {
        context.setActive(isDisabled || isActive ? undefined : props.id);
    }, [isDisabled, context.active, context.setActive, props.id]);
    const title =
        "string" === typeof props.title ? (
            <Title text={props.title} />
        ) : (
            props.title
        );

    return (
        <div className={classNames("accordion-item", isActive && "active")}>
            <div className="accordion-heading" onClick={handleClick}>
                <div className="wrap">
                    {props.icon && <i className={`icon icon_${props.icon}`} />}
                    {title}
                </div>
                <IconAccordionArrow />
            </div>
            {context.active === props.id && (
                <div className="accordion-body">{props.children}</div>
            )}
        </div>
    );
});
Item.displayName = "Accordion.Item";
