import * as Client from "modules/client";
import * as React from "react";
import { sendConversion } from "modules/utils/send-conversion";
import * as Base from "../base";

export const CompleteMessage: React.ComponentType<{ value: any }> =
    Client.withState<{ value: any }, { number: number, requestId: number }>(
        ({ number, requestId }) => {
            const dispatch = Client.useDispatchContext();

            React.useEffect(() => {
                sendConversion("credit", number, requestId);
                const id = setTimeout(() => {
                    dispatch(new Client.Auth.RefreshAction());
                }, 5000);
                return () => clearTimeout(id);
            }, [dispatch, number]);

            return (
                <Base.WrapMessage source="bot">
                    <p>Ready! While the money is being transferred to your card, I will show your personal account</p>
                </Base.WrapMessage>
            );
        },
        (state) =>
            state.type === "user" && {
                number: state.user.stats.outpayment.count,
                requestId: state.request.id,
            }
    );
