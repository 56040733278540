// tslint:disable:max-line-length
export const IconPencil = () => (
    <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_pencil"
    >
        <path
            className="icon-fill"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0795 1.98478C16.5188 1.54544 17.2312 1.54544 17.6705 1.98478L22.0152 6.32944C22.4545 6.7688 22.4545 7.48111 22.0152 7.92042L8.41228 21.5233C8.39091 21.5453 8.36817 21.5659 8.34426 21.5851C8.25 21.6609 8.14162 21.7105 8.02889 21.7341C8.01084 21.7379 7.99261 21.741 7.97414 21.7434L2.34913 22.4934C2.11758 22.5243 1.88486 22.4455 1.71967 22.2803C1.5545 22.1152 1.47571 21.8825 1.50659 21.6509L2.25546 16.0343C2.2749 15.8736 2.3463 15.718 2.46967 15.5946L16.0795 1.98478ZM18.4393 9.37495L7.87495 19.9393L4.06063 16.125L14.625 5.56064L18.4393 9.37495ZM19.5 8.31431L20.6894 7.12495L16.875 3.3106L15.6856 4.49996L19.5 8.31431ZM3.54284 17.7285L3.12305 20.877L6.2715 20.4572L3.54284 17.7285Z"
            fill="#1A4C6C"
        />
    </svg>
);
