export const addCodeToHtml = (code: string, tag: 'html' | 'body' | 'head' = 'html'): void => {
    const documentFragment: DocumentFragment = document
        .createRange()
        .createContextualFragment(code);
    const elements: HTMLCollectionOf<any> = document.getElementsByTagName(tag);
    const element = elements[0];

    if(!element || typeof element !== 'object' || !('append' in elements[0])) {
        return;
    }

    document.getElementsByTagName(tag)[0].append(documentFragment);
};
