import * as ErrorPage from "modules/components/error-page";

export const Layout = () => {
    return (
        <ErrorPage.Layout
            errorNumber="500"
            underTitle="
            I'm on a technical break, but you can always
            connect with us."
        >
            <p>You can make a new application in your personal account</p>
        </ErrorPage.Layout>
    );
};

export default Layout;
