import {compose} from "../i18n";

export type LocalizePrimitive = string | number | undefined | null

export type LocalizeTargetKey =
    LocalizePrimitive
    | LocalizeTarget
    | (LocalizePrimitive | LocalizeTarget | LocalizeTargetKey)[]

export interface LocalizeTarget {
    [key: string]: LocalizeTargetKey
}

export type LocalizeObject = <Target>(target: Target, exclude?: string[], base?: string) => Target

const nextBase = (base: string, next: string | number): string => {
    if (base === "") {
        if (typeof next === "number") {
            return base;
        }
        
        return next.toString();
    }
    
    return compose(base, next.toString());
};

export const localizeObject: LocalizeObject = <Target>(
    target: Target,
    exclude: string[] = [],
    base: string = ""
) => {
    if (target == null) {
        return target as unknown as Target;
    }
    
    if (typeof target !== "object") {
        return base as unknown as Target;
    }
    
    if (Array.isArray(target)) {
        return target.map((item, index) => {
            return localizeObject(item, exclude, nextBase(base, index));
        }) as unknown as Target;
    }
    
    const result: LocalizeTarget = {};
    
    Object.entries(target).forEach(([key, value]) => {
        if (!exclude.includes(key)) {
            result[key] = localizeObject(value, exclude, nextBase(base, key));
        } else {
            result[key] = value;
        }
    });
    
    return result as unknown as Target;
};
