import { useEffect, useState } from "react";
import { LocalizedLink } from "app/locale";
import { Data } from "./Data";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Client from "modules/client";
import { Details } from "modules/chat/bank-id";
import { IconArrow } from "modules/components/Icons/IconArrow";

export const Layout = () => {
    const { api } = Client.useStateContext();
    const history = useHistory();
    const state = Client.useStateContext();
    const [data, setData] = useState<Details | undefined>(undefined);
    const isUser = state.type !== "guest";

    useEffect(() => {
        if (!isUser) {
            history.push("/welcome");
        }
        const cancelToken = axios.CancelToken.source();
        api.with({ cancelToken: cancelToken.token })
            .user.mono.get()
            .then((response) => {
                if (!response) {
                    return history.push("/forbidden");
                }

                switch (response.status) {
                    case "AUTH":
                        history.push("/registration/mono/auth");
                        return;
                    case "PROCESSING":
                        history.push("/registration/mono/processing");
                        return;
                    case "FAILED":
                        history.push("/chat");
                        return;
                    case "AVAILABLE":
                        setData(response.details);
                        return;
                    default:
                        console.error(`[Registratio\\Mono\\Details] Unknown status for Mono Account: ${(response as any).status}`);
                }
            });
        return () => {
            cancelToken.cancel();
        };
    }, [setData, isUser]);

    useEffect(() => {
        return () => {
            window.location.reload();
        };
    }, []);

    return (
        <>
            <h1>Bank Account</h1>
            <p className="under-title">Grant access to your bank accounts</p>
            <Data data={data} />
            <p>
                Confirm your bank account in mono with one click and complete
                the authorization. After confirming the data registration on
                KoboGo will be completed.
            </p>
            <div className="btn-container">
                <LocalizedLink to={"/chat"} className="btn btn_white">
                    <span>Confirm data</span>
                    <IconArrow />
                </LocalizedLink>
            </div>
        </>
    );
};

export default Layout;
