import logoUrl from "resources/logo.jpg";
import { useEffect } from "react";
import {useLocation} from "react-router-dom";

export const useSchema = (title: string) => {
    const location = useLocation();
    const pageUrl: string = `https://lovilave.com.ua${location.pathname}`;
    
    useEffect(() => {
        const scriptGraph = document.createElement("script");
        scriptGraph.type = "application/ld+json";
        scriptGraph.innerHTML = JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
                {
                    "@type": "WebSite",
                    "@id": "https://lovilave.com.ua",
                    "url": "https://lovilave.com.ua",
                    "name": "LoviLave"
                },
                {
                    "@type": "WebPage",
                    "@id": pageUrl,
                    "url": pageUrl,
                    "inLanguage": "ru_RU",
                    "name": title,
                    "datePublished": "2020-10-15",
                    "dateModified": "2020-10-15",
                    "isPartOf": {
                        "@id": "https://lovilave.com.ua"
                    }
                }
            ]
        });
        document.head.appendChild(scriptGraph);
        return () => {
            document.head.removeChild(scriptGraph);
        };
    }, []);
    
    useEffect(() => {
        const scriptContactPoint = document.createElement("script");
        scriptContactPoint.type = "application/ld+json";
        scriptContactPoint.innerHTML = JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://lovilave.com.ua",
            "logo": logoUrl,
            "contactPoint": [
                {
                    "@type": "ContactPoint",
                    "url": "https://leanloan.com.ua/",
                    "email": "hello@lovilave.com.ua",
                    "contactType": "customer service"
                }
            ]
        });
        document.head.appendChild(scriptContactPoint);
        return () => {
            document.head.removeChild(scriptContactPoint);
        };
    }, []);
};
