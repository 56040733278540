import {AxiosInstance, AxiosResponse} from "axios";

export type ConfigurationValue = {
    phones: {
        collection: string[],
        support: string[],
    },
}

export const Api = (endpoint: AxiosInstance) => {
    return {
        get: (): Promise<ConfigurationValue> => {
            return endpoint.get<ConfigurationValue, AxiosResponse<ConfigurationValue>>("/v3/configuration")
                .then((response) => response.data);
        }
    } as const;
}
