import { createElement, FC, HTMLProps } from "react";

import { BuildTag } from "modules/utils/build-tag";
import { Environment } from "modules/utils/environment";

export interface ContainerProps {
    buildTag: BuildTag;
    environment: Environment;
    html?: string;
}

export type ContainerAttributes = {
    version: string;
    environment: string;
} & ({ debug: "0" } | {});
export const ContainerAttributes: (props: ContainerProps) => ContainerAttributes =
    ({ buildTag: { name, version }, environment: { name: environment, debug } }) => debug
        ? ({ name, version, environment, debug: "1" })
        : ({ name, version, environment });

export const ContainerComponent: FC<ContainerProps> = ({ html, ...props }) => {
    const childProps: HTMLProps<HTMLElement> & ContainerAttributes = ContainerAttributes(props);
    if (html) {
        childProps.dangerouslySetInnerHTML = { __html: html };
    }

    return createElement(
        "bobra-site",
        childProps
    );
};
ContainerComponent.displayName = "Container";

export function ContainerElement(props: ContainerProps & {
    parentNode?: HTMLElement,
}): HTMLElement {
    const { parentNode = document.body } = props;
    const attributes = ContainerAttributes(props);

    let element: HTMLElement | null = parentNode.querySelector(`bobra-site`);
    if (element === null) {
        element = document.createElement("bobra-site");
        for (const [ name, value ] of Object.entries(attributes)) {
            element.setAttribute(name, value);
        }
        parentNode.appendChild(element);
    }

    return element;
}
