import {AxiosInstance, AxiosResponse} from "axios";
import {ContactPayload} from "../../../app/chat/share-contacts/mock.type";

export enum ContactsStateType {
    exists = "exists",
    required = "required",
    optional = "optional",
}

export type ContactsState = {
    state: ContactsStateType,
    details: {
        count: number,
    },
}

// TODO вынести в capacitorApi аналогично guestApi и UserApi
export const Api = (endpoint: AxiosInstance) => ({
    sendContacts: async (payload: ContactPayload[]): Promise<void> => {
        const response = await endpoint.put<any>(
            "/v3/mobile/contact-list",
            {
                ContactList: {
                    records: [
                        ...payload
                    ]
                },
            }
        );

        return;
    },
    getContacts: async (): Promise<ContactsState> => {
        return endpoint.get<ContactsState>(
            "/v3/mobile/contact-list"
        )
            .then((res: AxiosResponse<ContactsState>) => res.data);
    },
});

