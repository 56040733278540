import * as React from "react";
import * as Client from "modules/client";
import * as Base from "../base";
import * as Chat from "modules/chat";
import * as State from "app/chat/state";
import {useHistory} from "react-router-dom";
import axios, {AxiosError, CancelTokenSource} from "axios";

const maxAttempts = 5;
const attemptIntervalSeconds = 15;

export const LoadCompleteMessage: State.Message<
    State.MessageProps<Chat.Card.LoadCompleteMessage>
> = () => {
    const history = useHistory();
    const [attempts, setAttemts] = React.useState<number>(0);
    const {api} = Client.useStateContext();
    const dispatch = Chat.useDispatchContext();

    React.useEffect(() => {

    }, [history.location.pathname]);

    React.useEffect(() => {
        if (attempts > maxAttempts) {
            dispatch(new Chat.FlushAction([
                new Chat.Card.FailureMessage,
                new Chat.Card.ConfirmMessage,
            ]));
            return;
        }
        let cancelToken: CancelTokenSource = axios.CancelToken.source();
        const timeoutId = setTimeout(() => {
            api.with({cancelToken: cancelToken.token})
                .user.payment.active()
                .then((card) => {
                    history.replace("/chat");
                    setAttemts(attempts + 1);
                    dispatch(new Chat.FlushAction([
                        new Chat.Card.CompleteMessage(card),
                        new Chat.Card.CompleteButtonMessage,
                    ]))
                })
                .catch((error: Error | AxiosError) => {
                    if (axios.isCancel(error)) {
                        return;
                    }
                    setAttemts(attempts + 1);
                    if (("response" in error) && error.response?.status === 404) {
                        return;
                    }
                    history.push("/failure");
                    return Promise.reject(error);
                })
        }, attemptIntervalSeconds * 1000);
        return () => clearTimeout(timeoutId);
    }, [attempts]);

    return <>
        <Base.WrapMessage source="bot">
            Please hang tight! We're securely processing your transaction information. This may take a few moments. Thank you for your patience.
        </Base.WrapMessage>
        <Base.LoadMessage/>
    </>;
}
