import { Capacitor } from "@capacitor/core";
import { AppUpdate, AppUpdateAvailability, AppUpdateInfo } from "@capawesome/capacitor-app-update";

export async function initUpdateApp(): Promise<void> {
    // ios temporary ignored
    if (Capacitor.getPlatform() !== 'android') {
        return;
    }

    const appUpdateInfo: AppUpdateInfo = await AppUpdate.getAppUpdateInfo();

    if (appUpdateInfo.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE){
        return;
    }

    if (appUpdateInfo.immediateUpdateAllowed) {
        await AppUpdate.performImmediateUpdate();
    } else if (appUpdateInfo.flexibleUpdateAllowed) {
        await AppUpdate.startFlexibleUpdate();
    }
}
