import * as Chat from "modules/chat";
import * as State from "../state";
import * as Client from "modules/client";
import { FormGroup } from "./form-group";
import { useCalc } from "modules/hooks/use-calc";
import { IconCard } from "modules/components/Icons/IconCard";
import { useOpenState } from "@horat1us/react-hooks";
import axios from "axios";
import classNames from "classnames";
import "./calc.scss";
import { Capacitor } from "@capacitor/core";

export const Menu = () => {
    const { api } = Client.useStateContext();
    const dispatch = Chat.useDispatchContext();

    const handleRequest = () => {
        const cancelToken = axios.CancelToken.source();
        api.with({ cancelToken: cancelToken.token })
            .user.data.validate(1)
            .then(async ([error]) => {
                if (error === undefined) {
                    const data = await api.user.data.get();
                    return dispatch([
                        new Chat.DeleteAction(),
                        new Chat.ReplaceAction([
                            new Chat.Registration.CompleteMessage(
                                data.schema,
                                data.values
                            ),
                            new Chat.Registration.CompleteFormMessage(),
                        ]),
                    ]);
                }
                const {
                    attribute: { value, schema, name: attribute },
                    text,
                } = error;
                return dispatch(
                    new Chat.ReplaceAction([
                        new Chat.Registration.FormMessage(
                            attribute,
                            text,
                            value,
                            schema
                        ),
                    ])
                );
            });

        return () => cancelToken.cancel();
    };

    return (
        <button
            type="button"
            className="btn btn_white calculator-submit"
            id="calculator-submit"
            onClick={handleRequest}
        >
            <IconCard />
            <span>Get cash!</span>
        </button>
    );
};

const Info = () => {
    const [isOpen, handleOpenChange] = useOpenState();
    return (
        <>
            {/* TODO: change text */}
            <p className="info">
                Процентна ставка від 0,01 до 3,98% на день Реальна річна
                процентна ставка, відсотки річних від 3,711 до 1418017.60%
                Максимальна сума кредиту від 500 грн до 10 000 грн
            </p>
            <div className="details">
                <p
                    className={classNames(
                        "details__heading",
                        isOpen && "is-active"
                    )}
                    onClick={handleOpenChange}
                >
                    <span>
                        {isOpen
                            ? "Hide APR Information"
                            : "Read more APR Information"}
                    </span>
                    <i className="icon icon_arrow-circle" />
                </p>
                {isOpen && (
                    <div className="details__body">
                        {/* TODO: change text */}
                        <p>Some text</p>
                    </div>
                )}
            </div>
        </>
    );
};

export const FormMessage: State.Message<
    State.MessageProps<Chat.Calculator.FormMessage>
> = (props) => {
    const conditions = useCalc(props.value);

    return (
        <div
            className={
                Capacitor.isNativePlatform() ? "calc calc_capacitor" : "calc"
            }
        >
            <div className="agreement-term">
                <span>Term of the agreement</span>
                <ul>
                    <li>90</li>
                    <li className="locked">120</li>
                    <li className="locked">180</li>
                </ul>
            </div>
            <FormGroup
                message={props.value}
                notice="max amount of the first loan"
                label="Loan amount"
                field="amount"
                messageRender={(conditions) =>
                    new Chat.Calculator.FormMessage(conditions)
                }
            />
            <FormGroup
                message={props.value}
                notice={`until ${conditions.returnDate.format("DD.MM.YYYY")} ${
                    conditions.days[conditions.returnDate.day()]
                }`}
                label="Exemption period, days"
                field="term"
                messageRender={(conditions) =>
                    new Chat.Calculator.FormMessage(conditions)
                }
            />
            <div className="calc-actions">
                <p className="calc-total">
                    <span className="calc-total__label">Total payment:</span>
                    <span className="calc-total__value with-currency">
                        {conditions.returnAmount}
                    </span>
                </p>
                <Menu />
                <Info />
            </div>
        </div>
    );
};
FormMessage.displayName = "Calculator.FormMessage";
