import { useLocale } from "app/locale";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import { useCallback } from "react";
import * as Base from "../base";
import * as State from "../state";
import {AxiosResponse} from "axios";

export const SendMessage: State.Message<
    State.MessageProps<Chat.Agreement.AgreementSendMessage>
> = ({ value }) => {
    const { t } = useLocale("registration");

    const handleRequest = useCallback((api: Client.Api.Instance) => {
        return api.user.agreement.send();
    }, []);

    const handleResponse = useCallback(
        async (response: Promise<AxiosResponse>) => {
            const {status} = await response;

            /** https://github.com/ncash-ng/personal-area/issues/27 */
            if (status === 205) {
                location.reload();
                return;
            }

            return new Chat.ReplaceAction(
                [
                    new Chat.TextMessage("Confirm", "user"),
                    new Chat.TextMessage(
                        "An SMS code has been sent to you, it will arrive in 120 seconds.",
                        "bot",
                        false,
                        ""
                    ),
                    new Chat.TimeoutMessage(
                        120,
                        new Chat.Agreement.AgreementResendMessage()
                    ),
                    new Chat.Agreement.AgreementConfirmFormMessage(),
                ],
                value
            );
        },
        [value]
    );

    return (
        <Base.DelaySubmitMessage
            value={undefined}
            onRequest={handleRequest}
            onFinish={handleResponse}
        />
    );
};
