import "./styles.scss";

export const Layout = () => {
    return (
        <article>
            <h1>Debug info page</h1>
            <div className="container">
                {Object.entries(process.env).map(([key, value], index) => (
                    <div className="block" key={key + index}>
                        <p className="block__title">process.env.{key}:</p>
                        <p className="block__value">{value}</p>
                    </div>
                ))}
                <div className="block">
                    <p className="block__title">capacitor:install-referrer: </p>
                    <p className="block__value">
                        {localStorage.getItem("capacitor:install-referrer")}
                    </p>
                </div>
                <div className="block">
                    <p className="block__title">capacitor:device-info: </p>
                    <p className="block__value">
                        {localStorage.getItem("capacitor:device-info")}
                    </p>
                </div>
                <div className="block">
                    <p className="block__title">capacitor:device-id: </p>
                    <p className="block__value">
                        {localStorage.getItem("capacitor:device-id")}
                    </p>
                </div>
                <div className="block">
                    <p className="block__title">capacitor:FCM-token: </p>
                    <p className="block__value">
                        {localStorage.getItem("capacitor:token")}
                    </p>
                </div>
            </div>
        </article>
    );
};

export default Layout;
