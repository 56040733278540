import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as Base from "../base"
import * as State from "../state"
import { useCallback } from "react";

export const LoadMessage: State.Message<State.MessageProps<Chat.DirectDebit.LoadMessage>> =
    (props) => {
        const handleRequest = useCallback(
            async (
                api: Client.Api.Instance
            ): Promise<Client.Api.DirectDebit.CreateResponse> => {
                return await api.user.directDebit.create();
            },
            []
        );

        const handleFinish = useCallback(async (response: Client.Api.DirectDebit.CreateResponse): Promise<Chat.Action | void> => {
            if (response.status === "failed") {
                return new Chat.FlushAction(
                    [
                        new Chat.DirectDebit.InitMessage,
                        new Chat.TextMessage(response.message || 'Something went wrong, please try again later'),
                        new Chat.DirectDebit.ConfirmMessage
                    ]
                );
            }

            return new Chat.FlushAction(
                [
                    new Chat.DirectDebit.WidgetMessage(
                        response.redirectUrl
                    ),
                ]
            );
        }, []);

        return <Base.DelaySubmitMessage
            delayMs={5000}
            value={props.value}
            onRequest={handleRequest}
            onFinish={handleFinish}
        />
    };
