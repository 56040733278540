import * as Chat from "modules/chat";
import * as State from "../state";
import { CompleteFormMessage } from "./complete-form-message";
import { CompleteMessage } from "./complete-message";
import { CompleteSubmitMessage } from "./complete-submit-message";
import { FormMessage } from "./form-message";
import { FormSubmitMessage } from "./form-submit-message";
import { IntroMessage } from "./intro-message";
import { UpdateTermMessage } from "./update-term-message";
import { ValidateMessage } from "./validate-message";
import { ValidateTermMessage } from "./validate-term-message";
import { WelcomeMessage } from "./welcome-message";
import { CompleteInitMessage } from "./complete-init-message";

export type MessageComponentProps<
    TMessage extends Chat.Registration.Message = Chat.Registration.Message
> = State.MessageProps<TMessage>;
export type MessageComponent<
    TProps extends MessageComponentProps = MessageComponentProps
> = State.Message<TProps>;

export function MessageComponentFactory(
    type: string
): State.Message | undefined {
    switch (type) {
        case "reg:welcome":
            return WelcomeMessage;
        case "reg:intro":
            return IntroMessage;
        case "reg:form":
            return FormMessage;
        case "reg:validate":
            return ValidateMessage;
        case "reg:form-submit":
            return FormSubmitMessage;
        case "reg:complete":
            return CompleteMessage;
        case "reg:complete-submit":
            return CompleteSubmitMessage;
        case "reg:complete-form":
            return CompleteFormMessage;
        case "reg:validate-term":
            return ValidateTermMessage;
        case "reg:update-term":
            return UpdateTermMessage;
        case "reg:complete-init":
            return CompleteInitMessage;
    }
    return undefined;
}
