import {WrapMessage} from "app/chat/base/wrap-message";
import {ButtonEdit} from "app/chat/button-edit";
import * as State from "app/chat/state";
import * as Chat from "modules/chat";
import * as React from "react";


type EditableMessageProps = State.MessageProps<Chat.EditableMessage>

export const EditableMessage: React.FC<EditableMessageProps> = ({value: {content, editAction}}) => {
    const dispatch = Chat.useDispatchContext();
    
    const handleClick = React.useCallback(() => {
        dispatch(editAction)
    }, [editAction, dispatch])
    
    return (
        <WrapMessage source="user" at={new Date().toISOString()}>
            <p>{content}</p>
            <ButtonEdit onClick={handleClick}/>
        </WrapMessage>
    );
};
