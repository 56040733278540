import {LocalizedLink, useLocale} from "app/locale";

export const ButtonLogout = () => {
    const {t} = useLocale('nav-list');
    return (
        <LocalizedLink to="/logout" id="exit-btn" className="btn_exit">
            <span>{t('leave')}</span>
            <i className="icon icon_exit"/>
        </LocalizedLink>
    );
};
