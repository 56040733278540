import { useLocale } from "app/locale";
import * as Base from "../base";
import * as State from "../state";
import { useIsMobile } from "modules/hooks/is-mobile";
import * as Chat from "modules/chat";

export const IntroMessage: State.Message<
    State.MessageProps<Chat.PromoPage.IntroMessage>
> = (props) => {
    const { t } = useLocale("main");
    const { currentLanguage } = useLocale();

    if (useIsMobile()) {
        return null;
    }

    return (
        <Base.WrapMessage source="bot">
            {props.value.welcomeRu && props.value.welcomeUa ? (
                currentLanguage === "ru" ? (
                    props.value.welcomeRu
                ) : (
                    props.value.welcomeUa
                )
            ) : (
                <>
                    <p>{t("intro-message.title")}</p>
                    <p>{t("intro-message.text")}:</p>
                </>
            )}
        </Base.WrapMessage>
    );
};
