import {useLang} from "app/locale/hooks/use-lang";
import * as React from "react";
import {useLocale} from "../hooks";
import {languageShortName} from "modules/utils/language-short-name";

export const LocalizedProvider: React.FC = ({children}) => {
    const {currentLanguage, routeMatch, changeLanguage} = useLocale();
    
    useLang(currentLanguage);
    
    React.useEffect(() => {
        changeLanguage(languageShortName(routeMatch));
    }, [routeMatch]);
    
    return <>{children}</>;
};
