import { Capacitor } from "@capacitor/core";
import { Adjust, AdjustConfig, AdjustEnvironment, AdjustEvent } from "@awesome-cordova-plugins/adjust";
import { getCurrentConfig } from "../../app/config";
import * as Sentry from "@sentry/capacitor";


/** Read more https://github.com/adjust/cordova_sdk */
export class AdjustSdk {
    public static init(): void {
        const adjustAppToken: string | undefined = getCurrentConfig().config.adjustAppToken;

        if (!Capacitor.isNativePlatform() || !adjustAppToken) {
            return;
        }

        try {
            const config: AdjustConfig = new AdjustConfig(adjustAppToken, getCurrentConfig().environment === 'production' ? AdjustEnvironment.Production : AdjustEnvironment.Sandbox);
            Adjust.create(config);
        } catch (e) {
            console.warn(e);
            // Ignore Error
        }
    }

    public static sendEvent(eventToken: string): void {
        const adjustAppToken: string | undefined = getCurrentConfig().config.adjustAppToken;

        if (!Capacitor.isNativePlatform() || !adjustAppToken || !eventToken) {
            return;
        }

        try {
            const adjustEvent: AdjustEvent = new AdjustEvent(eventToken);
            Adjust.trackEvent(adjustEvent);
        } catch (error) {
            console.warn(error);
            Sentry.captureMessage(
                `Error in sendAdjustSdkEvent: ${eventToken}`,
                {
                    level: 'error',
                    extra: {error}
                }
            );
        }
    }

    public static setPushToken(pushToken: string): void {
        const adjustAppToken: string | undefined = getCurrentConfig().config.adjustAppToken;

        if (!Capacitor.isNativePlatform() || !adjustAppToken || !pushToken) {
            return;
        }

        Adjust.setPushToken(pushToken);
    }
}
