import * as Chat from "modules/chat";
import { IconCard } from "modules/components/Icons/IconCard";
import { ReplaceAction } from "modules/chat";
import { DebtInfo } from "./debt-info";
import { useEffect, useState } from "react";

export const EditMenuMessage = () => {
    const dispatch = Chat.useDispatchContext();
    const [currentAmount, setCurrentAmount] = useState<number>(0);
    const calcData = JSON.parse(localStorage.getItem("ll.calc") || "{}");

    useEffect(() => {
        setCurrentAmount(calcData[1]);
    }, [setCurrentAmount, calcData]);

    const handleClick = () => {
        localStorage.setItem("firstCalculator.done", "1");
        dispatch(new ReplaceAction([new Chat.Calculator.FormSubmitMessage()]));
    };

    return (
        <div className="calc-submit">
            <button
                type="button"
                className="btn calculator-submit"
                id="calculator-submit"
                onClick={handleClick}
                disabled={currentAmount > 0 ? false : true}
            >
                <IconCard />
                <span>Get cash!</span>
            </button>
            <DebtInfo />
        </div>
    );
};
