export function plural(num: number, title: "years" | "days" | "months") {
    let value: string[];

    switch (title) {
        case "years":
            value = ["year", "years"];
            break;
        case "days":
            value = ["day", "days"];
            break;
        case "months":
            value = ["month", "months"];
    }

    return num + " " + value[num === 1 ? 0 : 1];
}
