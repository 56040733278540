import * as React from "react";
import * as Client from "modules/client";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { IconTrash } from "modules/components/Icons/IconTrash";

const confirmMessage =
    "Do you really want to delete your account? Once deleted, you will not be able to borrow in our app, nor will you be able to view your loan history in our app";

export const DeleteAccountButton = Client.withApi<{}>(({ api }) => {
    const history = useHistory();
    const dispatch = Client.useDispatchContext();
    const [isSending, setIsSending] = React.useState<boolean>(false);
    const onClick = async () => {
        if (!window.confirm(confirmMessage)) {
            history.push("/dashboard");
            return;
        }
        setIsSending(true);
    };
    React.useEffect(() => {
        if (!isSending) {
            return;
        }
        const cancelToken = axios.CancelToken.source();
        api.with({
            cancelToken: cancelToken.token,
        })
            .user.account.delete()
            .then((response) => {
                if (response.status !== "success") {
                    window.alert(response.message);
                    history.push("/dashboard");
                    return;
                }
                window.alert(response.message);
                dispatch(new Client.Auth.LogoutAction());
                history.push("/");
            });
        return () => {
            cancelToken.cancel();
        };
    }, [isSending]);
    return (
        <button
            onClick={onClick}
            className="btn btn_white"
            disabled={isSending}
        >
            <span>Delete My Account</span>
            <IconTrash />
        </button>
    );
});
