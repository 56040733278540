import * as ErrorPage from "modules/components/error-page";

export const Layout = () => {
    return (
        // TODO: change texts
        <ErrorPage.Layout errorNumber={"404"} underTitle="There's nothing here">
            <p>
                But I think I know where to get money on the card in just 5
                minutes!
            </p>
        </ErrorPage.Layout>
    );
};

export default Layout;
