import * as React from "react";
import InputMask, {InputState, Props as InputMaskProps} from "react-input-mask";

export type PhoneInputDefaultProps = Pick<InputMaskProps, "mask" | "alwaysShowMask" | "beforeMaskedValueChange" | "inputMode">;
export const PhoneInputDefaultProps: PhoneInputDefaultProps = Object.freeze({
    mask: "+234 999 999 9999",
    alwaysShowMask: false,
    inputMode: "tel",
});

export type PhoneInputProps = Omit<InputMaskProps, keyof PhoneInputDefaultProps>;
export const PhoneInput = React.forwardRef<InputMask, PhoneInputProps>(
    (props, ref) => {
        return (
            <InputMask maskChar={null} ref={ref} {...props} {...PhoneInputDefaultProps}/>
        );
    }
);
