import * as React from "react";
import {useCallback} from "react";
import * as Client from "modules/client";
import * as Chat from "modules/chat";
import * as Base from "../base";
import * as State from "../state";

export const ResendOptionsLoadMessage: State.Message<State.MessageProps<Chat.SignUp.ResendOptionsLoadMessage>> =
    (props) => {
        const handleRequest = useCallback(
            async (
                api: Client.Api.Instance
            ): Promise<Array<Client.Api.Sign.RequestChannel>> => {
                return await api.guest.sign.channels();
            },
            []
        );

        const handleFinish = useCallback(async (response: Array<Client.Api.Sign.RequestChannel>): Promise<Chat.Action | void> => {
            return new Chat.ReplaceAction(
                [
                    new Chat.SignUp.ResendOptionsMessage(props.value.phone, response)
                ]
            );
        }, []);

        return <Base.DelaySubmitMessage
            delayMs={1000}
            value={props.value}
            onRequest={handleRequest}
            onFinish={handleFinish}
        />
    }

