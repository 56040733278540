import * as React from "react";

type InputRangeProps = React.HTMLProps<HTMLInputElement> &
    Required<Pick<React.HTMLProps<HTMLInputElement>, "value" | "min" | "max">>;

export const InputRange: React.FC<InputRangeProps> = (props) => {
    let progress = Math.round(
        ((+props.value - +props.min) * 100) / (+props.max - +props.min)
    );
    progress > 95 && (progress = 95);
    const TypeRange = {
        background: `-webkit-linear-gradient(left, white 0%, white ${progress}%, rgba(255,255,255,.2) ${progress}%, rgba(255,255,255,.2) 100%)`,
    };
    return (
        <>
            <input type="range" {...props} style={TypeRange} />
            <div className="range__glow" style={{ width: `${progress}%` }} />
        </>
    );
};
