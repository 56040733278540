import * as Base from "../base";
import {useLocale} from "app/locale";

export const WelcomeMessage = () => {
    const {t} = useLocale("registration");

    return (
        <Base.Message source={"bot"}>{t("restructuring-enter-sms")}</Base.Message>
    );
};
