import * as Credit from "../client/api/credit";
import * as Client from "../client";

export class RestructuringWelcomeMessage {
    public readonly type = "restructuring:welcome";
}

export class RestructuringSendMessage {
    public readonly type = "restructuring:send";
}

export class RestructuringOpenMessage {
    public readonly type = "restructuring:open";
}

export class RestructuringConfirmRequestMessage {
    public readonly type = "restructuring:confirm-request";
}

export class RestructuringConfirmFormMessage {
    public readonly type = "restructuring:confirm-form";
}

export class RestructuringConfirmSubmitMessage {
    public readonly type = "restructuring:confirm-submit";

    public constructor(
        public readonly code: string,
    ) {
    }
}

export class RestructuringRefuseInfoMessage {
    public readonly type = "restructuring:refuse-info";
}

export class RestructuringRefuseFormMessage {
    public readonly type = "restructuring:refuse-form";
}

export class RestructuringRefuseSendMessage {
    public readonly type = "restructuring:refuse-send";
}

export class RestructuringExitMessage {
    public readonly type = "restructuring:exit";
}

export class RestructuringResendMessage {
    public readonly type = "restructuring:resend";
    public readonly time: string = new Date().toISOString();
}

export class RestructuringCompleteMessage {
    public readonly type = "restructuring:complete";
}

export type Message =
    RestructuringWelcomeMessage |
    RestructuringOpenMessage |
    RestructuringConfirmRequestMessage |
    RestructuringConfirmSubmitMessage |
    RestructuringConfirmFormMessage |
    RestructuringRefuseInfoMessage |
    RestructuringRefuseFormMessage |
    RestructuringResendMessage |
    RestructuringCompleteMessage |
    RestructuringSendMessage |
    RestructuringRefuseSendMessage |
    RestructuringExitMessage;
