import {AxiosInstance, AxiosResponse} from "axios";


export type JobStatus = 'Approved' | 'Rejected' | 'Error' | 'Initiated' | 'Pending' | 'Inconclusive';

export type Job = {
    id: number,
    status: JobStatus,
}

export type JobResponse = {
    job?: Job,
    isSandbox?: boolean,
};

export type WidgetConfig = {
    token: string;
    product: "biometric_kyc", // others also supported but we don't need it
    environment: "live" | "sandbox",
    callback_url: string,
    partner_details: {
        partner_id: string,
        name?: "Kobogo",
        logo_url?: string,
        policy_url?: string,
        theme_color?: string,
    },
    id_selection: {
        [k: string]: string[],
    },
    consent_required: {
        [k: string]: string[],
    },
    previewBVNMFA?: boolean,
}

export type WidgetResponse = {
    job: Job,
    config: WidgetConfig,
    widgetUrl: string,
}

export type MockDecision = 'Approved' | 'Rejected';
export type MockResponse = {
    job: Job,
    // debug information
    transaction: {
        id: number,
    }
}

export const Api = (endpoint: AxiosInstance) => {
    return {
        getWidget: async (): Promise<WidgetResponse> => {
            const response = await endpoint.post<WidgetResponse, AxiosResponse<WidgetResponse>>(
                "/v3/smile-id/widget"
            );
            return response.data;
        },
        getJob: async (id?: number): Promise<JobResponse> => {
            const response = await endpoint.get(
                "/v3/smile-id/job", {
                    params: { id, },
                },
            );
            return response.data;
        },
        createMock: async (decision: MockDecision): Promise<MockResponse> => {
            const response = await endpoint.post(
                "/v3/smile-id/mock", {
                    Mock: {
                        decision,
                    },
                }
            );
            return response.data;
        },
    } as const;
};
