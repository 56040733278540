import {AxiosInstance} from "axios";
import {Conditions} from "./conditions";
import * as PromoPage from "./promo-page";
import * as Configuration from "./configuration";
import * as Review from "./review";
import * as SignApi from "./sign";
import { UserState } from "../auth";

export type StateResponse = {
    user: Omit<UserState["user"], "token">;
    request: UserState["request"];
    update?: UserState["update"];
}

export class GuestApi {
    constructor(
        protected readonly endpoint: AxiosInstance,
        public readonly promoPage = PromoPage.Api(endpoint),
        public readonly configuration = Configuration.Api(endpoint),
        public readonly review = Review.Api(endpoint),
        public readonly sign = SignApi.Api(endpoint)
    ) {}

    public readonly getState = async (
        token: string
    ): Promise<Omit<UserState, "type"> | undefined> => {
        const response = await this.endpoint.get<StateResponse>("/v2/state", {
            validateStatus: (status) => status === 200 || status === 401,
            headers: { Authorization: `Bearer ${token}` },
        });

        return response.status === 200 ? {
            ...response.data,
            user: { ...response.data.user, token }
        } : undefined;
    };

    public readonly getConditions = (): Promise<Conditions> => {
        return this.endpoint
            .get<Conditions>("/v2/conditions")
            .then(({ data }) => Object.freeze(data));
    };
}
