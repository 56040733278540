import * as React from "react";
import * as Base from "app/chat/base";

export const MandatePendingTooLongMessage: React.FC<{}> = () => {
    return (
        <>
            <Base.WrapMessage source="bot">
                <p>
                    Unfortunately, the waiting time for DirectDebit approval for your account has exceeded 24 hours.
                </p>
                <p>
                    Contact your bank's support team, or try linking another bank account.
                </p>
            </Base.WrapMessage>
        </>
    );
}
