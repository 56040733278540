import * as React from "react";
import {useCallback} from "react";
import * as Client from "modules/client";
import * as Chat from "modules/chat";
import * as Base from "../base";
import * as State from "../state";

export const InitMessage: State.Message<State.MessageProps<Chat.BankAccount.InitMessage>> =
    (props) => {
        const handleRequest = useCallback(
            async (
                api: Client.Api.Instance
            ): Promise<Array<Client.Api.BankAccount.Method>> => {
                return await api.user.bankAccount.getMethods();
            },
            []
        );

        const handleFinish = useCallback(async (response: Array<Client.Api.BankAccount.Method>): Promise<Chat.Action | void> => {
            return new Chat.ReplaceAction(
                [
                    new Chat.BankAccount.SelectMethodMessage(response)
                ]
            );
        }, []);

        return <Base.DelaySubmitMessage
            delayMs={1000}
            value={props.value}
            onRequest={handleRequest}
            onFinish={handleFinish}
        />
    }
