import { keyboardLayoutFormat } from "./keyboard-layout-format";
import toLower from "lodash/toLower";

const upperFirst = (string: string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export const nameFilter = (value: string): string => {
    return value.split("-").map(toLower).map((v) => keyboardLayoutFormat(v)).map(upperFirst).join("-");
};
