import * as Chat from "modules/chat";
import * as State from "../state";
import {WelcomeMessage} from "./welcome-message";
import {OpenMessage} from "./open-message";
import {ConfirmRequestMessage} from "./confirm-request-message";
import {RefuseInfoMessage} from "./refuse-info-message";
import {RefuseFormMessage} from "./refuse-form-message";
import {ConfirmSubmitMessage} from "./confirm-submit-message";
import {ResendMessage} from "./resend-message";
import {ConfirmFormMessage} from "./confirm-form-message";
import {CompleteMessage} from "./complete-message";
import {SendMessage} from "./send-message";
import {RefuseSendMessage} from "./refuse-send-message";
import {ExitMessage} from "./exit-message";

export function MessageComponentFactory(type: Chat.Restructuring.Message["type"] | string): State.Message | undefined {
    switch (type) {
        case "restructuring:welcome":
            return WelcomeMessage;
        case "restructuring:open":
            return OpenMessage;
        case "restructuring:confirm-request":
            return ConfirmRequestMessage;
        case "restructuring:confirm-form":
            return ConfirmFormMessage;
        case "restructuring:confirm-submit":
            return ConfirmSubmitMessage;
        case "restructuring:refuse-info":
            return RefuseInfoMessage;
        case "restructuring:refuse-form":
            return RefuseFormMessage;
        case "restructuring:resend":
            return ResendMessage;
        case "restructuring:complete":
            return CompleteMessage;
        case "restructuring:send":
            return SendMessage;
        case "restructuring:refuse-send":
            return RefuseSendMessage;
        case "restructuring:exit":
            return ExitMessage;
    }
}
