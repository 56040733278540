// tslint:disable:max-line-length
export const IconSho = () => (
    <svg
        width="46"
        height="21"
        viewBox="0 0 46 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon_sho"
    >
        <path
            d="M45.477 20.211H5.892V9.439L0 6.592L5.907 3.745V0H45.492L45.477 20.211ZM7.777 18.551H43.6V1.88H7.772V4.925L4.309 6.592L7.772 8.267L7.777 18.551Z"
            fill="white"
        />
        <path
            d="M13.9082 6.98065C13.932 6.57779 13.827 6.1778 13.6082 5.83865C13.4929 5.70032 13.3466 5.59105 13.1812 5.51965C13.0159 5.44826 12.836 5.41675 12.6562 5.42765C12.4942 5.42234 12.3327 5.44922 12.1812 5.50672C12.0296 5.56422 11.8909 5.65121 11.7732 5.76265C11.5 6.12682 11.375 6.58097 11.4232 7.03365C11.3909 7.49605 11.4827 7.95868 11.6892 8.37365C11.8987 8.63997 12.1861 8.83432 12.5112 8.92965L13.6112 9.32965C14.2436 9.49661 14.8079 9.85709 15.2252 10.3607C15.6439 10.9998 15.8342 11.7617 15.7652 12.5227V12.9417C15.8355 13.9404 15.5286 14.9292 14.9052 15.7127C14.5888 15.9965 14.2195 16.2152 13.8185 16.3562C13.4175 16.4973 12.9927 16.5579 12.5682 16.5347C12.0932 16.5656 11.6177 16.4831 11.1809 16.2939C10.7441 16.1047 10.3586 15.8143 10.0562 15.4467C9.6679 14.8128 9.46971 14.0808 9.48522 13.3377V12.9377H11.2852V13.2807C11.2641 13.6676 11.3626 14.0516 11.5672 14.3807C11.691 14.5188 11.8442 14.6274 12.0156 14.6986C12.1869 14.7697 12.372 14.8015 12.5572 14.7917C12.7716 14.8169 12.9889 14.7883 13.1895 14.7085C13.3901 14.6287 13.5677 14.5002 13.7062 14.3347C13.935 13.9664 14.0365 13.5332 13.9952 13.1017V12.6467C14.0238 12.1992 13.9235 11.7529 13.7062 11.3607C13.4768 11.099 13.179 10.9065 12.8462 10.8047L11.5372 10.3407C11.1768 10.246 10.8422 10.0716 10.5582 9.83028C10.2742 9.58901 10.0479 9.28704 9.89622 8.94665C9.68002 8.36516 9.58772 7.7449 9.62522 7.12565V6.76765C9.61316 6.33747 9.68977 5.9094 9.85028 5.5101C10.0108 5.1108 10.2518 4.7488 10.5582 4.44665C10.8504 4.18079 11.1931 3.97641 11.5659 3.84568C11.9387 3.71495 12.334 3.66052 12.7282 3.68565C14.5282 3.68565 15.7202 4.84265 15.7202 6.92065V7.33165H13.9082V6.98065Z"
            fill="white"
        />
        <path
            d="M19.1379 3.85156V9.23356H21.5609V3.85156H23.3609V16.3666H21.5609V10.8396H19.1379V16.3666H17.3379V3.85156H19.1379Z"
            fill="white"
        />
        <path
            d="M25.0608 7.14022C25.007 6.65441 25.0632 6.16271 25.2252 5.70155C25.3872 5.2404 25.651 4.82162 25.9968 4.47622C26.6154 3.9603 27.3953 3.67773 28.2008 3.67773C29.0063 3.67773 29.7863 3.9603 30.4048 4.47622C30.7507 4.82162 31.0144 5.2404 31.1764 5.70155C31.3384 6.16271 31.3946 6.65441 31.3408 7.14022V13.0782C31.3946 13.564 31.3384 14.0557 31.1764 14.5169C31.0144 14.978 30.7507 15.3968 30.4048 15.7422C29.7863 16.2581 29.0063 16.5407 28.2008 16.5407C27.3953 16.5407 26.6154 16.2581 25.9968 15.7422C25.651 15.3968 25.3872 14.978 25.2252 14.5169C25.0632 14.0557 25.007 13.564 25.0608 13.0782V7.14022ZM29.5468 6.84622C29.5674 6.64851 29.5447 6.4487 29.4802 6.26065C29.4158 6.0726 29.3113 5.90081 29.1738 5.75722C28.9009 5.53129 28.5576 5.40766 28.2033 5.40766C27.849 5.40766 27.5058 5.53129 27.2328 5.75722C27.0948 5.90055 26.9897 6.07222 26.9248 6.26028C26.8599 6.44834 26.8367 6.64829 26.8568 6.84622V13.3172C26.8281 13.5234 26.8468 13.7334 26.9115 13.9313C26.9761 14.1292 27.085 14.3097 27.2298 14.4592C27.5028 14.6852 27.846 14.8088 28.2003 14.8088C28.5546 14.8088 28.8979 14.6852 29.1708 14.4592C29.3083 14.3156 29.4128 14.1438 29.4772 13.9558C29.5417 13.7677 29.5644 13.5679 29.5438 13.3702L29.5468 6.84622Z"
            fill="white"
        />
        <path
            d="M34.4917 11.7918C34.4619 11.4618 34.5006 11.1291 34.6054 10.8148C34.7102 10.5004 34.8788 10.211 35.1007 9.96484L36.1207 8.37384C36.3309 8.01705 36.435 7.60771 36.4207 7.19385V6.43284C36.4347 6.29426 36.4182 6.15429 36.3722 6.0228C36.3263 5.89131 36.252 5.77151 36.1547 5.67184C36.0529 5.57843 35.9324 5.50773 35.8012 5.46446C35.67 5.42118 35.5311 5.40632 35.3937 5.42084C35.2592 5.41185 35.1246 5.43427 35.0003 5.48634C34.876 5.53841 34.7656 5.6187 34.6777 5.72084C34.5253 5.94472 34.4479 6.21118 34.4567 6.48184V7.31885H32.6797V6.24884C32.6802 5.85301 32.7705 5.46245 32.9437 5.10651C33.1169 4.75058 33.3685 4.43854 33.6797 4.19384C34.1665 3.8468 34.752 3.66554 35.3497 3.67684C36.0984 3.63343 36.8357 3.87574 37.4127 4.35484C37.6726 4.59175 37.879 4.88135 38.0182 5.20432C38.1573 5.52729 38.226 5.87622 38.2197 6.22784V7.21785C38.2616 7.85628 38.1189 8.49329 37.8087 9.05285L36.5387 11.0388C36.3256 11.4075 36.23 11.8324 36.2647 12.2568V13.2768H34.4917V11.7918ZM36.3717 14.3918V16.3638H34.3997V14.3958L36.3717 14.3918Z"
            fill="white"
        />
        <path
            d="M41.6402 3.85156L41.2212 13.2376H39.7902L39.4902 3.85156H41.6402ZM41.4962 14.3916V16.3636H39.5242V14.3916H41.4962Z"
            fill="white"
        />
    </svg>
);
