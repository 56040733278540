import * as Chat from "modules/chat";
import * as Base from "../base";
import { IconArrow } from "modules/components/Icons/IconArrow";

export const RefuseFormMessage = () => {
    const dispatch = Chat.useDispatchContext();

    const handleCanceled = () => {
        dispatch(
            new Chat.ReplaceAction([
                new Chat.TextMessage("Refuse", "user"),
                new Chat.TextMessage(
                    [
                        "You refused the loan.",
                        "You can find out the reason for the refusal?",
                    ],
                    "bot"
                ),
                new Chat.Agreement.AgreementRefuseInfoMessage(),
            ])
        );
    };

    const handleConfirm = () => {
        dispatch(
            new Chat.ReplaceAction([new Chat.Agreement.AgreementSendMessage()])
        );
    };

    return (
        <Base.ButtonMessage source={"user"}>
            <Base.Button theme="primary" onClick={handleCanceled}>
                Refuse
            </Base.Button>
            <Base.Button
                theme="secondary"
                className={"long"}
                onClick={handleConfirm}
            >
                <span>Confirm</span>
                <IconArrow />
            </Base.Button>
        </Base.ButtonMessage>
    );
};
