import { LayoutContainer } from "modules/components/layout-container";
import { useTheme } from "modules/hooks/use-theme";
import classNames from "classnames";

export const Layout: React.FC<{ className?: string }> = ({
    className,
    children,
}) => {
    useTheme("info");
    return (
        <LayoutContainer>
            <div className={classNames("status-wrap", className)}>
                <div className="status">{children}</div>
            </div>
        </LayoutContainer>
    );
};
