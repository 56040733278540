import * as React from "react";
import * as Base from "../base";

export const InfoMessage: React.FC = () => {
    return (
        <Base.WrapMessage source="bot">
            <p>To continue with the registration, you need to verify your bank account through Mono.</p>
       </Base.WrapMessage>
    );
};
