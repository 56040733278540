import * as Chat from "modules/chat";
import * as State from "../state";
import {AgreementMessage} from "./agreement-message";
import {CompleteMessage} from "./complete-message";
import {ConfirmFormMessage} from "./confirm-form-message";
import {ConfirmMessage} from "./confirm-message";
import {InfoMessage} from "./info-message";
import {RequestFormMessage} from "./request-form-message";
import {RequestMessage} from "./request-message";
import {ResendMessage} from "./resend-message";
import {ResendOptionsLoadMessage} from "./resend-options-load-message";
import {ResendOptionsMessage} from "./resend-options-message";

export function MessageComponentFactory(type: Chat.SignUp.Message["type"] | string): State.Message | undefined {
    switch (type) {
        case "sign-up:info":
            return InfoMessage;
        case "sign-up:request-form":
            return RequestFormMessage;
        case "sign-up:request":
            return RequestMessage;
        case "sign-up:confirm-form":
            return ConfirmFormMessage;
        case "sign-up:confirm":
            return ConfirmMessage;
        case "sign-up:complete":
            return CompleteMessage;
        case "sign-up:agreement":
            return AgreementMessage;
        case "sign-up:resend":
            return ResendMessage;
        case "sign-up:resend-options-load":
            return ResendOptionsLoadMessage;
        case "sign-up:resend-options":
            return ResendOptionsMessage;
    }
}
