import * as React from "react";
import * as State from "../state";
import * as Base from "app/chat/base";
import * as Chat from "modules/chat";

type SuccessMessageProps = State.MessageProps<Chat.DirectDebit.MandateSuccessMessage>;

export const MandateSuccessMessage: React.FC<SuccessMessageProps> = (props) => {
    const handleClick = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        location.reload();
    }, []);

    return (
        <>
            <Base.WrapMessage source="bot">
                <p>
                    DirectDebit mandate for your bank account {props.value.accountNumber} in {props.value.bankName} successfully created.
                </p>
                <p>
                    One step left - sign the contract and we'll transfer the money to your bank account!
                </p>
            </Base.WrapMessage>
            <Base.ButtonMessage source={"user"}>
                <Base.Button theme="primary" onClick={handleClick}>
                    Continue
                </Base.Button>
            </Base.ButtonMessage>
        </>
    );
}
