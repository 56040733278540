import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as Base from "../base";
import * as State from "../state";
import axios from "axios";
import {DataValidationResult} from "../../../modules/client/api";

export const FormSubmitMessage: State.Message<
    State.MessageProps<Chat.Registration.FormSubmitMessage>
> = (props) => {
    const handleRequest = (
        api: Client.Api.Instance,
        { value, attribute }: Chat.Registration.FormSubmitMessage
    ) =>
        api
            .with({
                validateStatus: (status) => status >= 200 && status < 300,
            })
            .user.data.patch({
                [attribute]: value,
            })
            .catch((error): DataValidationResult | void => {
                if (axios.isCancel(error)) {
                    return;
                }

                console.error("Error occured during saving user data", error);

                return [
                    {
                        text: "Apologies! We're experiencing a glitch while saving your data. Our team is on it. Your info is safe. Please try again later or contact support. Thanks for your patience.",
                        attribute: {
                            name: props.value.name,
                            value: value,
                            schema: props.value.schema,
                        }
                    }
                ];
            })
    const handleFinish = async ([error]: any /* DataValidationError but TypeScript implementation sucks in this case */) => {
        if (error === undefined) {
            return new Chat.ReplaceAction(
                [new Chat.Registration.ValidateMessage()],
                props.value
            );
        }
        return new Chat.ReplaceAction(
            [
                new Chat.Registration.FormMessage(
                    error.attribute.name,
                    error.text,
                    error.attribute.value,
                    error.attribute.schema
                ),
            ],
            props.value
        );
    };

    return (
        <Base.DelaySubmitMessage
            value={props.value}
            onRequest={handleRequest}
            onFinish={handleFinish}
        />
    );
};
