import {AxiosInstance} from "axios";


export const Api = (endpoint: AxiosInstance) => {
    return {
        request: async (returnUrl: string, bankId: string): Promise<string | undefined> => {
            return endpoint.get<{ url: string }>(
                "/v3/bank-id/request",
                {
                    params: {returnUrl: returnUrl, bankId},
                    validateStatus: (status => [202, 400].includes(status)),
                }
                )
                .then(({data: {url}}) => url);
        },
        code: async (uuid: string, code: string): Promise<boolean> => {
            return endpoint.post(
                "/v3/bank-id/code", {}, {
                    params: {uuid: uuid, code: code},
                    validateStatus: (status => [409, 201, 202].includes(status)),
                }
            ).then(({status}) => [201, 202].includes(status));
        },
    } as const;
};
