import * as React from "react";
import { Context, ContextValue } from "./Context";

export const Controller: React.FC<{defaultActive?: string}> = (props) => {
    const [active, setActive] = React.useState<string | undefined>(props.defaultActive);
    const value = React.useMemo<ContextValue>(
        () => ({active, setActive}),
        [active, setActive]
    );
    return <Context.Provider value={value}>{props.children}</Context.Provider>
};
Controller.displayName = "Accordion.Controller";
