import * as React from "react";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as State from "../state";
import * as Base from "../base";
import {IconArrow} from "../../../modules/components/Icons/IconArrow";
import {SelectNubankBankLoadMessage} from "../../../modules/chat/bank-account";

type MethodButtonProps = {
    onClick: (method: Client.Api.BankAccount.Method) => void;
    method: Client.Api.BankAccount.Method;
    theme: "primary" | "secondary"
}
type MethodButton = React.FC<MethodButtonProps>;

export const MethodButton: MethodButton = React.memo((props: MethodButtonProps) => {
    const handleClick = () => {
        props.onClick(props.method);
    };
    return (
        <Base.Button theme={props.theme} onClick={handleClick}>
            {props.method.title}
        </Base.Button>
    )
});

const getButtonTheme = (method: Client.Api.BankAccount.Method): MethodButtonProps["theme"] => {
    switch (method.name) {
        case "mono":
            return "primary";
        case "nuban":
        default:
            return "secondary";
    }
}

const getMessagesByMethod = (method: Client.Api.BankAccount.Method): Array<Chat.Message> => {
    switch (method.name) {
        case "mono":
            return [
                new Chat.BankId.InfoMessage(),
                new Chat.BankId.InitMessage(),
            ];
        case "nuban":
            return [
                new Chat.BankAccount.SelectNubankBankLoadMessage(),
            ];
        default:
            return [
                new Chat.RedirectMessage("/upgrade-required"),
            ];
    }
}

export const SelectMethodMessage: State.Message<State.MessageProps<Chat.BankAccount.SelectMethodMessage>> =
    (props) => {
        const dispatch = Chat.useDispatchContext();

        const handleClick: MethodButtonProps["onClick"] = React.useCallback((method: Client.Api.BankAccount.Method) => {
            dispatch(new Chat.ReplaceAction([
                new Chat.EditableMessage(method.title, new Chat.FlushAction([
                    new Chat.BankAccount.WelcomeMessage(),
                    new Chat.BankAccount.InitMessage(),
                ])),
                ...getMessagesByMethod(method),
            ], props.value));
        }, [dispatch]);

        return (

            <Base.ButtonMessage source={"user"}>
                {props.value.methods.map((method) => {
                    return <MethodButton onClick={handleClick} method={method} theme={getButtonTheme(method)}
                                         key={method.name}/>;
                })}
            </Base.ButtonMessage>
        );
    };
