import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as Base from "../base"
import * as State from "../state"
import {sendConversion} from "modules/utils/send-conversion";

export const CompleteSubmitMessage: State.Message<State.MessageProps<Chat.Registration.FormSubmitMessage>> =
    (props) => {
        const dispatch = Client.useDispatchContext();
        const handleRequest = (api: Client.Api.Instance) => {
            sendConversion("registration");
            return api.user.data.complete();
        };
        const handleFinish = (): void => {
            dispatch(new Client.Auth.RefreshAction());
        };

        return <Base.DelaySubmitMessage
            delayMs={5000}
            value={props.value}
            onRequest={handleRequest}
            onFinish={handleFinish}
        />
    };
