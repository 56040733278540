import { useCallback } from "react";
import * as Client from "modules/client";
import * as Chat from "modules/chat";
import * as Base from "../base";
import * as State from "../state";

export const ConfirmEmailSubmitMessage: State.Message<State.MessageProps<Chat.Agreement.AgreementConfirmEmailSubmitMessage>> =
    ({value}) => {
        const handleRequest = useCallback((api: Client.Api.Instance, value: string) => {
            return api.user.data.patch({email: value});
        }, []);
        const handleResponse = useCallback(async (response: Promise<string | undefined>) => {
            const failure = await response;
            if (Array.isArray(failure) && (failure.length > 0) && (failure[0].text)) {
                return new Chat.ReplaceAction([
                    new Chat.Agreement.AgreementConfirmEmailFormMessage(value.record, failure[0].text)
                ]);
            }
            return new Chat.ReplaceAction([
                new Chat.Agreement.AgreementConfirmRequestMessage(),
            ]);
        }, [value]);
        return <Base.DelaySubmitMessage value={value.record.value} onRequest={handleRequest} onFinish={handleResponse}/>
    };
