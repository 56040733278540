// tslint:disable:max-line-length
export const Shield = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_244_6"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="48"
      height="48"
    >
      <rect width="48" height="48" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_244_6)">
      <path
        d="M21.9492 26.2496L18.0492 22.3496C17.7159 22.0496 17.3242 21.8996 16.8742 21.8996C16.4242 21.8996 16.0492 22.0496 15.7492 22.3496C15.4492 22.6496 15.2992 23.0246 15.2992 23.4746C15.2992 23.9246 15.4492 24.3163 15.7492 24.6496L20.5992 29.4996C20.9326 29.8329 21.3409 29.9996 21.8242 29.9996C22.3076 29.9996 22.7159 29.8329 23.0492 29.4996L32.1992 20.3496C32.4659 20.0829 32.5992 19.7163 32.5992 19.2496C32.5992 18.7829 32.4659 18.4163 32.1992 18.1496C31.9326 17.8829 31.5742 17.7496 31.1242 17.7496C30.6742 17.7496 30.3159 17.8829 30.0492 18.1496L21.9492 26.2496ZM23.9992 44.1496C23.8326 44.1496 23.6826 44.1413 23.5492 44.1246C23.4159 44.1079 23.2992 44.0829 23.1992 44.0496C18.6659 42.6829 14.9492 39.8913 12.0492 35.6746C9.14922 31.4579 7.69922 26.8496 7.69922 21.8496V12.1496C7.69922 11.4163 7.90755 10.7496 8.32422 10.1496C8.74089 9.54961 9.26589 9.13294 9.89922 8.89961L22.7992 4.04961C23.2326 3.91628 23.6326 3.84961 23.9992 3.84961C24.3659 3.84961 24.7659 3.91628 25.1992 4.04961L38.0992 8.89961C38.7325 9.13294 39.2576 9.54961 39.6742 10.1496C40.0909 10.7496 40.2992 11.4163 40.2992 12.1496V21.8496C40.2992 26.8496 38.8492 31.4579 35.9492 35.6746C33.0492 39.8913 29.3326 42.6829 24.7992 44.0496C24.8326 44.0163 24.5659 44.0496 23.9992 44.1496ZM23.9992 40.7496C27.7992 39.4496 30.8992 37.0579 33.2992 33.5746C35.6992 30.0913 36.8992 26.1829 36.8992 21.8496V12.1496L23.9992 7.24961L11.0992 12.1496V21.8496C11.0992 26.1829 12.2992 30.0913 14.6992 33.5746C17.0992 37.0579 20.1992 39.4496 23.9992 40.7496Z"
        fill="url(#paint0_linear_244_6)"
      />
      <path
        d="M21.9492 26.2496L18.0492 22.3496C17.7159 22.0496 17.3242 21.8996 16.8742 21.8996C16.4242 21.8996 16.0492 22.0496 15.7492 22.3496C15.4492 22.6496 15.2992 23.0246 15.2992 23.4746C15.2992 23.9246 15.4492 24.3163 15.7492 24.6496L20.5992 29.4996C20.9326 29.8329 21.3409 29.9996 21.8242 29.9996C22.3076 29.9996 22.7159 29.8329 23.0492 29.4996L32.1992 20.3496C32.4659 20.0829 32.5992 19.7163 32.5992 19.2496C32.5992 18.7829 32.4659 18.4163 32.1992 18.1496C31.9326 17.8829 31.5742 17.7496 31.1242 17.7496C30.6742 17.7496 30.3159 17.8829 30.0492 18.1496L21.9492 26.2496ZM23.9992 44.1496C23.8326 44.1496 23.6826 44.1413 23.5492 44.1246C23.4159 44.1079 23.2992 44.0829 23.1992 44.0496C18.6659 42.6829 14.9492 39.8913 12.0492 35.6746C9.14922 31.4579 7.69922 26.8496 7.69922 21.8496V12.1496C7.69922 11.4163 7.90755 10.7496 8.32422 10.1496C8.74089 9.54961 9.26589 9.13294 9.89922 8.89961L22.7992 4.04961C23.2326 3.91628 23.6326 3.84961 23.9992 3.84961C24.3659 3.84961 24.7659 3.91628 25.1992 4.04961L38.0992 8.89961C38.7325 9.13294 39.2576 9.54961 39.6742 10.1496C40.0909 10.7496 40.2992 11.4163 40.2992 12.1496V21.8496C40.2992 26.8496 38.8492 31.4579 35.9492 35.6746C33.0492 39.8913 29.3326 42.6829 24.7992 44.0496C24.8326 44.0163 24.5659 44.0496 23.9992 44.1496ZM23.9992 40.7496C27.7992 39.4496 30.8992 37.0579 33.2992 33.5746C35.6992 30.0913 36.8992 26.1829 36.8992 21.8496V12.1496L23.9992 7.24961L11.0992 12.1496V21.8496C11.0992 26.1829 12.2992 30.0913 14.6992 33.5746C17.0992 37.0579 20.1992 39.4496 23.9992 40.7496Z"
        fill="url(#paint1_radial_244_6)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_244_6"
        x1="23.9992"
        y1="3.84961"
        x2="37.8247"
        y2="38.6583"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B2FF" />
        <stop offset="1" stopColor="#0F85FF" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_244_6"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.8029 3.84961) rotate(88.7107) scale(48.9481 15.0353)"
      >
        <stop stopColor="#72D9FF" />
        <stop offset="1" stopColor="#65C4FF" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
