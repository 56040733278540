import * as React from "react";
import * as Client from "../client";
import axios from "axios";
import {Context, DefaultConfigurationValue} from "./context";
import {ConfigurationValue} from "modules/client/api/configuration";

export const Provider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const {api} = Client.useStateContext();
    const [configuration, setConfiguration] = React.useState<ConfigurationValue>(DefaultConfigurationValue);

    React.useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        api.with({cancelToken: cancelToken.token})
            .guest.configuration.get()
            .then(setConfiguration)
            .catch((error) => {
                console.error("Failed to get configuration. Using default.", error);
            })
        return () => cancelToken.cancel();
    }, []);

    return <Context.Provider value={configuration}>{children}</Context.Provider>;
};
