import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { Capacitor } from "@capacitor/core";
import { AppsFlyer } from "appsflyer-capacitor-plugin";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { getCurrentConfig } from "../../app/config";
import { AdjustSdk } from "../capacitor/adjust-sdk";
// import {sendKochavaEvent} from "../capacitor/kochava";
import * as Sentry from "@sentry/capacitor";

const dataLayer = ((window as any).dataLayer = (window as any).dataLayer || []);
const uetq = ((window as any).uetq = (window as any).uetq || []);

export function gtag(...args: any) {
    dataLayer.push(arguments);
}
function uet_report_conversion() {
    (window as any).uetq = (window as any).uetq || [];
    (window as any).uetq.push("event", "first_credit_issue", {});
}

(window as any).gtag = gtag;
gtag("js", new Date());
gtag("config", "AW-10826901037");

export function sendConversion(type: "registration"): void;
export function sendConversion(
    type: "credit",
    number: number,
    id?: number
): void;
export function sendConversion(
    type: "registration" | "credit",
    number?: number /* порядковый номер заявки */,
    id?: number /* id заявки */
): void {
    if (getCurrentConfig().environment !== "production") {
        return;
    }

    if (type === "registration") {
        gtag("event", "conversion", {
            send_to: "AW-10826901037/hj0jCL-outcDEK3M1aoo",
        });
        if (Capacitor.isNativePlatform()) {
            FirebaseAnalytics.logEvent({ name: type }).catch((error) => {
                Sentry.captureException(error);
            });
            // @ts-ignore https://github.com/capacitor-community/facebook-login/issues/144
            FacebookLogin.logEvent({ eventName: type }).catch((error) => {
                Sentry.captureException(error);
            });
            // sendKochavaEvent(type);
            AdjustSdk.sendEvent("nu5l0d");
        }

        return;
    }

    if (Capacitor.isNativePlatform()) {
        const eventName =
            Number(number) > 0 ? "second_credit_taken" : "first_credit_taken";
        const facebookEventName =
            Number(number) > 0 ? "ddd_second" : "ddd_first";
        const adjustEventToken = Number(number) > 0 ? "s8wzfe" : "xrzhju";

        FirebaseAnalytics.logEvent({
            name: eventName,
            params: {
                id,
                number,
            },
        }).catch((error) => {
            Sentry.captureException(error);
        });

        AppsFlyer.logEvent({
            eventName: eventName,
            eventValue: {
                id,
                number,
            },
        }).catch((error) => {
            /** ignoring this error because it's not necessary */
            console.warn(error);
        });

        FacebookLogin.logEvent({ eventName: facebookEventName }).catch((error) => {
            Sentry.captureException(error);
        });

        // sendKochavaEvent(eventName);
        AdjustSdk.sendEvent(adjustEventToken);
    }

    if (Number(number) > 0) {
        gtag("event", "conversion", {
            send_to: "AW-10826901037/LF8vCLCwjIAYEK3M1aoo",
        });
    } else {
        uet_report_conversion();
        gtag("event", "conversion", {
            send_to: "AW-10826901037/ksJtCL60jIAYEK3M1aoo",
        });
    }
}
