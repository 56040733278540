import { history } from "app/history";
import i18next from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next, Trans as BaseTrans } from "react-i18next";
import { LocaleLanguage, RouteLanguage } from "./types";

export const DEFAULT_LANGUAGE: LocaleLanguage = LocaleLanguage.uk;

export const LOCALE_ROUTE_PATH = `/:locale(${RouteLanguage.ru})?`;

export const LOCALE_SEPARATOR = "|";

export const compose = (...lexemes: string[]) => lexemes.join(LOCALE_SEPARATOR);

const getLanguage = (pathname: string): string => {
    const [match] = pathname.match(/^(\/ru)?/) ?? [];

    if (match === "/ru") {
        return LocaleLanguage.ru;
    }

    return LocaleLanguage.uk;
};

const startLanguage = getLanguage(history.location.pathname);

i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: startLanguage,
        fallbackLng: startLanguage,
        keySeparator: LOCALE_SEPARATOR,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
    });

export const Trans = BaseTrans;
