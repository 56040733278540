import * as React from "react";
import { Redirect, RedirectProps } from "react-router-dom";
import { useLocale } from "../hooks";
import { LocationDescriptorObject } from "history";

export interface LocalizedRedirect extends RedirectProps {
    to: string | LocationDescriptorObject;
}


export const LocalizedRedirect: React.FC<LocalizedRedirect> = (props) => {
    const { joinBasePath } = useLocale();

    const path = props.to;

    if (typeof path === "string") {
        return <Redirect {...props} to={joinBasePath(path)} />;
    } else {
        return <Redirect {...props} to={{ ...path, pathname: joinBasePath(path.pathname || "") }} />;
    }
};
