export class CalculatorStorage {
    static readonly calc = "ll.calc";
    static readonly promoCode = "ll.pc";
    static readonly synced = "ll.synced";

    static setSynced(value: boolean) {
        localStorage.setItem(this.synced, value.toString());
    }

    static getSynced() {
        return localStorage.getItem(this.synced) === "true";
    }

    static setCalc(amount: number, term: number) {
        this.setSynced(false);

        localStorage.setItem(this.calc, JSON.stringify([amount, term]));
    }

    static getCalc(): CalcDescriptor | null {
        const item = this.parse(this.calc);

        if (item == null) {
            return null;
        }

        const [amount, term] = item;

        return {
            amount: Number.parseFloat(amount),
            term: Number.parseFloat(term),
        };
    }

    static removeCalc() {
        localStorage.removeItem(this.calc);
    }

    static setPromoCode(code: string, discount: number) {
        this.setSynced(false);

        localStorage.setItem(this.promoCode, JSON.stringify([code, discount]));
    }

    static getPromoCode(): PromoCodeDescriptor | null {
        const item = this.parse(this.promoCode);

        if (item == null) {
            return null;
        }

        const [code, discount] = item;

        return {
            code,
            discount: Number.parseFloat(discount),
        };
    }

    static removePromoCode() {
        localStorage.removeItem(this.promoCode);
    }

    private static parse(key: string) {
        const item = localStorage.getItem(key);

        if (item == null) {
            return null;
        }

        try {
            return JSON.parse(item);
        } catch (error) {
            console.error(`Error while parsing (${key})`, error);

            return null;
        }
    }
}

export interface CalcDescriptor {
    amount: number;
    term: number;
}

export interface PromoCodeDescriptor {
    code: string;
    discount: number;
}
