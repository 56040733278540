import * as React from "react";
import * as Base from "app/chat/base";
import * as Client from "modules/client";
import {useLocale} from "app/locale";

export const CompleteMessage: React.FC = () => {
    const dispatch = Client.useDispatchContext();
    const {t} = useLocale("registration");

    React.useEffect(() => {
        const id = setTimeout(() => {
            dispatch(new Client.Auth.RefreshAction());
        }, 5000);
        return () => clearTimeout(id);
    }, [dispatch,]);
    return <Base.Message source="bot">{t("restructuring-complete")}</Base.Message>
}
