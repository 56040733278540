import * as State from "../state";
import * as Chat from "../../../modules/chat";
import {useCallback} from "react";
import * as Client from "../../../modules/client";
import * as Base from "../base";
import * as React from "react";
import {ConfirmNubanMessage} from "../../../modules/chat/bank-account";

export const SelectNubanLoadMessage: State.Message<State.MessageProps<Chat.BankAccount.SelectNubanLoadMessage>> =
    (props) => {
        const handleRequest = useCallback(
            async (
                api: Client.Api.Instance
            ): Promise<Client.Api.BankAccount.CreateBankAccountResponse> => {
                return await api.user.bankAccount.createAccount(props.value.bankCode, props.value.accountNumber);
            },
            []
        );

        const handleFinish = useCallback(async (response: Client.Api.BankAccount.CreateBankAccountResponse): Promise<Chat.Action | void> => {
            if (response.account) {
                return new Chat.ReplaceAction(
                    [
                        new Chat.TextMessage(response.message, "bot"),
                        new Chat.BankAccount.ConfirmNubanMessage(response.account.accountNumber, response.account.bank.code)
                    ], props.value
                );
            }
            return new Chat.ReplaceAction([
                new Chat.TextMessage(response.message),
                new Chat.BankAccount.WelcomeMessage,
                new Chat.BankAccount.InitMessage,
            ], props.value);
        }, []);

        return <Base.DelaySubmitMessage
            delayMs={1000}
            value={props.value}
            onRequest={handleRequest}
            onFinish={handleFinish}
        />
    }
