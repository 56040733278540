import * as React from "react";
import * as Chat from "../../../modules/chat";
import * as Base from "../base";
import {IconArrow} from "../../../modules/components/Icons/IconArrow";

const completeText = 'Continue';

export const ConfirmMessage: React.FC<{}> = () => {
    const dispatch = Chat.useDispatchContext();
    const handleConfirm = React.useCallback(() => {
        dispatch(
            new Chat.ReplaceAction([
                new Chat.DirectDebit.LoadMessage(),
            ])
        );
    }, [dispatch]);

    return (
        <Base.ButtonMessage source={"user"}>
            <Base.Button
                id={"complete-registration-submit"}
                theme="secondary"
                onClick={handleConfirm}
            >
                <span>{completeText}</span>
                <IconArrow />
            </Base.Button>
        </Base.ButtonMessage>
    );
};
