import { AxiosInstance, AxiosResponse } from "axios";
import {
    DataAttributeRecord,
    DataAttributeValue,
    DataValidationResult,
    DataResult,
} from "./data";
import * as Payment from "./payment";
import * as Prolongation from "./prolongation";
import * as Agreement from "./agreement";
import * as Restructuring from "./restructuring";
import * as Credit from "./credit";
import * as Promocode from "./promocode";
import * as BankId from "./bank-id";
import * as PhoneId from "./phone-id";
import * as Profile from "./profile";
import * as Mono from "./mono";
import * as SmileId from "./smile-id";
import * as Notifications from "./notifications";
import * as PushNotifications from "./push-notifications-api";
import * as DeviceInfo from "./device-info-api";
import * as Contacts from "./contacts-api";
import * as DirectDebit from "./direct-debit";
import * as BankAccount from "./bank-account";

type AccountDeletionResponse = {status: 'success' | 'failure', message: string};

export class UserApi {
    constructor(
        protected readonly endpoint: AxiosInstance,
        public readonly prolongation = Prolongation.Api(endpoint),
        public readonly credit = Credit.Api(endpoint),
        public readonly agreement = Agreement.Api(endpoint),
        public readonly restructuring = Restructuring.Api(endpoint),
        public readonly payment = Payment.Api(endpoint),
        public readonly promocode = Promocode.Api(endpoint),
        public readonly bankId = BankId.Api(endpoint),
        public readonly phoneId = PhoneId.Api(endpoint),
        public readonly profile = Profile.Api(endpoint),
        public readonly mono = Mono.Api(endpoint),
        public readonly smileId = SmileId.Api(endpoint),
        public readonly notifications = Notifications.Api(endpoint),
        public readonly pushNotifications = PushNotifications.Api(endpoint),
        public readonly deviceInfo = DeviceInfo.Api(endpoint),
        public readonly contacts = Contacts.Api(endpoint),
        public readonly directDebit = DirectDebit.Api(endpoint),
        public readonly bankAccount = BankAccount.Api(endpoint),
    ) {}

    /** TODO: move data to separate file */
    public readonly data = {
        get: async (): Promise<DataResult> => {
            const { data } = await this.endpoint.get("/v2/data/attribute");
            return data;
        },
        patch: async (update: {
            [k: string]: DataAttributeValue;
        }): Promise<DataValidationResult> => {
            const result = await this.endpoint.patch("/v2/data", {
                Data: update,
            });
            return result.data;
        },
        attribute: async (name: string): Promise<DataAttributeRecord<any>> => {
            const { data } = await this.endpoint.get<
                DataAttributeRecord<any>,
                AxiosResponse<DataAttributeRecord<any>>
            >("/v2/data/attribute", {
                params: { name },
            });
            return data;
        },
        validate: async (limit?: number): Promise<DataValidationResult> => {
            const { data } = await this.endpoint.get("/v2/data/validate", {
                params: { limit },
            });
            return data;
        },
        complete: async (): Promise<any> => {
            await this.endpoint.post("/v2/complete");
        },
    } as const;

    public readonly account = {
        delete: async(): Promise<AccountDeletionResponse> => {
            const { data } = await this.endpoint.delete<{
                status: boolean, message: string,
            }, AxiosResponse<AccountDeletionResponse>>("/v2/user", {
                validateStatus: (status) => [451, 200].includes(status),
            });

            return data;
        },
    } as const;
}
