import * as React from "react";
import { LocalizedRoute } from "app/locale";
import { Page } from "./Page";

export const Route: () => React.ReactNode = () => {
    return (
        <LocalizedRoute
            key="bank-accounts"
            path="/dashboard/bank-accounts"
            exact
            component={Page}
        />
    );
};
