import { useCallback } from "react";
import {useLocale} from "app/locale";
import * as Client from "modules/client";
import * as Chat from "modules/chat";
import * as Base from "../base";
import * as State from "../state";

export const RefuseSendMessage: State.Message<State.MessageProps<Chat.Restructuring.RestructuringRefuseSendMessage>> =
    ({value}) => {
        const {t} = useLocale("registration");

        const handleRequest = useCallback((api: Client.Api.Instance) => {
            return api.user.restructuring.refuse();
        }, []);

        const handleResponse = useCallback(
            async () => {
                return new Chat.ReplaceAction([
                    new Chat.TextMessage(t("change-my-mind"), "user"),
                    new Chat.TextMessage([
                        t("canceled-message-1"),
                        t("canceled-message-2"),
                    ], "bot"),
                    new Chat.Restructuring.RestructuringExitMessage(),
                ], value);
            }, [value]);

        return <Base.DelaySubmitMessage value={undefined} onRequest={handleRequest} onFinish={handleResponse}/>
    };
